import { Component } from 'react';
import { settings } from '../../services/settings-service';
import { getOrganizationId } from '../../services/token-service';
import { Buffer } from 'buffer';

interface LivePreviewProps {
  messageId: string;
  draft: boolean;
  livePreview: boolean;
  loaded?: () => unknown;
}

export default class LivePreview extends Component<
  LivePreviewProps,
  Record<string, never>
> {
  componentDidMount() {
    window.addEventListener('message', this.handleGistEvents.bind(this));
  }

  handleGistEvents = (e: any) => {
    if (e.data.gist && e.data.gist.method === 'routeLoaded') {
      if (this.props.loaded) {
        this.props.loaded();
      }
    }
  };

  getLivePreviewUrl = (
    messageId: string,
    draft: boolean,
    livePreview: boolean,
  ) => {
    var organizationId = getOrganizationId();
    draft && (messageId = `${messageId}-draft`);

    var options = {
      endpoint: settings.ENGINE_API_ENDPOINT,
      organizationId: organizationId,
      messageId: messageId,
      livePreview: livePreview,
      useConfigurationCache: false,
      properties: null,
    };

    return `${
      settings.GIST_RENDERER_ENDPOINT
    }/index.html?options=${this.encodeUnicode(JSON.stringify(options))}`;
  };

  encodeUnicode = (str: string) => {
    // eslint-disable-next-line no-undef
    return Buffer.from(
      encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          // @ts-ignore
          return String.fromCharCode('0x' + p1);
        },
      ),
    ).toString('base64');
  };

  render() {
    const { messageId, draft, livePreview } = this.props;
    return (
      <div className="live-preview-iframe-container">
        <iframe
          title="Live Preview"
          className="live-preview-frame"
          src={this.getLivePreviewUrl(messageId, draft, livePreview)}
        />
      </div>
    );
  }
}
