export const WHITE = '#ffffff';

export const LIGHT_GRAY_COLOR = '#dddddd';
export const LIGHTER_GRAY_COLOR = '#eeeeee';
export const CIO_PURPLE = '#7131FF';
export const LIGHT_CIO_GREEN = '#a0cac9';
export const LIGHTER_CIO_GREEN = '#9fc5c1';
export const COLOR_TEXT = '#545D63'; // $color-text-default from fly-css-framework
export const COLOR_TEXT_DARK = '#2F3437'; // $color-text-dark from fly-css-framework
export const COLOR_TEXT_MUTED = '#909AA1'; // $color-text-light from fly-css-framework

export const CYAN = '#00626E';
export const BLUE = '#151630';
export const VIOLET = '#3532C2';
export const TEAL = '#007056';
export const YELLOW = '#8F6F00';
export const ORANGE = '#904405';
export const PINK = '#7F003D';
export const PURPLE = '#4F2D73';
export const PURPLE_LIGHT = '#A569b1';

export const DANGER_STATE = '#e60134';
export const DANGER_RED = '#a94442';
export const DANGER_RED_LIGHT = '#d9534f';
export const DANGER_RED_LIGHTER = '#ed8d85';

// Purple
export const PURPLE_700 = '#006ff5';

// Gray
export const GRAY_900 = '#343446';
export const GRAY_700 = '#4B4B60';
export const GRAY_500 = '#66787A';
export const GRAY_400 = '#A1A2B2';
export const GRAY_300 = '#C7C7D4';
export const GRAY_200 = '#EBECF2';
export const GRAY_100 = '#EBF0F1';

// Red
export const RED_900 = '#69002C';

interface Color {
  name: string;
  value: string;
}

export const isColorNameDuplicated = (
  newColorName: string,
  colors: Color[],
): boolean =>
  colors.findIndex(
    (color: Color) => newColorName.toLowerCase() === color.name.toLowerCase(),
  ) !== -1;
