import { scrollIntoView } from 'seamless-scroll-polyfill';

export const scrollToComponent = (selectedComponentId: string | null): void => {
  if (!selectedComponentId) {
    return;
  }

  const element = document.getElementById(selectedComponentId);
  if (!element) {
    return;
  }

  // check if element is in view
  const parent = document.querySelector('#editor-container') as HTMLElement;
  const parentRect = parent.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();
  const viewportHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0,
  );

  if (
    elementRect.top < parentRect.top ||
    elementRect.bottom > parentRect.bottom ||
    elementRect.top < 0 ||
    elementRect.bottom > viewportHeight
  ) {
    // Using the polyfill method directly to handle cross browser support!
    scrollIntoView(
      element,
      { behavior: 'smooth', block: 'center' },
      { duration: 500 },
    );
  }
};

export const highlightComponent = (componentId: string | null) => {
  if (!componentId) {
    return;
  }
  const element = document.getElementById(componentId);
  if (!element) {
    return;
  }
  element?.classList.add('highlight');
};

export const unhighlightComponent = (componentId: string | null) => {
  if (!componentId) {
    return;
  }
  const element = document.getElementById(componentId);
  if (!element) {
    return;
  }
  element?.classList.remove('highlight');
};
