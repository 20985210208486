import React from 'react';
import { useDraggable } from '@dnd-kit/core';

export function Draggable(props) {
  const { attributes, listeners, isDragging, setNodeRef, transform } =
    useDraggable({
      id: props.id,
    });
  const style = transform
    ? {
        zIndex: 10000,
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;
  const className = isDragging ? 'dragging' : 'not-dragging';

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={className}
      {...listeners}
      {...attributes}
    >
      {props.children}
    </div>
  );
}
