import React, { Component } from 'react';
import ColorField from '../fields/ColorField';
import FontsField from '../fields/FontsField';
import PaddingField from '../fields/PaddingField';
import { CrossAxisAlignmentField } from '../fields/CrossAxisAlignmentField';
import { MainAxisAlignmentField } from '../fields/MainAxisAlignmentField';
import ActionBehaviourField from '../fields/ActionBehaviourField';
import { TextAlignField } from '../fields/TextAlignField';
import { TextOverflowField } from '../fields/TextOverflowField';
import ImageFitField from '../fields/ImageFitField';
import AddMultipleComponentField from '../fields/AddMultipleComponentField';
import AddSingleComponentField from '../fields/AddSingleComponentField';
import { MultiPaddingPicker } from './MultiPaddingPicker';
import { getPlaceholderInputForFieldName } from '../../../utilities/fields';
import { withRouter } from 'react-router-dom';
import FilePickerFieldEmbed from '../fields/FilePickerFieldEmbed';
import { NumericInputField } from '../fields/NumericInputField';

class ComponentDetailsField extends Component {
  constructor(props) {
    super(props);
    this.updatePropertyValue = this.updatePropertyValue.bind(this);
  }

  updatePropertyValue = (e) => {
    const { fieldKey } = this.props;
    var value = e.target.value;
    value = value === '' ? null : value;
    this.props.updateValue(value, fieldKey);
  };

  updateActionValue = (action, behaviour) => {
    var value = action;
    value = value === '' ? null : value;
    this.props.updateValue(value, 'action');
    this.props.updateValue(behaviour, 'behaviour');
  };

  updateNumberPropertyValue = (e) => {
    const { fieldKey } = this.props;
    var value = Number(e.target.value);
    value = value === 0 ? null : value;
    this.props.updateValue(value, fieldKey);
  };

  updateSinglePropertyPadding = (value, index) => {
    const { fieldKey } = this.props;
    value = value === '' ? null : value;
    this.props.updateValue(value, fieldKey);
  };

  updatePropertyPadding = (newValue, index) => {
    const { value, fieldKey } = this.props;
    var originalValue = value ? value : ['', '', '', ''];
    var paddingField = [
      originalValue[0],
      originalValue[1],
      originalValue[2],
      originalValue[3],
    ];
    paddingField[index] = newValue;
    this.props.updateValue(paddingField, fieldKey);
  };

  updateComponentValue = (component) => {
    const { fieldKey } = this.props;
    this.props.updateValue(component, fieldKey);
  };

  getInputField() {
    const {
      value,
      definition,
      configuration,
      messages,
      canPerformActions,
      componentType,
    } = this.props;
    let selectPlaceholder = null;
    const placeholder = getPlaceholderInputForFieldName(definition?.name);
    switch (definition.fieldType) {
      case 'MultiPaddingPicker':
        return (
          <MultiPaddingPicker
            value={value}
            configuration={configuration}
            updatePropertyPaddingHandler={this.updatePropertyPadding}
          />
        );
      case 'SinglePaddingPicker':
        return (
          <PaddingField
            value={value}
            configuration={configuration}
            onPaddingChange={this.updateSinglePropertyPadding}
          />
        );
      case 'ColorPicker':
        return (
          <ColorField
            defaultsToFirstOption={definition.defaultsToFirstOption}
            value={value}
            configuration={configuration}
            onChange={this.updatePropertyValue}
          />
        );
      case 'TextStylePicker':
        return (
          <FontsField
            defaultsToFirstOption={definition.defaultsToFirstOption}
            value={value}
            configuration={configuration}
            onChange={this.updatePropertyValue}
          />
        );
      case 'CrossAxisAlignmentPicker':
        selectPlaceholder =
          componentType === 'Horizontal List'
            ? 'Select vertical alignment...'
            : 'Select horizontal alignment...';
        return (
          <CrossAxisAlignmentField
            value={value}
            configuration={configuration}
            onChange={this.updatePropertyValue}
            selectPlaceholder={selectPlaceholder}
          />
        );
      case 'MainAxisAlignmentPicker':
        selectPlaceholder =
          componentType === 'Horizontal List'
            ? 'Select horizontal alignment...'
            : 'Select vertical alignment...';
        return (
          <MainAxisAlignmentField
            value={value}
            configuration={configuration}
            onChange={this.updatePropertyValue}
            selectPlaceholder={selectPlaceholder}
          />
        );
      case 'ActionBehaviourPicker':
        return (
          <ActionBehaviourField
            action={value}
            behaviour={this.props.getValue('behaviour')}
            configuration={configuration}
            onChange={this.updateActionValue}
            messages={messages}
            required={definition.required}
          />
        );
      case 'TextAlignPicker':
        return (
          <TextAlignField
            value={value}
            configuration={configuration}
            onChange={this.updatePropertyValue}
          />
        );
      case 'TextOverflowPicker':
        return (
          <TextOverflowField
            value={value}
            configuration={configuration}
            onChange={this.updatePropertyValue}
          />
        );
      case 'ImageFitPicker':
        return (
          <ImageFitField
            value={value}
            configuration={configuration}
            onChange={this.updatePropertyValue}
          />
        );
      case 'SingleComponentPicker':
        return (
          <AddSingleComponentField
            value={value}
            configuration={configuration}
            onChange={this.updateComponentValue}
            canPerformActions={canPerformActions}
          />
        );
      case 'ComponentPicker':
        return (
          <AddMultipleComponentField
            value={value}
            configuration={configuration}
            onChange={this.updateComponentValue}
            canPerformActions={canPerformActions}
          />
        );
      case 'Number':
        return (
          <NumericInputField
            value={value}
            onChange={this.updateNumberPropertyValue}
            required={definition.required}
            placeholder={placeholder}
            step={definition.step ? definition.step : '1'}
            min="0"
          />
        );
      case 'DecimalNumber':
        return (
          <input
            type="number"
            placeholder={placeholder}
            defaultValue={value}
            onChange={this.updateNumberPropertyValue}
            min="0"
            required={definition.required}
            step="any"
          />
        );
      case 'FilePicker':
        return (
          <FilePickerFieldEmbed
            preview={definition.preview}
            value={value}
            onChange={this.updatePropertyValue}
            required={definition.required}
          />
        );
      case 'DataSourcePicker':
        return (
          <input
            type="text"
            defaultValue={value}
            placeholder="$name.list"
            onChange={this.updatePropertyValue}
            required={definition.required}
          />
        );
      case 'Textarea':
        return (
          <textarea
            value={value}
            onChange={this.updatePropertyValue}
            required={definition.required}
          ></textarea>
        );
      default:
        return (
          <div>
            <input
              type="text"
              defaultValue={value}
              onChange={this.updatePropertyValue}
              required={definition.required}
              placeholder={definition.placeholder}
            />
            {definition.hint && <div className="hint">{definition.hint}</div>}
          </div>
        );
    }
  }

  render() {
    const {
      definition,
      value,
      canPerformActions,
      style,
      inputClassName,
      containerClassName,
    } = this.props;
    const containerWrapperClassName = containerClassName
      ? containerClassName
      : 'field';

    // Extra safety check
    if (!definition || !definition.name) {
      return null;
    }

    if (
      (definition.name === 'Component' || definition.name === 'Components') &&
      !canPerformActions
    ) {
      return <div />;
    } else {
      return (
        <div
          data-testid="cdf-container"
          className={
            definition.required === true && value === undefined
              ? `highlight ${containerWrapperClassName}`
              : `${containerWrapperClassName}`
          }
          style={style}
        >
          <span
            className={
              definition.fieldType === 'Padding'
                ? 'input-field-title padding'
                : 'input-field-title'
            }
          >
            {definition.name}
          </span>
          <span className={inputClassName ? inputClassName : 'input-field'}>
            {this.getInputField()}
          </span>
        </div>
      );
    }
  }
}

export default withRouter(ComponentDetailsField);
