// @ts-nocheck
import { Component } from 'react';
import EmbedLayout from './layouts/EmbedLayout';
import { identifyUser } from './services/reporting-service';
import { checkForTokenAndLogin } from './utilities/TokenLogin';

class LoggedIn extends Component {
  constructor(props) {
    super(props);
    this.state = { accountState: {} };
  }

  componentDidMount() {
    checkForTokenAndLogin();
    identifyUser();
  }

  render() {
    return <EmbedLayout />;
  }
}

export default LoggedIn;
