import { AuthenticatedNetworkInstance } from './base-network';

export async function getFolders() {
  try {
    var response = await AuthenticatedNetworkInstance().get('/api/v1/folders');
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function updateFolder(id, folder) {
  try {
    var response = await AuthenticatedNetworkInstance().put(
      `/api/v1/folders/${id}`,
      folder,
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function linkMessageToFolder(messageId, folderId) {
  try {
    var response = await AuthenticatedNetworkInstance().put(
      `/api/v1/folders/message/${messageId}`,
      {
        folderId: folderId,
      },
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function linkFolderToParentFolder(folderId, parentFolderId) {
  try {
    var response = await AuthenticatedNetworkInstance().put(
      `/api/v1/folders/folder/${folderId}`,
      {
        folderId: parentFolderId,
      },
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function createFolder(folder) {
  try {
    var response = await AuthenticatedNetworkInstance().post(
      '/api/v1/folders',
      folder,
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function deleteFolder(id) {
  try {
    var response = await AuthenticatedNetworkInstance().delete(
      `/api/v1/folders/${id}`,
    );
    return response;
  } catch (error) {
    return error.response;
  }
}
