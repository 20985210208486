import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { trackEvent } from '../../../services/reporting-service';
import {
  findComponentAndAction,
  UpdateActions,
} from '../../../utilities/TemplateUtilities';
import { getExtraMetadataForDefinitionType } from '../definitions/definition-manager';

export default class ComponentActionField extends Component {
  // Update with new key Verification
  updateWithNewKey = (component, key) => {
    if (component.gist.id === key) {
      component.gist.id = uuidv4();
    }
    return component;
  };

  actionSelected = (e) => {
    var { component } = this.props;
    var componentId = component.gist.id;
    const componentMetadata = getExtraMetadataForDefinitionType(component.type);
    var updatedComponent = JSON.parse(JSON.stringify(component));
    var newComponent = {
      gist: {
        id: uuidv4(),
        designer: true,
      },
    };

    switch (e.target.value) {
      case 'clone-component':
        trackEvent('MESSAGE_COMPONENT_CLONED', {
          'Component Type': componentMetadata.name,
        });
        this.props.updateComponent(
          updatedComponent,
          componentId,
          UpdateActions.clone,
        );
        break;
      case 'wrap-within-block':
        trackEvent('MESSAGE_COMPONENT_WRAPPED_WITHIN', {
          'Component Type': componentMetadata.name,
          'Wrap Component Type': 'Block',
        });
        newComponent.type = 'blockWidget';
        newComponent.components = [updatedComponent];
        newComponent = findComponentAndAction(newComponent, (component) => {
          return this.updateWithNewKey(component, componentId);
        });
        this.props.updateComponent(
          newComponent,
          componentId,
          UpdateActions.update,
        );
        break;
      case 'wrap-within-action':
        trackEvent('MESSAGE_COMPONENT_WRAPPED_WITHIN', {
          'Component Type': componentMetadata.name,
          'Wrap Component Type': 'Action',
        });
        newComponent.type = 'actionWidget';
        newComponent.component = updatedComponent;
        newComponent = findComponentAndAction(newComponent, (component) => {
          return this.updateWithNewKey(component, componentId);
        });
        this.props.updateComponent(
          newComponent,
          componentId,
          UpdateActions.update,
        );
        break;
      case 'action-delete':
        trackEvent('MESSAGE_COMPONENT_DELETED', {
          'Component Type': componentMetadata.name,
        });
        this.props.deleteComponent(componentId);
        break;
      case 'order-move-up':
        trackEvent('MESSAGE_COMPONENT_ORDER_CHANGED', {
          'Component Type': componentMetadata.name,
          'Order Type': 'Move Up',
        });
        this.props.updateComponent(
          component,
          componentId,
          UpdateActions.moveUp,
        );
        break;
      case 'order-move-down':
        trackEvent('MESSAGE_COMPONENT_ORDER_CHANGED', {
          'Component Type': componentMetadata.name,
          'Order Type': 'Move Down',
        });
        this.props.updateComponent(
          component,
          componentId,
          UpdateActions.moveDown,
        );
        break;
      case 'visibility-expand-children':
        trackEvent('MESSAGE_COMPONENT_VISIBILITY_CHANGED', {
          'Component Type': componentMetadata.name,
          'Visibility Type': 'Expand Children',
        });
        this.props.updateComponent(
          component,
          componentId,
          UpdateActions.expandChildren,
        );
        break;
      case 'visibility-hide':
        trackEvent('MESSAGE_COMPONENT_VISIBILITY_CHANGED', {
          'Component Type': componentMetadata.name,
          'Visibility Type': 'Hide',
        });
        component.gist.designer = false;
        this.props.updateComponent(
          component,
          componentId,
          UpdateActions.update,
        );
        break;
      case 'visibility-show-components':
        trackEvent('MESSAGE_COMPONENT_VISIBILITY_CHANGED', {
          'Component Type': componentMetadata.name,
          'Visibility Type': 'Show Children',
        });
        this.props.updateComponent(
          component,
          componentId,
          UpdateActions.showComponents,
        );
        break;
      case 'visibility-show-parent':
        trackEvent('MESSAGE_COMPONENT_VISIBILITY_CHANGED', {
          'Component Type': componentMetadata.name,
          'Visibility Type': 'Show Parent',
        });
        this.props.updateComponent(
          component,
          componentId,
          UpdateActions.showParent,
        );
        break;
      default:
        break;
    }

    e.target.value = '';
  };

  render() {
    var { canOrder, canClone } = this.props;

    return (
      <select onChange={this.actionSelected}>
        <option value="">Actions</option>
        <optgroup label="Wrap Within">
          <option value="wrap-within-block">Block</option>
          <option value="wrap-within-action">Action</option>
        </optgroup>
        {canOrder && (
          <optgroup label="Change Order">
            <option value="order-move-up">Move Up</option>
            <option value="order-move-down">Move Down</option>
          </optgroup>
        )}
        <optgroup label="Visibility">
          <option value="visibility-hide">Hide</option>
          <option value="visibility-show-parent">Unhide Parent</option>
          <option value="visibility-show-components">Unhide Components</option>
          <option value="visibility-expand-children">Expand Children</option>
        </optgroup>
        <optgroup label="Action">
          {canClone && <option value="clone-component">Clone</option>}
          <option value="action-delete">Delete</option>
        </optgroup>
      </select>
    );
  }
}
