import { Component } from 'react';
import { RenameEditor } from './RenameEditor';
import { truncateText } from '../../../utilities/TextUtilities';
import { getExtraMetadataForDefinitionType } from '../definitions/definition-manager';
import { useComponentSVGIcon } from '../../../hooks/useComponentSVGIcon';

const TITLE_MAX_LENGTH = 65;
const TIMEOUT_TO_HIDE = 2000;
export default class ComponentTitle extends Component {
  constructor(props) {
    super(props);
    const { componentId } = this.props;
    const metadata = getExtraMetadataForDefinitionType(componentId);
    const initialTitle = this.getTitleAsString(metadata);
    this.state = {
      title: initialTitle,
      draftTitle: initialTitle,
      metadata: metadata,
    };
  }

  extractValue = (metadata) => {
    const { component, definition } = this.props;

    const regex = /\{(.*?)\}|\$(\w|[-.])+/g;
    const matches = definition.type.match(regex);

    var title = metadata?.name || definition.type;
    if (matches) {
      matches.forEach((match) => {
        // nosemgrep: javascript.lang.security.audit.incomplete-sanitization.incomplete-sanitization
        var cleanedMatch = match.replace('{', '').replace('}', '');
        if (component[cleanedMatch]) {
          title = title.replace(match, component[cleanedMatch]);
        }
      });
    }
    return title;
  };

  getTitleAsString = (metadata) => {
    const { component } = this.props;
    if (component.gist && component.gist.description) {
      return component.gist.description;
    }
    return this.extractValue(metadata);
  };

  onInputClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  onMouseOverTitle = (event) => {
    const { showEditButtonHandler } = this.props;
    showEditButtonHandler(true);
    event.stopPropagation();
    event.preventDefault();
  };

  onMouseLeaveTitle = (event) => {
    const { showEditButtonHandler } = this.props;
    setTimeout(() => {
      showEditButtonHandler(false);
    }, TIMEOUT_TO_HIDE);
    event.stopPropagation();
    event.preventDefault();
  };

  render() {
    const { isEditing, onSave, onCancel } = this.props;
    const { title, metadata } = this.state;

    if (isEditing) {
      return (
        <RenameEditor
          value={title}
          onSave={(e, value) => {
            this.setState({ title: value }, onSave(e, value));
          }}
          onInputClick={this.onInputClick}
          onCancel={(e) => onCancel(e)}
        />
      );
    }

    return (
      <div className="title-container">
        {metadata?.iconName && useComponentSVGIcon(metadata.iconName)}
        <span
          className="component-title"
          onMouseOver={this.onMouseOverTitle}
          onMouseLeave={this.onMouseLeaveTitle}
        >
          {truncateText(title, TITLE_MAX_LENGTH)}
        </span>
      </div>
    );
  }
}
