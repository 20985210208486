import { components } from 'react-select';

import {
  GRAY_200,
  GRAY_500,
  GRAY_900,
  PURPLE_700,
} from '../../../../../utilities/colors';
import { HoveredBlock } from '../HoveredBlock';
import { useComponentSVGIcon } from '../../../../../hooks/useComponentSVGIcon';
import { ComponentOptionProps, RowProps } from './types';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
  },
  labelsContainer: {
    flexDirection: 'column' as const,
  },
  iconContainer: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    height: '48px',
    width: '48px',
    marginRight: '16px',
    marginLeft: '4px',
    border: `1px solid ${GRAY_200}`,
    borderRadius: '2px',
  },
  // TODO: Define the following two styles in a typography component
  label: {
    display: 'block',
    color: GRAY_900,
    fontWeight: 600,
    marginBottom: '4px',
    fontSize: '15px',
  },
  description: {
    display: 'block',
    color: GRAY_500,
    fontWeight: 400,
    fontSize: '13px',
  },
};

export const Row = ({ iconName, label, description, isSelected }: RowProps) => {
  const Icon = useComponentSVGIcon(iconName, {
    fill: isSelected ? PURPLE_700 : GRAY_500,
  });
  return (
    <div style={styles.container}>
      {iconName && <div style={styles.iconContainer}>{Icon}</div>}
      <div style={styles.labelsContainer}>
        <span
          className="option-label"
          style={{
            ...styles.label,
          }}
        >
          {label}
        </span>
        {description && <span style={styles.description}>{description}</span>}
      </div>
    </div>
  );
};

export const ComponentOption = (props: ComponentOptionProps) => {
  const { isSelected, isFocused } = props;
  // TODO: Review the undefined possible value for description and iconName
  const { label, description, iconName, type } = props.data;
  const isBlockComponent = type === 'block';
  if (isFocused && isBlockComponent) {
    return (
      <components.Option {...props}>
        <HoveredBlock
          label={label}
          description={description ?? ''}
          iconName={iconName ?? ''}
          isSelected={isSelected}
        />
      </components.Option>
    );
  }
  return (
    <components.Option {...props}>
      <Row
        label={label}
        description={description ?? ''}
        iconName={iconName ?? ''}
        isSelected={isSelected}
      />
    </components.Option>
  );
};
