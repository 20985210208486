import { getConfiguration } from '../../services/configuration-service';
import { getDraftMessageDetails } from '../../services/message-service';
import { Template } from '../../types/common';
import { loadBrandFonts } from '../../utilities/fonts';

export const loadMessageDetails = async (
  messageId: string,
  errorHandler: (
    errorMessage: string,
    errorDescription: string,
    obj: unknown,
  ) => void,
  onSuccessCallback: (
    message: any,
    configuration: any,
    template: Template,
  ) => void,
) => {
  var message = await getDraftMessageDetails(messageId);
  if (message && message.status !== 200) {
    return errorHandler(
      'Error fetching message',
      'There was a problem fetching the message',
      message,
    );
  }
  const configuration = await getConfiguration();
  if (configuration.status !== 200) {
    return errorHandler(
      'Error fetching configuration',
      'There was a problem fetching the configuration',
      configuration,
    );
  }
  const fonts = configuration.data.assets?.fonts ?? [];
  loadBrandFonts(fonts);
  const template = JSON.parse(message.data.template);
  onSuccessCallback?.(message, configuration, template);
};
