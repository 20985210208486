import React, { Component } from 'react';
import { Select } from '../components/Select';
import { FontOption as Option } from '../components/Select/FontOption';

export default class FontsField extends Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = { internalValue: this.getDefaultValue(value) };
  }

  getDefaultValue = (value) => {
    const { defaultsToFirstOption, onChange } = this.props;
    const fontInfoFromConfig = this.getMetadataFromFontName(value);
    if (fontInfoFromConfig) {
      return { value, label: value, metadata: fontInfoFromConfig };
    }
    if (defaultsToFirstOption) {
      const firstValue = this.findFirstFontValue();
      if (firstValue) {
        onChange({ target: { value: firstValue ? firstValue.value : '' } });
        return firstValue;
      }
    }
    return null;
  };

  getMetadataFromFontName = (name) => {
    const configuration = this.props.configuration;
    let fontToReturn = null;
    configuration.style.fonts.forEach((configFont) => {
      if (configFont.name === name) {
        fontToReturn = configFont;
      }
    });
    if (fontToReturn) {
      return {
        fontFamily: fontToReturn.font,
        lineHeight: fontToReturn.lineHeight,
        size: fontToReturn.size,
      };
    }
    return null;
  };

  // Returns the first font type or null
  findFirstFontValue = () => {
    const configuration = this.props.configuration;
    const firstFont = configuration.style.fonts[0]
      ? configuration.style.fonts[0]
      : null;
    return firstFont
      ? {
          value: firstFont.name,
          label: firstFont.name,
          metadata: this.getMetadataFromFontName(firstFont),
        }
      : null;
  };

  onChangeWrapper = (option) => {
    const { onChange } = this.props;
    this.setState({ internalValue: option });
    onChange({ target: { value: option ? option.value : '' } });
  };

  render() {
    const { configuration } = this.props;
    const { internalValue } = this.state;

    return (
      <Select
        value={internalValue}
        isClearable={true}
        options={configuration.style.fonts.map((configFont) => ({
          value: configFont.name,
          label: configFont.name,
          metadata: {
            fontFamily: configFont.font,
            lineHeight: configFont.lineHeight,
            size: configFont.size,
          },
        }))}
        placeholder="Select a text style..."
        components={{ Option }}
        onChange={this.onChangeWrapper}
      />
    );
  }
}
