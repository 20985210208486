import React, { Component } from 'react';
import {
  getConfiguration,
  updateColors,
  updateFonts,
  updatePadding,
  updateTextStyles,
} from '../../services/configuration-service';
import ColorsList from './components/ColorsList';
import '../../assets/editor.scss';
import FontsList from './components/FontsList';
import PaddingList from './components/PaddingList';
import TextStylesList from './components/TextStylesList';
import { showError, showSuccess } from '../../services/embed-service';
import { getFontFilename } from '../../utilities/fonts';

// TODO: Add more specific types
class BrandEmbed extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      configuration: {
        style: { padding: [], colors: [], fonts: [] },
        assets: { fonts: [] },
      },
      fontList: [],
    };
  }

  componentDidMount() {
    this.getBrandConfiguration();
  }

  getBrandConfiguration = async () => {
    const response = await getConfiguration();
    if (response.status === 200) {
      this.setState({ configuration: response.data });
      await this.loadFonts(response.data.assets.fonts);
    } else {
      showError(
        'Error fetching brand configuration',
        'There was a problem fetching the brand configuration',
        response,
      );
    }
  };

  onColorsChange = async (colors: any) => {
    const response = await updateColors(colors);
    if (response.status === 200) {
      showSuccess('Colour configuration saved');
    } else {
      showError(
        'Error updating colors:',
        'There was a problem updating the colour configuration',
        response,
      );
    }
  };

  onFontsChange = async (fonts: any) => {
    const response = await updateFonts(fonts);
    if (response.status === 200) {
      showSuccess('Fonts configuration saved');
      await this.loadFonts(fonts);
    } else {
      showError(
        'Error updating fonts',
        'There was a problem updating the font configuration',
        response,
      );
    }
  };

  onPaddingChange = async (padding: any) => {
    const response = await updatePadding(padding);
    if (response.status === 200) {
      showSuccess('Padding configuration saved');
    } else {
      showError(
        'Error updating padding',
        'There was a problem updating the padding configuration',
        response,
      );
    }
  };

  onTextStylesChange = async (textStyles: any) => {
    const response = await updateTextStyles(textStyles);
    if (response.status === 200) {
      showSuccess('Text styles configuration saved');
    } else {
      showError(
        'Error updating text styles',
        'There was a problem updating the text styles configuration',
        response,
      );
    }
  };

  loadFonts = async (fonts: any) => {
    const fontNameList: any[] = [];
    fonts.forEach((font: any) => {
      const filename = getFontFilename(font);
      fontNameList.push(filename);
    });
    this.setState({ fontList: fontNameList });
  };

  render() {
    const { style, assets } = this.state.configuration;
    const { fontList } = this.state;

    return (
      <div className="branding-embed">
        <div className="row">
          <div className="col-xs-12">
            <ColorsList
              showHeader={true}
              colors={style.colors}
              onColorsChange={this.onColorsChange}
              readOnly={this.props.readOnly}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <FontsList
              fonts={assets.fonts}
              onFontsChange={this.onFontsChange}
              readOnly={this.props.readOnly}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <TextStylesList
              textStyles={style.fonts}
              fonts={fontList}
              onTextStylesChange={this.onTextStylesChange}
              readOnly={this.props.readOnly}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <PaddingList
              padding={style.padding}
              onPaddingChange={this.onPaddingChange}
              readOnly={this.props.readOnly}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">&nbsp;</div>
        </div>
      </div>
    );
  }
}

export default BrandEmbed;
