import Cookies from 'js-cookie';

const tempLoginName = 'gist.tempLogin';
const tokenCookieName = 'gist.token';
const organizationIdCookieName = 'gist.organizationId';
const userIdCookieName = 'gist.userId';

export function setToken(token, organizationId, expiryDate, userId) {
  Cookies.set(tokenCookieName, token, { expires: expiryDate });
  Cookies.set(organizationIdCookieName, organizationId, {
    expires: expiryDate,
  });
  Cookies.set(userIdCookieName, userId, { expires: expiryDate });
}

export function setTemporaryToken(token, organizationId, userId) {
  sessionStorage.setItem(tempLoginName, true);
  sessionStorage.setItem(tokenCookieName, token);
  sessionStorage.setItem(organizationIdCookieName, organizationId);
  sessionStorage.setItem(userIdCookieName, userId);
}

export function removeToken() {
  Cookies.remove(tokenCookieName);
  Cookies.remove(organizationIdCookieName);
  Cookies.remove(userIdCookieName);
}

export function getToken() {
  if (isTempLogin()) {
    return sessionStorage.getItem(tokenCookieName);
  } else {
    return Cookies.get(tokenCookieName);
  }
}

export function getOrganizationId() {
  if (isTempLogin()) {
    return sessionStorage.getItem(organizationIdCookieName);
  } else {
    return Cookies.get(organizationIdCookieName);
  }
}

export function getUserId() {
  if (isTempLogin()) {
    return sessionStorage.getItem(userIdCookieName);
  } else {
    return Cookies.get(userIdCookieName);
  }
}

export function getTokenOrRedirect() {
  var token = getToken();
  if (token) {
    return token;
  } else {
    window.location.pathname = '';
  }
}

function isTempLogin() {
  return sessionStorage.getItem(tempLoginName) === 'true';
}
