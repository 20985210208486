import React, { Component } from 'react';
import { fileUpload } from '../../../services/upload-service';
import { showErrorResponseNotification } from '../notifications';
import { ReactComponent as UploadIcon } from '../../../assets/images/upload.svg';

export default class FileUploadField extends Component {
  constructor(props) {
    super(props);
    this.state = { isUploading: false };
  }

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  onFileSelected = async (e) => {
    const { onFileUploaded } = this.props;
    this.setState({ isUploading: true });
    if (e.target.files.length === 1) {
      var file = e.target.files[0];
      var base64File = await this.getBase64(file);
      var base64FileSplit = base64File.split(',');
      if (base64FileSplit.length > 1) {
        var base64 = base64FileSplit[base64FileSplit.length - 1];
        var response = await fileUpload(file.name, base64);
        if (response.status === 200) {
          this.setState({ isUploading: false });
          onFileUploaded(response.data.url);
        } else {
          this.setState({ isUploading: false });
          showErrorResponseNotification(
            'Error uploading file',
            'There was a problem uploading the file',
            response,
          );
        }
      }
    }
  };

  render() {
    var id = Math.random().toString();
    var supportedTypes = this.props.supportedTypes;
    return (
      <div className="file-upload">
        <label htmlFor={id} className="button">
          <UploadIcon />
          {this.state.isUploading ? 'Uploading...' : 'Upload'}
        </label>
        <input
          type="file"
          id={id}
          onChange={this.onFileSelected}
          key={Math.random().toString()}
          accept={supportedTypes}
        />
      </div>
    );
  }
}
