import React, { Component } from 'react';
import back from '../../../assets/images/back.svg';
import {
  showErrorResponseNotification,
  showSuccessNotification,
} from '../../common/notifications';
import {
  createFolder,
  updateFolder,
  linkMessageToFolder,
} from '../../../services/folders-service';

class FolderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { icon: '', name: '' };
  }

  saveFolder = async (e) => {
    e.preventDefault();
    const { name, icon } = this.state;
    const { currentFolder, isNew, messageIds } = this.props;

    var parentId = null;
    if (currentFolder != null) {
      parentId = currentFolder.id;
    }

    if (icon !== '' && name !== '') {
      var model = {
        parentId: parentId,
        name: name,
        icon: icon,
      };

      if (isNew) {
        var response = await createFolder(model);
        if (response && response.status === 200) {
          await messageIds.forEach(async (messageId) => {
            await linkMessageToFolder(messageId, response.data.id);
          });
          showSuccessNotification('Folder created', '');
        } else {
          showErrorResponseNotification(
            'Error creating folder',
            'There was a problem creating the folder',
            response,
          );
        }
      } else {
        var response = await updateFolder(model);
        if (response && response.status === 200) {
          showSuccessNotification('Folder updated', '');
        } else {
          showErrorResponseNotification(
            'Error updating folder',
            'There was a problem updating the folder',
            response,
          );
        }
      }
    }

    this.setState({ name: '', icon: '' });
    this.props.onDismissOverlay();
  };

  render() {
    const { icon, name } = this.state;
    const { isNew } = this.props;

    return (
      <div className="modal-overlay">
        <div className="modal-overlay-container">
          <form onSubmit={this.saveFolder}>
            <div className="row">
              <div className="col-xs-12">
                <div className="action-menu">
                  <div className="page-nav">
                    <span className="back">
                      <span
                        className="tappable"
                        onClick={() => this.props.onDismissOverlay()}
                      >
                        <img src={back} alt="back" />
                      </span>
                    </span>
                    <span className="title top-title-text">
                      {isNew ? 'Create New Folder' : 'Update Folder'}
                    </span>
                  </div>
                  <div className="action-buttons">
                    <button className="button primary" type="submit">
                      {isNew ? 'Create' : 'Save'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div className="editor-card-container">
                  <div className="card padded">
                    <div className="body padding">
                      <div className="field">
                        <div className="input-field-title">Short Name</div>
                        <div className="input-field">
                          <input
                            type="text"
                            placeholder="Short Name or Emoji"
                            value={icon}
                            onChange={(e) =>
                              this.setState({ icon: e.target.value })
                            }
                            maxLength="4"
                            required
                          />
                        </div>
                      </div>
                      <div className="field">
                        <div className="input-field-title">Name</div>
                        <div className="input-field">
                          <input
                            type="text"
                            placeholder="Name"
                            value={name}
                            onChange={(e) =>
                              this.setState({ name: e.target.value })
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default FolderDetails;
