import { ReactNode } from 'react';
import ReactSelect from 'react-select';
import { SelectProps } from './types';
import {
  GRAY_100,
  GRAY_300,
  GRAY_400,
  GRAY_500,
  GRAY_700,
  PURPLE_700,
  WHITE,
} from '../../../../utilities/colors';
import { BORDER, CARET_WIDTH, TARGET_HEIGHT } from './constants';
import { CustomDropdownIndicator } from './DropdownIndicator';
import { CustomClearIndicator } from './ClearIndicator';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const formatGroupLabel = (data: any): ReactNode => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
);

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    height: `${TARGET_HEIGHT}px`,
    minHeight: `${TARGET_HEIGHT}px`,
    border: state.isFocused
      ? `1px solid ${PURPLE_700}`
      : `1px solid ${GRAY_100}`,
    boxShadow: state.isFocused ? 'none' : provided.boxShadow,
    '&:hover': {
      border: `1px solid ${PURPLE_700}`,
      boxShadow: `none`,
    },
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    color: GRAY_400,
    padding: '4px !important',
    marginRight: '2px !important',
    img: {
      width: `${10}px`,
      height: `${10}px`,
    },
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: GRAY_400,
    img: {
      width: `${CARET_WIDTH}px`,
      height: `${CARET_WIDTH}px`,
    },
  }),
  group: (provided: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  groupHeading: (provided: any) => ({
    ...provided,
    backgroundColor: GRAY_100,
    color: GRAY_500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '11px',
    fontWeight: 600,
    height: '28px',
    paddingLeft: '20px',
  }),
  input: (provided: any, props: any) => ({
    ...provided,
    // display: props.hasValue || props.isHidden ? 'none !important' : provided.display,
    margin: 0,
    padding: 0,
    height: `${TARGET_HEIGHT - 2 * BORDER}px`,
    minHeight: `${TARGET_HEIGHT - 2 * BORDER}px`,
    fontSize: '12px',
    color: GRAY_700,
    fontWeight: 400,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    fontSize: '12px',
    color: GRAY_700,
    fontWeight: 400,
  }),
  // state is passed as the second param: state.isSelected
  option: (provided: any, { isSelected }: any) => ({
    ...provided,
    backgroundColor: isSelected ? GRAY_100 : WHITE,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: `${isSelected ? PURPLE_700 : GRAY_700} !important`,
    '.option-label': {
      color: `${isSelected ? PURPLE_700 : GRAY_700} !important`,
      width: '60%',
      textOverflow: 'ellipsis',
    },
    ':hover': {
      ...provided[':hover'],
      color: GRAY_700,
      backgroundColor: GRAY_100,
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontWeight: 400,
  }),
  singleValue: (provided: any, { isDisabled, isSelected }: any) => ({
    ...provided,
    fontSize: '12px',
    color: GRAY_700,
    fontWeight: 400,
    overflow: 'hidden',
    marginRight: '4px !important',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    marginRight: '0px !important',
    padding: '0px 2px 0px 8px',
    height: `${TARGET_HEIGHT - 2 * BORDER}px`,
    minHeight: `${TARGET_HEIGHT - 2 * BORDER}px`,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
};

export const Select = (props: SelectProps) => {
  const { options, styles, components } = props;
  const optionsAreGrouped = options.length > 0 && options[0].label != null;
  return (
    <ReactSelect
      {...props}
      onKeyDown={(event) => {
        if ('Enter' === event.key) {
          event.preventDefault();
        }
      }}
      menuPosition="absolute"
      menuPlacement="auto"
      menuPortalTarget={document.body}
      noOptionsMessage={() => 'No results found'}
      components={{
        ...components,
        DropdownIndicator: CustomDropdownIndicator,
        ClearIndicator: CustomClearIndicator,
      }}
      styles={{ ...customStyles, ...styles }}
      options={options}
      {...(optionsAreGrouped && { formatGroupLabel: formatGroupLabel })}
    />
  );
};
