import { Buffer } from 'buffer';
import { setTemporaryToken } from '../services/token-service';

export function checkForTokenAndLogin() {
  const query = new URLSearchParams(window.location.search);
  const token = query.get('token');

  if (token != null) {
    // eslint-disable-next-line no-undef
    const jsonToken = JSON.parse(Buffer.from(token, 'base64'));
    setTemporaryToken(
      jsonToken.token,
      jsonToken.organizationId,
      jsonToken.userId,
    );
    window.location.href = window.location.href.split('?')[0];
  }
}
