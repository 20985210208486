import * as React from 'react';

// Layout components
import { ReactComponent as ActionIcon } from '../assets/images/components/action.svg';
import { ReactComponent as BlockIcon } from '../assets/images/components/block.svg';
import { ReactComponent as CarouselIcon } from '../assets/images/components/carousel.svg';
import { ReactComponent as ConditionalIcon } from '../assets/images/components/conditional.svg';
import { ReactComponent as GridIcon } from '../assets/images/components/grid.svg';
import { ReactComponent as HorizontalListIcon } from '../assets/images/components/horizontal-list.svg';
import { ReactComponent as IconIcon } from '../assets/images/components/icon.svg';
import { ReactComponent as ImageIcon } from '../assets/images/components/image.svg';
import { ReactComponent as MarkdownIcon } from '../assets/images/components/markdown.svg';
import { ReactComponent as TextIcon } from '../assets/images/components/text.svg';
import { ReactComponent as VerticalListIcon } from '../assets/images/components/vertical-list.svg';

// Blocks
import { ReactComponent as CloseButton } from '../assets/images/components/closeButton.svg';
import { ReactComponent as Button } from '../assets/images/components/button.svg';
import { ReactComponent as CsatNumberSurvey } from '../assets/images/components/csatNumbersSurvey.svg';
import { ReactComponent as TwoButtonRow } from '../assets/images/components/twoButtons.svg';
import { ReactComponent as CsatEmojiSurvey } from '../assets/images/components/csatEmojiSurvey.svg';
import { ReactComponent as RoundedImage } from '../assets/images/components/roundedImage.svg';
import { ReactComponent as TwoOptionSurvey } from '../assets/images/components/twoOptionSurvey.svg';
import { ReactComponent as FiveOptionSurvey } from '../assets/images/components/fiveOptionSurvey.svg';
import { ReactComponent as MessageFrame } from '../assets/images/components/messageFrame.svg';

import { GRAY_500 } from '../utilities/colors';

export interface Options {
  width?: string | number;
  height?: string | number;
  fill?: string;
}

// This is not dynamically importing the svgs
// because of this known issue with CRA (create react app) and webpack
// https://ryanhutzley.medium.com/dynamic-svg-imports-in-create-react-app-d6d411f6d6c6
export const useComponentSVGIcon = (name?: string, options?: Options) => {
  // Default width and height
  const iconOptions = {
    width: 24,
    height: 24,
    fill: GRAY_500,
    ...options,
  };
  switch (name) {
    case 'block':
      return <BlockIcon {...iconOptions} />;
    case 'action':
      return <ActionIcon {...iconOptions} />;
    case 'text':
      return <TextIcon {...iconOptions} />;
    case 'image':
      return <ImageIcon {...iconOptions} />;
    case 'markdown':
      return <MarkdownIcon {...iconOptions} />;
    case 'vertical-list':
      return <VerticalListIcon {...iconOptions} />;
    case 'horizontal-list':
      return <HorizontalListIcon {...iconOptions} />;
    case 'grid':
      return <GridIcon {...iconOptions} />;
    case 'carousel':
      return <CarouselIcon {...iconOptions} />;
    case 'icon':
      return <IconIcon {...iconOptions} />;
    case 'conditional':
      return <ConditionalIcon {...iconOptions} />;
    // Avoid passing width 24 and height 24 to make the svg flexible for the block previews
    case 'closeButton':
      return <CloseButton />;
    case 'messageFrame':
      return <MessageFrame />;
    case 'button':
      return <Button />;
    case 'twoButtonRow':
      return <TwoButtonRow />;
    case 'roundedImage':
      return <RoundedImage />;
    case 'twoOptionSurvey':
      return <TwoOptionSurvey />;
    case 'fiveOptionSurvey':
      return <FiveOptionSurvey />;
    case 'csatSurveyNumbers':
      return <CsatNumberSurvey />;
    case 'csatSurveyEmojis':
      return <CsatEmojiSurvey />;
    default:
      return null;
  }
};
