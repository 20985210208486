import React, { useCallback, useState } from 'react';
import { Select } from '../components/Select';
import { SelectOption } from '../components/Select/types';

interface MainAxisAlignmentFieldProps {
  value: any;
  onChange: (value: any) => void;
  selectPlaceholder?: string;
}

export const MainAxisAlignmentField = ({
  value,
  onChange,
  selectPlaceholder,
}: MainAxisAlignmentFieldProps) => {
  const [options] = useState<SelectOption[]>([
    { value: 'start', label: 'Start' },
    { value: 'end', label: 'End' },
    { value: 'spaceBetween', label: 'Space Between' },
    { value: 'spaceAround', label: 'Space Around' },
    { value: 'spaceEvenly', label: 'Space Evenly' },
  ]);

  const getDefaultValue = useCallback(
    (value: string) => {
      const option = options.find((option) => option.value === value);
      return option ? option : null;
    },
    [options],
  );

  const onChangeWrapper = useCallback(
    (option: any) => {
      onChange({ target: { value: option ? option.value : '' } });
    },
    [onChange],
  );

  return (
    <div className="select-container">
      <Select
        defaultValue={getDefaultValue(value)}
        isClearable={true}
        options={options}
        placeholder={selectPlaceholder ?? 'Select main axis alignment...'}
        onChange={onChangeWrapper}
      />
    </div>
  );
};
