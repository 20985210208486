import React, { Component } from 'react';
import MessageDetails from './MessageDetails';
import MessageList from './MessageList';
import TemplateStyling from './TemplateStyling';
import TemplateSelectorField from './fields/TemplateSelectorField';
import { extractTemplateStyles } from '../../../utilities/StyleUtilities';
import { getMessageDetails } from '../../../services/message-service';
import { getConfiguration } from '../../../services/configuration-service';
import templates from '../../../assets/default-templates.json';
import { showErrorResponseNotification } from '../../common/notifications';
import { isTemplateCompatible } from '../../../utilities/StyleUtilities';
import '../../../assets/new-message.scss';
import { trackEvent } from '../../../services/reporting-service';

class NewMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usingDataSources: false,
      configurationLoaded: false,
      templateIssue: false,
      selectedTemplateId: '',
      selectedMessageId: '',
      templateChanges: {},
      step: 1,
    };
  }

  componentDidMount() {
    this.loadConfiguration();
  }

  loadConfiguration = async () => {
    var configuration = await getConfiguration();
    if (configuration && configuration.status === 200) {
      this.setState({
        configuration: configuration.data,
        configurationLoaded: true,
      });
    } else {
      showErrorResponseNotification(
        'Error loading configuration',
        'There was a problem loading the configuration',
        configuration,
      );
    }
  };

  onValueChange = (name, value) => {
    var message = this.state.message;
    message[name] = value;
    this.setState({ message: message }, () => {
      this.props.onMessageChange(message);
    });
  };

  onTemplateSelected = (id, template, defaultPosition, templateName) => {
    var message = this.state.message;
    message.template = JSON.stringify(template);
    message.position = defaultPosition;
    var templateIssue = !this.isBaseTemplateCompatible(template);
    var templateChanges = extractTemplateStyles(template);
    trackEvent('MESSAGE_CREATION_TEMPLATE_SELECTED', {
      'Template Type': templateName,
    });
    this.setState(
      {
        selectedTemplateId: id,
        template: message.template,
        templateIssue: templateIssue,
        templateChanges: templateChanges,
        message: message,
      },
      () => {
        this.proceedToNext();
      },
    );
  };

  static getDerivedStateFromProps(props, state) {
    return { message: props.message };
  }

  onNextTapped = (event) => {
    event.preventDefault();
    if (event.target.form.checkValidity()) {
      trackEvent('MESSAGE_CREATION_BRANDING_MAPPED');
      this.proceedToNext();
    } else {
      event.target.form.reportValidity();
    }
  };

  proceedToNext = () => {
    const currentStep = this.state.step;
    var nextStep = currentStep + 1;
    if (
      nextStep === 2 &&
      this.state.selectedTemplateId !== 'clone' &&
      !this.state.templateIssue
    ) {
      nextStep = 4;
    } else if (
      nextStep === 2 &&
      this.state.selectedTemplateId !== 'clone' &&
      this.state.templateIssue
    ) {
      nextStep = 3;
    } else if (nextStep === 3 && !this.state.templateIssue) {
      nextStep = 4;
    }
    this.setState({ step: nextStep });
  };

  onPreviousTapped = (event) => {
    var nextStep = this.state.step - 1;
    if (nextStep === 2 && this.state.selectedTemplateId !== 'clone') {
      nextStep = 1;
    } else if (
      nextStep === 3 &&
      !this.state.templateIssue &&
      this.state.selectedTemplateId !== 'clone'
    ) {
      nextStep = 1;
    } else if (
      nextStep === 3 &&
      !this.state.templateIssue &&
      this.state.selectedTemplateId === 'clone'
    ) {
      nextStep = 2;
    }
    this.setState({ step: nextStep });
  };

  onTemplateStyleChanged = (template, changes) => {
    var message = this.state.message;
    message['template'] = template;
    this.setState({ message: message, templateChanges: changes });
  };

  messageSelected = async (messageId) => {
    var message = await getMessageDetails(messageId);
    if (message && message.status === 200) {
      var templateChanges = extractTemplateStyles(
        JSON.parse(message.data.template),
      );
      var templateIssue = !this.isBaseTemplateCompatible(
        JSON.parse(message.data.template),
      );
      message = {
        folderId: this.state.message.folderId,
        template: message.data.template,
        position: message.data.position,
        data: message.data.data,
      };
      this.setState({
        selectedMessageId: messageId,
        message: message,
        template: message.template,
        templateIssue: templateIssue,
        templateChanges: templateChanges,
        usingDataSources: message.data.length > 0,
      });
      this.props.onMessageChange(message);
      this.proceedToNext();
    } else {
      showErrorResponseNotification(
        'Error loading message',
        'There was a problem loading the message',
        message,
      );
    }
  };

  isBaseTemplateCompatible = (template) => {
    return isTemplateCompatible(template, this.state.configuration);
  };

  fetchWidthStyles = () => {
    const { isEmbedded } = this.props;
    if (isEmbedded) {
      return 'col-xs-12';
    } else {
      return 'col-xs-12 col-sm-offset-1 col-sm-10';
    }
  };

  fetchContainerStyles = () => {
    const { isEmbedded } = this.props;
    if (isEmbedded) {
      return 'editor-card-container';
    } else {
      return 'editor-card-container spacer';
    }
  };

  selectTemplateOption = () => {
    return (
      <TemplateSelectorField
        onValueChange={this.onTemplateSelected}
        templates={templates}
        value={this.state.selectedTemplateId}
      />
    );
  };

  configureTemplate = () => {
    return (
      <div>
        <div className="card-text title">Make it your own</div>
        {!this.props.isEmbedded && (
          <div className="card-text description">
            Replace the missing styles with your{' '}
            <a href="/account/branding">brand</a>.
          </div>
        )}
        <div className="card">
          <TemplateStyling
            template={this.state.template}
            onTemplateStyleChanged={this.onTemplateStyleChanged}
            templateChanges={this.state.templateChanges}
            configuration={this.state.configuration}
          />
        </div>
      </div>
    );
  };

  selectTemplateToClone = () => {
    return (
      <div>
        <div className="card-text title">Select an existing message</div>
        <MessageList
          selectAction={this.messageSelected}
          selectedMessageId={this.state.selectedMessageId}
          emptyListMessage="In order to clone a message you need to have existing messages"
        />
      </div>
    );
  };

  finalTouches = (message, isNew) => {
    return (
      <div>
        <span className="card-text title">Final touches</span>
        <div className="card">
          <MessageDetails
            onValueChange={this.onValueChange}
            message={message}
            isNew={isNew}
            isEmbedded={this.props.isEmbedded}
          />
        </div>
      </div>
    );
  };

  nextBackButtons = () => {
    const { isEmbedded } = this.props;
    const currentStep = this.state.step;
    var canShowBack = currentStep > 1;
    var canShowContinue = currentStep == 3 && this.state.configurationLoaded;
    var canShowCreateMesssage = currentStep === 4;

    return currentStep > 1 ? (
      <div
        className={
          isEmbedded ? 'new-message-steps embedded' : 'new-message-steps'
        }
      >
        <div className="buttons">
          {canShowBack && (
            <button
              className="alt button"
              type="button"
              onClick={this.onPreviousTapped}
            >
              Back
            </button>
          )}
          {canShowContinue && (
            <button
              className="primary button"
              type="button"
              onClick={this.onNextTapped}
            >
              Continue
            </button>
          )}
          {canShowCreateMesssage && (
            <button
              data-testid="create-message-button"
              className={`primary button ${
                this.props.readOnly || this.props.isSaving ? 'disabled' : ''
              }`}
              type="submit"
              disabled={this.props.readOnly || this.props.isSaving}
            >
              {this.props.isSaving ? 'Creating...' : 'Create Message'}
            </button>
          )}
        </div>
      </div>
    ) : (
      <div />
    );
  };

  steps = (step, message, isNew) => {
    switch (step) {
      case 1:
        return this.selectTemplateOption(message);
      case 2:
        return this.selectTemplateToClone(message);
      case 3:
        return this.configureTemplate();
      case 4:
        return this.finalTouches(message, isNew);
      default:
        return;
    }
  };

  render() {
    const { message, step } = this.state;
    const { isNew } = this.props;
    return (
      <div className="extra-bottom-margin">
        <div className="row">
          <div className={this.fetchWidthStyles()}>
            <div className={this.fetchContainerStyles()}>
              {this.steps(step, message, isNew)}
            </div>
          </div>
        </div>
        {this.nextBackButtons()}
      </div>
    );
  }
}

export default NewMessage;
