import { useCallback, useState } from 'react';
import { ToggleOption, ToggleProps } from './types';
import '../../../assets/toggle.scss';
import { GRAY_100 } from '../../../utilities/colors';

export const Toggle = ({
  options,
  onToggleChange,
  height,
  removeBorder = false,
}: ToggleProps) => {
  const [activeOption, setActiveOption] = useState(options[0]);

  const toggleOption = useCallback(
    (option: ToggleOption) => {
      setActiveOption(option);
      onToggleChange(option);
    },
    [onToggleChange],
  );

  const defaultStyles = {
    height: '36px',
    border: `1px solid ${GRAY_100}`,
  };

  return (
    <div
      style={{
        height: height ? `${height}px` : defaultStyles.height,
        border: removeBorder ? 'none' : defaultStyles.border,
      }}
      className="toggle"
    >
      {options.map((option: ToggleOption) => (
        <button
          key={option.value}
          className={`toggle-option ${
            activeOption.value === option.value ? 'active' : ''
          }`}
          onClick={() => toggleOption(option)}
        >
          {option.icon && <span className="icon">{option.icon}</span>}
          <label>{option.label}</label>
        </button>
      ))}
    </div>
  );
};
