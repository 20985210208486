import { v4 as uuidv4 } from 'uuid';

export const SingleObjectContainerNames = [
  'component',
  'template',
  'true',
  'false',
];
export const MultipleObjectsContainerNames = ['components'];
export const UpdateActions = {
  update: 'update',
  moveUp: 'moveUp',
  moveDown: 'moveDown',
  showParent: 'showParent',
  showComponents: 'showComponents',
  expandChildren: 'expandChildren',
  clone: 'clone',
};

export function verifyTemplateIds(template) {
  return findComponentsAndAction(template, checkOrAddKey);
}

export function updateComponentWithActionFromTemplate(
  template,
  updatedComponent,
  componentId,
  action,
) {
  switch (action) {
    case UpdateActions.moveUp:
      return moveComponent(template, componentId, UpdateActions.moveUp);
    case UpdateActions.moveDown:
      return moveComponent(template, componentId, UpdateActions.moveDown);
    case UpdateActions.showParent:
      return showParent(template, componentId);
    case UpdateActions.showComponents:
      return updateGistPropertyWithinComponentAndChildren(
        template,
        componentId,
        'designer',
        true,
      );
    case UpdateActions.expandChildren:
      return updateGistPropertyWithinComponentAndChildren(
        template,
        componentId,
        'showSettings',
        true,
      );
    case UpdateActions.clone:
      return cloneComponent(template, updatedComponent);
    default:
      return updateComponentFromTemplate(
        template,
        updatedComponent,
        componentId,
      );
  }
}

function cloneComponent(template, component) {
  var parentComponent = findParentComponentFromTemplate(
    template,
    component.gist.id,
  );
  if (parentComponent == null) {
    template.push(component);
    template = refreshComponentsIds(template);
    return template;
  } else {
    parentComponent.components.push(component);
    var components = refreshComponentsIds(parentComponent.components);
    parentComponent.components = components;
    return updateComponentFromTemplate(
      template,
      parentComponent,
      parentComponent.gist.id,
    );
  }
}

export function deleteComponentFromTemplate(template, retiredComponentId) {
  return findComponentsAndAction(template, (component) => {
    return deleteComponent(component, retiredComponentId);
  });
}

export function findComponentFromTemplate(template, id) {
  return findComponentWithIdFromComponents(template, id, false);
}

export function findParentComponentFromTemplate(template, id) {
  var component = findComponentWithIdFromComponents(template, id, true);
  if (component.gist.id === id) {
    return null;
  } else {
    return component;
  }
}

function updateComponentFromTemplate(template, updatedComponent, componentId) {
  return findComponentsAndAction(template, (component) => {
    return updateComponent(component, updatedComponent, componentId);
  });
}

function showParent(template, id) {
  var parentComponent = findParentComponentFromTemplate(template, id);
  if (parentComponent !== null) {
    parentComponent.gist.designer = true;
  }
  return template;
}

function updateGistPropertyWithinComponentAndChildren(
  template,
  id,
  propertyName,
  value,
) {
  var component = findComponentFromTemplate(template, id);
  var containers = hasContainers(component);
  containers.forEach((container) => {
    if (container.multiple) {
      component[container.key].forEach((innerContainer) => {
        if (innerContainer.gist) {
          innerContainer.gist[propertyName] = value;
        }
      });
    } else {
      var innerContainer = component[container.key];
      if (innerContainer.gist) {
        innerContainer.gist[propertyName] = value;
      }
    }
  });
  return template;
}

function moveComponent(template, id, direction) {
  var parentId = null;
  var componentsToMove = template;
  var parentComponent = findParentComponentFromTemplate(template, id);
  if (parentComponent !== null) {
    parentId = parentComponent.gist.id;
    componentsToMove = parentComponent.components;
  }
  var currentIndex = componentsToMove.findIndex((item) => item.gist.id === id);
  if (currentIndex !== -1) {
    var newIndex = currentIndex;
    if (direction === UpdateActions.moveDown) {
      if (currentIndex !== componentsToMove.length) {
        newIndex = currentIndex + 1;
      }
    } else {
      if (currentIndex !== 0) {
        newIndex = currentIndex - 1;
      }
    }
    var objectToMove = componentsToMove.splice(currentIndex, 1)[0];
    componentsToMove.splice(newIndex, 0, objectToMove);

    if (parentId !== null) {
      return updateComponentFromTemplate(template, parentComponent, parentId);
    } else {
      return componentsToMove;
    }
  }
}

// Key Verification

function checkOrAddKey(component) {
  if (component) {
    if (component.gist === undefined) {
      component.gist = {};
    }
    if (component.gist.id === undefined) {
      component.gist.id = uuidv4();
    }
    return component;
  }
}

// Refresh Key

function refreshKey(component) {
  if (component) {
    if (component.gist === undefined) {
      component.gist = {};
    }
    component.gist.id = uuidv4();
    return component;
  }
}

// Update Component
function updateComponent(component, updatedComponent, id) {
  return component.gist.id === id ? updatedComponent : component;
}

// Delete Component

function deleteComponent(component, retiredComponentId) {
  return component.gist.id !== retiredComponentId && component;
}

// Exported Helpers
export function refreshComponentsIds(components) {
  var refreshedComponent = [];

  if (!components) {
    return refreshedComponent;
  }

  components.forEach((component) => {
    if (component) {
      component = refreshComponentIds(component);
      refreshedComponent.push(component);
    }
  });

  return refreshedComponent;
}

export function refreshComponentIds(component) {
  component = refreshKey(component);
  var containers = hasContainers(component);
  containers.forEach((container) => {
    if (container.multiple) {
      component[container.key] = refreshComponentsIds(component[container.key]);
    } else {
      component[container.key] = refreshKey(component[container.key]);
      if (component[container.key].hasOwnProperty('components')) {
        refreshComponentsIds(component[container.key].components);
      }
    }
  });
  return component;
}

export function findComponentsAndAction(components, action) {
  var actionedComponents = [];
  components.forEach((component) => {
    var actionedComponent = findComponentAndAction(component, action);
    actionedComponent && actionedComponents.push(actionedComponent);
  });
  return actionedComponents;
}

export function findComponentAndAction(component, action) {
  component = action(component);

  if (component) {
    var containers = hasContainers(component);
    containers.forEach((container) => {
      if (container.multiple) {
        component[container.key] = findComponentsAndAction(
          component[container.key],
          action,
        );
      } else {
        var actionedComponent = findComponentAndAction(
          component[container.key],
          action,
        );
        if (actionedComponent) {
          component[container.key] = actionedComponent;
        } else {
          delete component[container.key];
        }
      }
    });
    return component;
  }
}

// Private Helpers

function hasContainers(component) {
  var containers = [];
  SingleObjectContainerNames.forEach((key) => {
    if (component.hasOwnProperty(key)) {
      containers.push({ key: key, multiple: false });
    }
  });
  MultipleObjectsContainerNames.forEach((key) => {
    if (component.hasOwnProperty(key)) {
      containers.push({ key: key, multiple: true });
    }
  });
  return containers;
}

function findComponentWithIdFromComponents(
  components,
  componentId,
  withParent,
) {
  var foundComponent = null;
  components.forEach((component) => {
    const tempComponent = findComponentWithIdFromComponent(
      component,
      componentId,
      withParent,
    );
    if (tempComponent !== null) {
      foundComponent = tempComponent;
    }
  });
  return foundComponent;
}

function findComponentWithIdFromComponent(component, componentId, withParent) {
  var foundComponent = null;
  if (component.gist.id === componentId) {
    foundComponent = component;
  } else {
    var containers = hasContainers(component);
    containers.forEach((container) => {
      if (container.multiple) {
        foundComponent = findComponentWithIdFromComponents(
          component[container.key],
          componentId,
          withParent,
        );
      } else {
        foundComponent = findComponentWithIdFromComponent(
          component[container.key],
          componentId,
          withParent,
        );
      }
      if (
        withParent &&
        foundComponent &&
        foundComponent.gist.id === componentId
      ) {
        foundComponent = component;
      }
    });
  }
  return foundComponent;
}

export function isEmbedded(location) {
  return location.pathname.startsWith('/embed');
}
