import React from 'react';
import { components } from 'react-select';
import { GRAY_900 } from '../../../../../utilities/colors';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  // TODO: Define the following two styles in a typography component
  label: { color: GRAY_900 },
};

export const FontOption = (props: any) => {
  const { label, metadata } = props.data;

  return (
    <components.Option {...props}>
      <div style={styles.container}>
        <span
          className="option-label"
          style={{
            ...styles.label,
            fontFamily: `${metadata.fontFamily}, system-ui`,
            fontSize: metadata.size,
          }}
        >
          {label}
        </span>
      </div>
    </components.Option>
  );
};
