import React, { Component } from 'react';

type RenameEditorProps = {
  value: string;
  onSave: (event: any, value: string) => Promise<void>;
  onCancel: (event: any) => void;
  onInputClick?: (event: any) => void;
  onValueChange?: (event: any) => void;
};

type RenameEditorState = {
  value: string;
  isSaving: boolean;
};

export class RenameEditor extends Component<
  RenameEditorProps,
  RenameEditorState
> {
  private readonly inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: any) {
    super(props);
    this.inputRef = React.createRef();
    this.state = { value: this.props.value, isSaving: false };
  }

  componentDidMount() {
    this.inputRef.current?.focus();
  }

  onValueChange = (event: any) => {
    this.setState({ value: event.target.value });
    this.props.onValueChange?.(event);
  };

  onSaveButtonClick = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isSaving: true });
    await this.props.onSave(e, this.state.value.trim());
    this.setState({ isSaving: false });
  };

  onCancelClick = (e: any) => {
    this.setState({ value: this.props.value });
    this.props.onCancel(e);
  };

  render() {
    const { onInputClick } = this.props;
    const { value } = this.state;

    return (
      <div className="actions-container">
        <div className="input-field">
          <input
            title={
              value === '' ? 'Component description cannot be empty' : undefined
            }
            ref={this.inputRef}
            className={value === '' ? 'highlight' : ''}
            type="text"
            value={value}
            onClick={onInputClick}
            onChange={this.onValueChange}
          />
        </div>
        <div className="action-buttons">
          <button
            disabled={value === '' || this.state.isSaving}
            className="button primary"
            onClick={this.onSaveButtonClick}
          >
            {this.state.isSaving ? 'Saving...' : 'Save'}
          </button>
          <button className="button" onClick={this.onCancelClick}>
            Cancel
          </button>
        </div>
      </div>
    );
  }
}
