import { ToggleOption } from '../common/toggle/types';
import LivePreview from './LivePreview';

interface LivePreviewContainerProps {
  message: any;
  onTargetDeviceChange: (option: ToggleOption) => void;
}

export const LivePreviewContainer = ({
  message,
}: LivePreviewContainerProps) => {
  return (
    <div className="live-preview-card">
      <div className="header">
        <h3>Preview</h3>
      </div>
      <div className="live-preview-container embedded pt-0 p-4">
        {message.routeParameters !== '' ? (
          <input
            className="query-field"
            type="text"
            placeholder={message.routeParameters}
            defaultValue={message.routeParameters}
          />
        ) : (
          <span />
        )}
        <LivePreview messageId={message.id} draft={true} livePreview={true} />
      </div>
    </div>
  );
};
