import React, { useEffect, useRef, useState } from 'react';
import { components } from 'react-select';
import { GRAY_200, GRAY_700 } from '../../../../../utilities/colors';

const styles = {
  container: {
    overflow: 'hidden',
    width: 'fit-content',
  },
  dot: {
    width: '20px',
    height: '20px',
    border: `1px solid ${GRAY_200}`,
    borderRadius: '50%',
    marginRight: '8px',
  },
  // TODO: Define the following one in a typography component
  label: {
    display: 'inline-block',
    color: GRAY_700,
    fontSize: '12px',
    fontWeight: 400,
  },
};

export const ColorSingleValue = (props: any) => {
  const { label, value } = props.data;

  return (
    <components.SingleValue {...props}>
      <div style={styles.container}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          <div
            style={{
              ...styles.dot,
              backgroundColor: value,
            }}
          />
          <label style={styles.label}>{label}</label>
        </div>
      </div>
    </components.SingleValue>
  );
};
