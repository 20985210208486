import { Fragment, memo, useEffect, useRef, useState } from 'react';

import { LivePreviewContainer } from '../livePreview/LivePreviewContainer';
import BuilderView from './components/BuilderView';
import { Layers } from './Layers';
import { DropOptions, NodeModel } from '@minoru/react-dnd-treeview';
import { CustomData } from './Layers/types';
import { Template } from '../../types/common';
import { isEqual } from 'lodash';

interface EditorContentProps {
  template: Template;
  layersArray: NodeModel<CustomData>[];
  onLayersDropHandler: (
    tree: NodeModel<CustomData>[],
    options: DropOptions<CustomData>,
  ) => void;
  openNodes: (string | number)[];
  setOpenNodes: (openNodes: (string | number)[]) => void;
  configuration: any;
  updateTemplate: (template: Template) => unknown;
  activeView: 'designer' | 'code';
  layersVisible: boolean;
  previewVisible: boolean;
  message: any;
  onTargetDeviceChange: (option: any) => void;
}

const EditorContentComponent = memo(
  ({
    template,
    layersArray,
    openNodes,
    setOpenNodes,
    configuration,
    updateTemplate,
    activeView,
    layersVisible,
    previewVisible,
    message,
    onTargetDeviceChange,
    onLayersDropHandler,
  }: EditorContentProps) => {
    const layersPanelRef = useRef<HTMLDivElement>(null);

    const handleWheel = (event: WheelEvent) => {
      if (!layersPanelRef.current) return;
      // // Check if the user is scrolling horizontally and do nothing in that case
      // if (Math.abs(event.deltaX) > 0) return;

      const delta = event.deltaY;
      const scrollTop = layersPanelRef.current.scrollTop;
      const scrollHeight = layersPanelRef.current.scrollHeight;
      const height = layersPanelRef.current.clientHeight;

      const isAtTop = scrollTop === 0;
      const isAtBottom = Math.abs(height + scrollTop - scrollHeight) <= 1;

      const isScrollingDown = delta > 0;

      if ((isScrollingDown && isAtBottom) || (!isScrollingDown && isAtTop)) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    useEffect(() => {
      const panel = layersPanelRef.current as HTMLDivElement;
      if (panel) {
        panel.addEventListener('wheel', handleWheel);
      }
      // Clean up the event listener when the component is unmounted
      return () => {
        if (panel) {
          panel.removeEventListener('wheel', handleWheel);
        }
      };
    }, []);

    return (
      <Fragment>
        {layersVisible && (
          <div className="flex-1 layers-wrapper">
            <div className="layers sticky">
              <div className="card">
                <div className="header">
                  <h3>Layers</h3>
                </div>
                <div ref={layersPanelRef} className="layers-container">
                  <Layers
                    treeData={layersArray}
                    openNodes={openNodes}
                    setOpenNodes={setOpenNodes}
                    onDropHandler={onLayersDropHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div id="editor-container">
          <form id="builder-view" onSubmit={(e) => e.preventDefault()}>
            <BuilderView
              template={template}
              configuration={configuration}
              updateTemplate={updateTemplate}
              activeView={activeView}
            />
          </form>
        </div>
        {previewVisible && (
          <div className="live-preview-wrapper">
            <div className="live-preview sticky">
              <LivePreviewContainer
                message={message}
                onTargetDeviceChange={onTargetDeviceChange}
              />
            </div>
          </div>
        )}
      </Fragment>
    );
  },
);

EditorContentComponent.displayName = 'EditorContent';

export const EditorContent = EditorContentComponent;
