import React, { Component } from 'react';
import FontEditor from './FontEditor';
import AddImage from '../../../assets/images/large-plus.svg';
import FontImage from '../../../assets/images/font.svg';
import { trackEvent } from '../../../services/reporting-service';

class FontsList extends Component {
  clearSelected = { id: -1, font: null };

  constructor(props) {
    super(props);
    this.state = { selected: this.clearSelected, isNew: false, fonts: [] };
  }

  static getDerivedStateFromProps(props, state) {
    var updatedState = state;
    updatedState.fonts = props.fonts;
    return updatedState;
  }

  fontSelected = (id, font) => {
    if (this.state.selected.id === id) {
      this.resetSelection();
    } else {
      this.setState({ isNew: false, selected: { id: id, font: font } });
      trackEvent('BRANDING_EDIT_STARTED', {
        Name: this.cleanUpName(font) ?? 'Unknown font name',
        Type: 'Font',
      });
    }
  };

  toggleNewFont = () => {
    this.resetSelection();
    this.setState({ isNew: !this.state.isNew });
    trackEvent('BRANDING_CREATION_STARTED', { Type: 'Font' });
  };

  resetSelection = () => {
    this.setState({ isNew: false, selected: this.clearSelected });
  };

  removeFont = (index) => {
    var fonts = this.state.fonts;
    trackEvent('BRANDING_DELETED', {
      Name: this.cleanUpName(fonts[index]) ?? 'Unknown font name',
      Type: 'Font',
    });
    fonts.splice(index, 1);
    this.setState({ fonts: fonts });
    this.props.onFontsChange(fonts);
    this.resetSelection();
  };

  saveFont = (font, index) => {
    var fonts = this.state.fonts;
    if (index != null) {
      fonts[index] = font;
      trackEvent('BRANDING_EDIT_COMPLETED', {
        Name: this.cleanUpName(font) ?? 'Unknown font name',
        Value: this.cleanUpName(font),
        Type: 'Font File',
      });
    } else {
      fonts.push(font);
      trackEvent('BRANDING_CREATION_COMPLETED', {
        Name: this.cleanUpName(font) ?? 'Unknown font name',
        Value: this.cleanUpName(font),
        Type: 'Font File',
      });
    }
    this.setState({ fonts: fonts });
    this.props.onFontsChange(fonts);
    this.resetSelection();
  };

  cleanUpName = (font) => {
    var fontSplit = font.split('/');
    return fontSplit[fontSplit.length - 1];
  };

  render() {
    const { fonts, selected, isNew } = this.state;

    return (
      <div className="editor-card-container spacer">
        <span className="card-text title">Fonts</span>
        <div className="card shadow">
          <div className="body alt">
            <div className="grid">
              {fonts.map((font, id) => {
                return (
                  <div key={id}>
                    <div
                      className="field tappable"
                      onClick={() => this.fontSelected(id, font)}
                    >
                      <div className="display">
                        <div className="bubble">
                          <img src={FontImage} alt="Font" />
                        </div>
                      </div>
                      <div className="title">{this.cleanUpName(font)}</div>
                    </div>
                  </div>
                );
              })}
              <div
                className="field tappable"
                onClick={() => this.toggleNewFont()}
              >
                <div className="display">
                  <div className="bubble add">
                    <img src={AddImage} alt="Add font" />
                  </div>
                </div>
                <div className="title">&nbsp;</div>
              </div>
            </div>
            {isNew ? (
              <FontEditor
                saveFont={this.saveFont}
                cancel={this.resetSelection}
                readOnly={this.props.readOnly}
              />
            ) : (
              selected.id !== -1 && (
                <FontEditor
                  key={selected.id}
                  font={selected.font}
                  id={selected.id}
                  removeFont={this.removeFont}
                  saveFont={this.saveFont}
                  cancel={this.resetSelection}
                  readOnly={this.props.readOnly}
                />
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FontsList;
