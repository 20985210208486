import React from 'react';
import { components } from 'react-select';
import caretDownIcon from '../../../../../assets/images/down-chevron.svg';
import caretUpIcon from '../../../../../assets/images/up-chevron.svg';

const { DropdownIndicator } = components;

const CaretDownIcon = () => {
  return <img src={caretDownIcon} alt="caret down icon" />;
};

const CaretUpIcon = () => {
  return <img src={caretUpIcon} alt="caret up icon" />;
};

export const CustomDropdownIndicator = (props: any) => {
  const {
    selectProps: { menuIsOpen },
  } = props;
  return (
    <DropdownIndicator {...props}>
      {menuIsOpen ? <CaretUpIcon /> : <CaretDownIcon />}
    </DropdownIndicator>
  );
};
