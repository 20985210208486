import React from 'react';
import { BaseLayoutProps } from '../../../../../types/common';
import ComponentDetailsField from '../../ComponentDetailsField';
import { FieldsBuilder } from '../FieldsBuilder';

export const ActionLayout = ({
  component,
  definition,
  configuration,
  fields,
  updateComponentField,
  getComponentField,
  messages,
  canPerformActions,
}: BaseLayoutProps) => (
  <>
    <div className="action-fields fields">
      <FieldsBuilder
        component={component}
        definition={definition}
        configuration={configuration}
        fields={fields}
        updateComponentField={updateComponentField}
        getComponentField={getComponentField}
        messages={messages}
        canPerformActions={canPerformActions}
      />
    </div>
    <div className="footer">
      <ComponentDetailsField
        key={`component-cdf`}
        fieldKey={'component'}
        value={component['component']}
        definition={definition['component']}
        configuration={configuration}
        updateValue={updateComponentField}
        getValue={getComponentField}
        messages={messages}
        canPerformActions={canPerformActions}
      />
    </div>
  </>
);
