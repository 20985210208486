import { createContext, useState } from 'react';
import { Template } from '../types/common';
import { usePrevious } from '../hooks/usePreviousValue';

const defaultEditorState = {
  template: null,
  selectedComponentId: null,
  previousSelectedComponentId: null,
  onHoverComponentId: null,
  previousOnHoverComponentId: null,
};

const defaultEditorUpdaters = {
  setTemplate: null,
  setSelectedComponentId: null,
  setOnHoverComponentId: null,
  resetPreviousSelectedComponentId: null,
};

interface EditorContextState {
  template: Template | null;
  selectedComponentId: string | null;
  previousSelectedComponentId: string | null;
  onHoverComponentId: string | null;
  previousOnHoverComponentId: string | null;
}

interface EditorContextUpdater {
  setTemplate: React.Dispatch<React.SetStateAction<Template | null>> | null;
  setSelectedComponentId: React.Dispatch<
    React.SetStateAction<string | null>
  > | null;
  setOnHoverComponentId: React.Dispatch<
    React.SetStateAction<string | null>
  > | null;
  resetPreviousSelectedComponentId: React.Dispatch<
    React.SetStateAction<string | null>
  > | null;
}

export const EditorContextState =
  createContext<EditorContextState>(defaultEditorState);
export const EditorContextUpdater = createContext<EditorContextUpdater>(
  defaultEditorUpdaters,
);

// Context provider
export const EditorContextProvider = ({ children }: any) => {
  const [template, setTemplate] = useState<Template | null>(
    defaultEditorState.template,
  );
  const [selectedComponentId, setSelectedComponentId] = useState<string | null>(
    defaultEditorState.selectedComponentId,
  );
  const [onHoverComponentId, setOnHoverComponentId] = useState<string | null>(
    defaultEditorState.onHoverComponentId,
  );
  const previousSelectedComponentId = usePrevious(selectedComponentId);
  const previousOnHoverComponentId = usePrevious(onHoverComponentId);

  const resetPreviousSelectedComponentId = () => {
    setSelectedComponentId(null);
  };

  return (
    <EditorContextState.Provider
      value={{
        template,
        selectedComponentId,
        previousSelectedComponentId,
        onHoverComponentId,
        previousOnHoverComponentId,
      }}
    >
      <EditorContextUpdater.Provider
        value={{
          setTemplate,
          setSelectedComponentId,
          setOnHoverComponentId,
          resetPreviousSelectedComponentId,
        }}
      >
        {children}
      </EditorContextUpdater.Provider>
    </EditorContextState.Provider>
  );
};
