import React, { Component } from 'react';

export default class PaddingField extends Component {
  onChange = (e) => {
    const { index, onPaddingChange } = this.props;
    onPaddingChange(e.target.value, index);
  };

  render() {
    const { value, configuration, index } = this.props;
    var ltrbValue = '';
    if (value && index !== undefined) {
      ltrbValue = value[index];
    } else if (value) {
      ltrbValue = value;
    }
    return (
      <select
        className="padding-field"
        value={ltrbValue}
        onChange={this.onChange}
      >
        <option value="">None</option>
        {configuration.style.padding.map((style, i) => {
          return (
            <option key={i} value={style.name}>
              {style.name}
            </option>
          );
        })}
      </select>
    );
  }
}
