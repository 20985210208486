import React from 'react';
import { useDroppable } from '@dnd-kit/core';

export function Droppable(props) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });
  const style = isOver ? 'over' : 'not-over';

  return (
    <div ref={setNodeRef} className={style}>
      {props.children}
    </div>
  );
}
