import React from 'react';
import { components } from 'react-select';
import closeIcon from '../../../../../assets/images/close.svg';

const { ClearIndicator } = components;

const CloseIcon = () => {
  return <img src={closeIcon} alt="close" />;
};

export const CustomClearIndicator = (props: any) => {
  return (
    <ClearIndicator {...props}>
      <CloseIcon />
    </ClearIndicator>
  );
};
