import { getFlyUserId } from './fly-user-service';
import { setSentryUser } from './sentry';
import { settings } from './settings-service';
import mixpanel from 'mixpanel-browser';

function humanize(str: string) {
  let i = 0;
  const frags = str.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] =
      frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase();
  }
  return frags.join(' ');
}

interface ColorEvent {
  Type: 'Color';
  Name?: string;
  Color?: string; // Hex color code, e.g. "#ff0000"
}

interface FontEvent {
  Type: 'Font';
  Name?: string;
  'Font File Path'?: string; // Font file name
}

interface TextStyleEvent {
  Type: 'Text Style';
  Font?: string;
  'Font Size'?: number;
  'Line Height'?: number; // Possible text styles
}

interface PaddingEvent {
  Type: 'Padding';
  Name?: string;
  Padding?: number; // Padding [top, right, bottom, left] in pixels
}

type BrandingEvent = ColorEvent | FontEvent | TextStyleEvent | PaddingEvent;

// TODO: Uncomment and probably share this type
// type GistComponent =
//   | 'Block'
//   | 'Action'
//   | 'Text'
//   | 'Image'
//   | 'Markdown'
//   | 'Vertical list'
//   | 'Horizontal list'
//   | 'Grid'
//   | 'Carousel'
//   | 'Icon'
//   | 'Conditional';

interface MessageEvent {
  'Template Type'?: string;
  Name?: string;
  'Message ID'?: string;
  'View Type'?: 'Designer' | 'Code';
  // TODO: Use GistComponent instead and have a function to convert values
  'Component Type'?: string;
  'Wrap Component Type'?: 'Block' | 'Action';
  'Component Property'?: string;
  'Order Direction'?: 'Move up' | 'Move down';
  'Visibility Type'?:
    | 'Hide'
    | 'Show'
    | 'Parent'
    | 'Show Components'
    | 'Expand Children';
}

type PanelEvent = {
  Visible?: boolean;
};

type LayerEvent = {
  'Component Type'?: string;
  'Parent Component Type'?: string;
};

type Event = MessageEvent | BrandingEvent | LayerEvent | PanelEvent;

type EventName =
  | 'MESSAGE_CREATION_STARTED'
  | 'MESSAGE_CREATION_TEMPLATE_SELECTED'
  | 'MESSAGE_CREATION_BRANDING_MAPPED'
  | 'MESSAGE_CREATION_COMPLETED'
  | 'MESSAGE_VIEWED'
  | 'MESSAGE_DELETED'
  | 'MESSAGE_REVERTED'
  | 'MESSAGE_RENAMED'
  | 'MESSAGE_SAVED'
  | 'MESSAGE_TOGGLED_VIEW'
  | 'MESSAGE_COMPONENT_EDITED'
  | 'MESSAGE_COMPONENT_ADDED'
  | 'MESSAGE_COMPONENT_DELETED'
  | 'MESSAGE_COMPONENT_CLONED'
  | 'MESSAGE_COMPONENT_WRAPPED_WITHIN'
  | 'MESSAGE_COMPONENT_ORDER_CHANGED'
  | 'MESSAGE_COMPONENT_VISIBILITY_CHANGED'
  | 'BRANDING_CREATION_STARTED'
  | 'BRANDING_CREATION_COMPLETED'
  | 'BRANDING_EDIT_STARTED'
  | 'BRANDING_EDIT_COMPLETED'
  | 'BRANDING_DELETED'
  | 'MESSAGE_PREVIEW_SENT'
  | 'BROADCAST_CREATED'
  | 'BROADCAST_PUBLISHED'
  | 'SERVICE_KEY_CREATED'
  | 'SERVICE_KEY_REVOKED'
  | 'SUBSCRIPTION_CREATED'
  | 'SUBSCRIPTION_CANCELLED'
  | 'USER_INVITE_SENT'
  | 'DATASOURCE_CREATED'
  | 'ONBOARDING_COMPLETE'
  | 'LAYERS_PANEL_TOGGLED'
  | 'PREVIEW_PANEL_TOGGLED'
  | 'COMPONENT_TREE_EXPANDED'
  | 'COMPONENT_TREE_COLLAPSED'
  | 'MOVE_COMPONENT_STARTED'
  | 'MOVE_COMPONENT_COMPLETED';

type EventsMapType = Required<{ [key in EventName]: string }>;

export const EventsMap: EventsMapType = {
  // Message events
  MESSAGE_CREATION_STARTED: 'message-creation-started',
  MESSAGE_CREATION_TEMPLATE_SELECTED: 'message-creation-template-selected',
  MESSAGE_CREATION_BRANDING_MAPPED: 'message-creation-branding-mapped',
  MESSAGE_CREATION_COMPLETED: 'message-creation-completed',
  MESSAGE_VIEWED: 'message-viewed',
  MESSAGE_DELETED: 'message-deleted',
  MESSAGE_REVERTED: 'message-revert',
  MESSAGE_RENAMED: 'message-renamed',
  MESSAGE_SAVED: 'message-saved',
  MESSAGE_TOGGLED_VIEW: 'message-toggled-view',

  // Component events
  MESSAGE_COMPONENT_EDITED: 'message-component-edited',
  MESSAGE_COMPONENT_ADDED: 'message-component-added',
  MESSAGE_COMPONENT_DELETED: 'message-component-deleted',
  MESSAGE_COMPONENT_CLONED: 'message-component-cloned',
  MESSAGE_COMPONENT_WRAPPED_WITHIN: 'message-component-wrapped-within',
  MESSAGE_COMPONENT_ORDER_CHANGED: 'message-component-order-changed',
  MESSAGE_COMPONENT_VISIBILITY_CHANGED: 'message-component-visibility-changed',

  // Branding events
  BRANDING_CREATION_STARTED: 'branding-creation-started',
  BRANDING_CREATION_COMPLETED: 'branding-creation-completed',
  BRANDING_EDIT_STARTED: 'branding-edition-started',
  BRANDING_EDIT_COMPLETED: 'branding-edition-completed',
  BRANDING_DELETED: 'branding-deleted',
  // BRANDING_UPDATED: 'branding-updated',
  // Other events existing events
  // MESSAGE_CREATED: 'message-created',
  MESSAGE_PREVIEW_SENT: 'message-preview-sent',
  BROADCAST_CREATED: 'broadcast-created',
  BROADCAST_PUBLISHED: 'broadcast-pubished',
  SERVICE_KEY_CREATED: 'service-key-created',
  SERVICE_KEY_REVOKED: 'service-key-revoked',
  SUBSCRIPTION_CREATED: 'subscription-created',
  SUBSCRIPTION_CANCELLED: 'subscription-cancelled',
  USER_INVITE_SENT: 'user-invite-sent',
  DATASOURCE_CREATED: 'datasource-created',
  ONBOARDING_COMPLETE: 'onboarding-complete',

  // Panel events
  LAYERS_PANEL_TOGGLED: 'layers-panel-toggled',
  PREVIEW_PANEL_TOGGLED: 'preview-panel-toggled',

  // Layers events
  COMPONENT_TREE_EXPANDED: 'component-tree-expanded',
  COMPONENT_TREE_COLLAPSED: 'component-tree-collapsed',
  MOVE_COMPONENT_STARTED: 'move-component-started',
  MOVE_COMPONENT_COMPLETED: 'move-component-completed',
};

export const ANON_ID_KEY = 'cio:anonymous-id';

export function setupReporting() {
  setupMixpanel();
}

export function identifyUser() {
  const loggedUserId = getFlyUserId();
  mixpanel.identify(loggedUserId ? loggedUserId : ANON_ID_KEY);
  setSentryUser(loggedUserId ? loggedUserId : ANON_ID_KEY);
}

export const trackEvent = (eventName: keyof EventsMapType, data?: Event) => {
  if (data === null || data === undefined) {
    mixpanel.track(`In-App: ${humanize(eventName as string)}`);
  } else {
    mixpanel.track(`In-App: ${humanize(eventName as string)}`, data);
  }
};

const setupMixpanel = () => {
  mixpanel.init(
    settings.MIXPANEL_TOKEN,
    settings.GIST_WEB_ENV !== 'prod' ? { debug: true } : undefined,
  );
};
