import { AuthenticatedNetworkInstance } from './base-network';

export async function getConfiguration() {
  try {
    var response = await AuthenticatedNetworkInstance().get(`/api/v1/brand`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function updateColors(colors) {
  try {
    var response = await AuthenticatedNetworkInstance().put(
      `/api/v1/brand/style/colors`,
      colors,
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function updatePadding(padding) {
  try {
    var response = await AuthenticatedNetworkInstance().put(
      `/api/v1/brand/style/padding`,
      padding,
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function updateFonts(fonts) {
  try {
    var response = await AuthenticatedNetworkInstance().put(
      `/api/v1/brand/assets/fonts`,
      fonts,
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function updateTextStyles(textStyles) {
  try {
    var response = await AuthenticatedNetworkInstance().put(
      `/api/v1/brand/style/fonts`,
      textStyles,
    );
    return response;
  } catch (error) {
    return error.response;
  }
}
