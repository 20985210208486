import { notifyParentOfError } from '../services/embed-service';
import { EventsMap, trackEvent } from '../services/reporting-service';
import { showErrorResponseNotification } from '../views/common/notifications';

export const processCreateMessageResponse = (
  response: any,
  messageData: any,
  redirectTo: string,
) => {
  if (response.status === 200) {
    trackEvent('MESSAGE_CREATION_COMPLETED', { Name: messageData.name });
    window.location.pathname = redirectTo;
  } else {
    if (response.status === 409) {
      showErrorResponseNotification(
        'Message name already exist, please update and try again',
        'There was a problem creating the message',
        response,
      );
      notifyParentOfError(
        'Message name already exist, please update and try again',
        response,
      );
    } else {
      showErrorResponseNotification(
        'Error creating message',
        'There was a problem creating the message',
        response,
      );
      notifyParentOfError('There was a problem creating the message', response);
    }
  }
};
