import { createDetailedErrorMessage } from '../views/common/notifications';

export function notifyParentFinishLoading() {
  notifyIframeParent('completedLoading', {});
}

export function notifyParentThatUserLoggedIn() {
  notifyIframeParent('userLoggedIn', {});
}

export function notifyParentThatUserLoggedOut() {
  notifyIframeParent('userLoggedOut', {});
}

export function notifyParentThatLocationChanged(location: string) {
  notifyIframeParent('locationChanged', { location });
}

export function notifyParentToShowDeleteMessageModal(message: any) {
  notifyIframeParent('showDeleteMessageModal', { message });
}

export function notifyParentToShowAssetLibraryModal(inputId: string) {
  notifyIframeParent('showAssetLibraryModal', {
    assetInputId: inputId,
  });
}

export function notififyParentOfNewSize() {
  const body = document.body;
  const html = document.documentElement;
  const height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  );
  notifyIframeParent('frameResized', { width: 0, height: height });
}

export const showSuccess = (title: string) => {
  notifyParentOfSuccess(title);
};

export const showError = (title: string, message: string, response: any) => {
  notifyParentOfError(`${title}: \n${message}`, response);
};

export function notifyParentOfError(message: any, response: any) {
  const detailedMessage = createDetailedErrorMessage(message, response);
  notifyParentOfMessage('error', detailedMessage);
}

export function notifyParentOfSuccess(message: any) {
  notifyParentOfMessage('success', message);
}

function notifyParentOfMessage(type: string, message: any) {
  notifyIframeParent('notification', { type, message });
}

function notifyIframeParent(method: string, payload: any) {
  const event = {
    gistApp: {
      method: method,
      payload: payload,
    },
  };
  const target = window.parent ?? { postMessage: () => {} };
  target.postMessage(event, '*');
}
