import React, { Component } from 'react';

export default class ImageFitField extends Component {
  render() {
    const { value, onChange } = this.props;
    return (
      <select value={value} onChange={onChange}>
        <option value="none">None</option>
        <option value="cover">Cover</option>
        <option value="contain">Contain</option>
        <option value="scaleDown">Scale Down</option>
        <option value="fill">Fill</option>
        <option value="fitWidth">Fit Width</option>
        <option value="fitHeight">Fit Height</option>
      </select>
    );
  }
}
