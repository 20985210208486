export const loadRemoteFont = async (
  familyName: string,
  fontFileUrl: string,
) => {
  const font = new FontFace(familyName, `url(${fontFileUrl})`);
  font
    .load()
    .then((loadedFontFace: FontFace) => {
      // loadedFontFace holds the loaded FontFace
      // console.log("Loaded face:", familyName, loadedFontFace);
      document.fonts.add(loadedFontFace);
    })
    .catch((error: any) => {
      // An error loading the font occurred
      console.log('Error loading font face: ', familyName, error);
    });
};

export const getFontFilename = (fontFilePath: any) =>
  fontFilePath.split('/').pop().replace('.ttf', '');

export const loadBrandFonts = async (fonts: string[]) =>
  fonts.forEach(async (fontFileUrl: string) => {
    loadRemoteFont(getFontFilename(fontFileUrl), fontFileUrl);
  });
