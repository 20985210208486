import { isEmpty } from 'lodash';
import { Template, Widget } from '../types/common';

export const findComponentById = (components: Template, id: string): any => {
  if (components) {
    for (const component of components) {
      if (component) {
        if (component.gist?.id === id) {
          return component;
        }
        if (component.components) {
          const foundComponent = findComponentById(component.components, id);
          if (foundComponent) {
            return foundComponent;
          }
        }
        if (component.type === 'conditionalWidget') {
          if (component.true) {
            const foundInTrue = findComponentById([component.true], id);
            if (foundInTrue) {
              return foundInTrue;
            }
          }
          if (component.false) {
            const foundInFalse = findComponentById([component.false], id);
            if (foundInFalse) {
              return foundInFalse;
            }
          }
        }
        if (component.component) {
          const foundInComponent = findComponentById([component.component], id);
          if (foundInComponent) {
            return foundInComponent;
          }
        }
      }
    }
  }
  return null;
};

export const findAndRemoveComponentById = (
  components: Template,
  nodeId: string,
  context: 'array' | 'actionWidget' | 'conditionalWidget' = 'array',
): [Template, Widget] => {
  if (components) {
    for (let i = 0; i < components.length; i++) {
      // Base case modification
      if (components[i]?.gist?.id === nodeId) {
        const removed = components[i];
        if (context === 'array') {
          components.splice(i, 1);
        } else if (context === 'actionWidget') {
          components[i] = null;
        } else if (context === 'conditionalWidget') {
          return [null, removed]; // Return null indicating the entire component should be set to null in parent
        }
        return [components, removed];
      }

      // Check conditional widgets
      if (components[i] && components[i]?.type === 'conditionalWidget') {
        if (components[i]?.true) {
          const [updatedTrue, removedFromTrue] = findAndRemoveComponentById(
            [components[i]!.true],
            nodeId,
            'conditionalWidget',
          );
          if (removedFromTrue) {
            components[i]!.true = updatedTrue ? updatedTrue[0] : null;
            return [components, removedFromTrue];
          }
        }

        if (components[i]?.false) {
          const [updatedFalse, removedFromFalse] = findAndRemoveComponentById(
            [components[i]!.false],
            nodeId,
            'conditionalWidget',
          );
          if (removedFromFalse) {
            components[i]!.false = updatedFalse ? updatedFalse[0] : null;
            return [components, removedFromFalse];
          }
        }
      }

      // Check action widgets
      if (components[i] && components[i]?.type === 'actionWidget') {
        const [updatedComponent, removed] = findAndRemoveComponentById(
          [components[i]?.component],
          nodeId,
          'actionWidget',
        );
        if (removed) {
          if (updatedComponent && updatedComponent[0]) {
            // Update the component property with the returned updated template
            components[i]!.component = updatedComponent[0];
          } else {
            // Direct removal from the actionWidget's component property
            components[i]!.component = null;
          }
          return [components, removed];
        }
      }

      // Check child components
      if (components[i] && components[i]?.components) {
        const [updatedComponents, removed] = findAndRemoveComponentById(
          components[i]!.components,
          nodeId,
          'array',
        );
        if (removed) {
          components[i]!.components = updatedComponents; // Update the parent's child components
          return [components, removed];
        }
      }
    }
  }

  // Node not found
  return [components, null];
};

export const insertNodeAtTarget = (
  node: any,
  dropTargetId: string | number,
  components: Template,
  relativeIndex?: number,
): Template => {
  if (components) {
    // Insert at the root level if dropTargetId is '0'
    if (dropTargetId === 0) {
      if (relativeIndex != undefined) {
        components.splice(relativeIndex, 0, node);
      }
      return components;
    }

    const targetNode = findComponentById(components, dropTargetId as string);

    if (!targetNode) {
      console.warn('Target node not found.');
      return components; // If target node isn't found, return the original template
    }

    // Insert the new node based on the type of target node
    if (targetNode.type === 'actionWidget' && relativeIndex === 0) {
      if (isEmpty(targetNode.component)) {
        targetNode.component = node;
      }
      return components;
    } else if (targetNode.type === 'conditionalWidget') {
      if (relativeIndex === 0 || !targetNode.true) {
        targetNode.true = node;
      } else if (relativeIndex === 1 || !targetNode.false) {
        targetNode.false = node;
      }
      return components;
    } else {
      if (targetNode.components != null) {
        if (relativeIndex != undefined) {
          targetNode.components.splice(relativeIndex, 0, node);
        }
      }
    }
  }
  return components;
};
