import { memo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DropOptions, NodeModel, Tree } from '@minoru/react-dnd-treeview';
import { CustomData, LayersProps } from './types';
import '../../../assets/layers.scss';
import { renderNodes } from './Node';
import { Placeholder } from './Placeholder';
import { EmptyPlaceholder } from './EmptyPlaceholder';
import { canDrag, canDrop } from '../../../utilities/dnd';
import { useEditorContextState } from '../../../context/useEditorContext';
import { DragPreview } from './DragPreview';
import withScrolling from 'react-dnd-scrolling';
import { isEqual } from 'lodash';
import { trackEvent } from '../../../services/reporting-service';
import {
  getExtraMetadataForDefinitionType,
  WidgetOption,
} from '../definitions/definition-manager';

const ScrollingComponent = withScrolling('div');
const getComponentType = (meta: WidgetOption | null) =>
  meta?.name || 'Unknown Component Type';

const LayersComponent = memo(
  ({ treeData, openNodes, setOpenNodes, onDropHandler }: LayersProps) => {
    const [hover, setHover] = useState(false);
    const { template } = useEditorContextState();

    const handleOnMouseEnterNode = () => {
      setHover(true);
    };

    const handleOnMouseLeaveNode = () => {
      setHover(false);
    };

    const onChangeOpenHandler = (newOpenIds: NodeModel['id'][]) => {
      setOpenNodes(newOpenIds);
    };

    // To review if we want to keep it closed after drop
    const onDragStart = (node: NodeModel<CustomData>) => {
      trackMoveComponentStarted(node);
      setOpenNodes(openNodes.filter((id: string | number) => id !== node.id));
    };

    const onDrop = (
      tree: NodeModel<CustomData>[],
      opts: DropOptions<CustomData>,
    ) => {
      trackMoveComponentCompleted(opts);
      onDropHandler(tree, opts);
    };

    const trackMoveComponentStarted = (node: NodeModel<CustomData>) => {
      const { parent: parentNodeId } = node;
      const parentNode = treeData.find((node) => node.id === parentNodeId);
      const parentComponentType = parentNode?.data?.componentType;

      const componentMeta = getExtraMetadataForDefinitionType(
        node.data?.componentType ?? '',
      );
      const parentComponentMeta = getExtraMetadataForDefinitionType(
        parentComponentType ?? '',
      );

      trackEvent('MOVE_COMPONENT_STARTED', {
        'Component Type': getComponentType(componentMeta),
        ...(parentNodeId !== 0
          ? { 'Parent Component Type': getComponentType(parentComponentMeta) }
          : {}),
      });
    };

    const trackMoveComponentCompleted = (opts: DropOptions<CustomData>) => {
      const componentMeta = getExtraMetadataForDefinitionType(
        opts.dragSource?.data?.componentType ?? '',
      );
      const parentComponentMeta = getExtraMetadataForDefinitionType(
        opts.dropTarget?.data?.componentType ?? '',
      );
      const parentNodeId = opts.dropTargetId;
      trackEvent('MOVE_COMPONENT_COMPLETED', {
        'Component Type': getComponentType(componentMeta),
        ...(parentNodeId !== 0
          ? { 'Parent Component Type': getComponentType(parentComponentMeta) }
          : {}),
      });
    };

    if (treeData.length === 0) {
      return <EmptyPlaceholder />;
    }

    return (
      <div
        className="flex-1"
        onMouseEnter={handleOnMouseEnterNode}
        onMouseLeave={handleOnMouseLeaveNode}
      >
        <DndProvider backend={HTML5Backend}>
          <ScrollingComponent>
            <Tree
              rootId={0}
              tree={treeData}
              render={renderNodes(hover)}
              onDrop={onDrop}
              classes={{
                root: 'tree-root',
                container: 'list-container',
                listItem: 'list-item',
                draggingSource: 'dragging-source',
                placeholder: 'placeholder-container',
                dropTarget: 'drop-target',
              }}
              sort={false}
              insertDroppableFirst={false}
              initialOpen={openNodes}
              onDragStart={onDragStart}
              onChangeOpen={onChangeOpenHandler}
              canDrop={(tree, options) => canDrop(tree, options, template)}
              canDrag={(node) => canDrag(node, template)}
              dropTargetOffset={5}
              placeholderRender={(node, { depth }) => (
                <Placeholder node={node} depth={depth} />
              )}
              dragPreviewRender={(monitorProps) => (
                <DragPreview monitorProps={monitorProps} />
              )}
            />
          </ScrollingComponent>
        </DndProvider>
      </div>
    );
  },
  (prevProps, nextProps) =>
    isEqual(prevProps.treeData, nextProps.treeData) &&
    isEqual(prevProps.openNodes, nextProps.openNodes),
);

LayersComponent.displayName = 'Layers';

export const Layers = LayersComponent;
