import { ReactNode } from 'react';
import { GRAY_500, GRAY_900 } from '../../../../../utilities/colors';

const styles = {
  labelsContainer: {
    flexDirection: 'column' as const,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  previewLabel: {
    display: 'block',
    color: GRAY_900,
    fontWeight: 600,
    marginBottom: '2px',
    fontSize: '14px',
  },
  previewDescription: {
    display: 'block',
    color: GRAY_500,
    fontWeight: 400,
    fontSize: '12px',
    marginBottom: '4px',
  },
  previewContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    backgroundColor: 'white',
    padding: '16px',
  },
};

export const TooltipPreview = ({
  Icon,
  label,
  description,
}: {
  label: string;
  description: string;
  Icon: ReactNode;
}) => {
  return (
    <div style={styles.previewContainer}>
      <div style={styles.labelsContainer}>
        <span
          className="option-label"
          style={{
            ...styles.previewLabel,
          }}
        >
          {label}
        </span>
        {description && (
          <span style={styles.previewDescription}>{description}</span>
        )}
      </div>
      <div style={styles.iconContainer}>{Icon}</div>
    </div>
  );
};
