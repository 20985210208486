import React, { Component } from 'react';

class TextStyleEditor extends Component {
  constructor(props) {
    super(props);
    const { textStyle } = this.props;
    if (textStyle) {
      this.state = {
        name: textStyle.name,
        font: textStyle.font,
        size: textStyle.size,
        lineHeight: textStyle.lineHeight,
      };
    } else {
      this.state = { name: '', font: '', size: 12, lineHeight: 1.0 };
    }
  }

  static getDerivedStateFromProps(props, state) {
    var updatedState = state;
    updatedState.fonts = props.fonts;
    return updatedState;
  }

  removeTextStyle = () => {
    this.props.removeTextStyle(this.props.id);
  };

  saveTextStyle = (e) => {
    const { name, font, size, lineHeight } = this.state;
    this.props.saveTextStyle(
      { name: name, font: font, size: size, lineHeight: lineHeight },
      this.props.id,
    );
    e.preventDefault();
  };

  render() {
    const { id } = this.props;
    const { name, font, size, lineHeight, fonts } = this.state;

    return (
      <form className="inline-form padded" onSubmit={this.saveTextStyle}>
        <div className="field">
          <div className="input-field-title">Name</div>
          <div className="input-field">
            <input
              type="text"
              value={name}
              onChange={(e) => this.setState({ name: e.target.value })}
              required
            />
          </div>
        </div>
        <div className="field">
          <div className="input-field-title">Font</div>
          <div className="input-field">
            <span className="select-drop">
              <select
                value={font}
                onChange={(e) => this.setState({ font: e.target.value })}
                required
              >
                <option value=""></option>
                {fonts.map((font, index) => (
                  <option key={index} value={font}>
                    {font}
                  </option>
                ))}
              </select>
            </span>
          </div>
        </div>
        <div className="field">
          <div className="input-field-title">Size</div>
          <div className="input-field">
            <input
              type="number"
              value={size}
              onChange={(e) => this.setState({ size: e.target.value })}
              step="1"
              required
            />
          </div>
        </div>
        <div className="field">
          <div className="input-field-title">Line Height</div>
          <div className="input-field">
            <input
              type="number"
              value={lineHeight}
              onChange={(e) => this.setState({ lineHeight: e.target.value })}
              step="0.1"
              required
            />
          </div>
        </div>
        <div className="field last">
          <div className="input-field-title">&nbsp;</div>
          <div className="input-field button-field">
            <button
              className={`button primary ${
                this.props.readOnly ? 'disabled' : ''
              }`}
              disabled={this.props.readOnly}
              type="submit"
            >
              Save
            </button>
            <button className="button" onClick={this.props.cancel}>
              Cancel
            </button>
            {id != null && (
              <button
                className={`button delete ${
                  this.props.readOnly ? 'disabled' : ''
                }`}
                disabled={this.props.readOnly}
                onClick={this.removeTextStyle}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default TextStyleEditor;
