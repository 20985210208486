import axios from 'axios';
import { getTokenOrRedirect } from './token-service';
import { settings } from './settings-service';
import { getFlyWorkspaceId } from './fly-user-service';

export function AuthenticatedNetworkInstance() {
  const token = getTokenOrRedirect();
  const wid = getFlyWorkspaceId();

  return axios.create({
    baseURL: settings.GIST_API_ENDPOINT,
    timeout: 40000,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-CIO-Workspace-Id': wid,
    },
  });
}

export function UnAuthenticatedNetworkInstance() {
  return axios.create({
    baseURL: settings.GIST_API_ENDPOINT,
    timeout: 40000,
  });
}
