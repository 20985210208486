// @ts-nocheck
import React, { Component } from 'react';
import AddComponentField from './AddComponentField';

export default class AddMultipleComponentField extends Component {
  constructor(props) {
    super(props);
    this.state = { component: {} };
  }

  onComponentChange = (component) => {
    let components = this.props.value;
    if (components && components.length > 0) {
      components.push(component);
    } else {
      components = [component];
    }
    this.props.onChange(components);
  };

  render() {
    return (
      <AddComponentField
        clearOnAdd={true}
        onChange={this.onComponentChange}
        canPerformAction={this.props.canPerformActions}
      />
    );
  }
}
