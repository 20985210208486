import Tippy from '@tippyjs/react';

import { ReactComponent as DangerIcon } from '../../../assets/images/danger.svg';
import { RED_900 } from '../../../utilities/colors';

export const ErrorTooltip = ({ errorMessage }: { errorMessage: string }) => {
  return (
    <div className="error-tooltip-container">
      <Tippy
        content={<span> {errorMessage}</span>}
        placement="bottom"
        animation="scale-subtle"
        theme="light"
        className="tippy-preview"
      >
        <DangerIcon fill={RED_900} />
      </Tippy>
    </div>
  );
};
