function extractColors(template) {
  var allItems = extractItems(template, [
    'backgroundColor',
    'color',
    'borderColor',
  ]);
  return Array.from(new Set(allItems));
}

function extractPadding(template) {
  var allItems = extractItems(template, ['padding']);
  return Array.from(new Set(allItems));
}

function extractTextStyles(template) {
  var allItems = extractItems(template, ['style']);
  return Array.from(new Set(allItems));
}

function extractItems(template, items, foundItems) {
  foundItems = foundItems || [];
  template.forEach((component) => {
    Object.keys(component).forEach((key) => {
      if (items.includes(key) && component[key] !== '') {
        if (Array.isArray(component[key])) {
          component[key].forEach((innerComponent) => {
            if (innerComponent !== '') {
              foundItems.push(innerComponent);
            }
          });
        } else {
          foundItems.push(component[key]);
        }
      }
      if (key === 'components') {
        foundItems.concat(extractItems(component[key], items, foundItems));
      } else if (
        key === 'component' ||
        key === 'true' ||
        key === 'false' ||
        key === 'template'
      ) {
        foundItems.concat(extractItems([component[key]], items, foundItems));
      }
    });
  });
  return foundItems;
}

export function isTemplateCompatible(template, configuration) {
  var templateStyles = extractTemplateStyles(template);
  var valid = true;

  Object.keys(templateStyles.colors).forEach((color) => {
    if (!configuration.style.colors.some((item) => item.name === color)) {
      valid = false;
    }
  });

  Object.keys(templateStyles.text).forEach((textStyle) => {
    if (!configuration.style.fonts.some((item) => item.name === textStyle)) {
      valid = false;
    }
  });

  Object.keys(templateStyles.padding).forEach((padding) => {
    if (!configuration.style.padding.some((item) => item.name === padding)) {
      valid = false;
    }
  });

  return valid;
}

export function extractTemplateStyles(template) {
  var colors = extractColors(template);
  var textStyles = extractTextStyles(template);
  var padding = extractPadding(template);
  var styles = { colors: {}, text: {}, padding: {} };
  colors.forEach((color) => {
    styles.colors[color] = '';
  });
  textStyles.forEach((textStyle) => {
    styles.text[textStyle] = '';
  });
  padding.forEach((pad) => {
    styles.padding[pad] = '';
  });
  return styles;
}

export function replaceStyle(strTemplate, changes) {
  Object.keys(changes).forEach((key) => {
    Object.keys(changes[key]).forEach((style) => {
      if (changes[key][style] !== '') {
        const replace = `"${style}"`;
        const replaceWith = `"${changes[key][style]}"`;
        strTemplate = strTemplate.replace(
          new RegExp(replace, 'g'),
          replaceWith,
        );
      }
    });
  });
  return strTemplate;
}
