import { Component } from 'react';
import LoggedOutLayout from './layouts/LoggedOutLayout';
import { notifyParentThatUserLoggedOut } from './services/embed-service';
import { checkForTokenAndLogin } from './utilities/TokenLogin';

class LoggedOut extends Component {
  componentDidMount() {
    notifyParentThatUserLoggedOut();
    checkForTokenAndLogin();
  }

  render() {
    return <LoggedOutLayout />;
  }
}

export default LoggedOut;
