import React, { Component } from 'react';
import { replaceStyle } from '../../../utilities/StyleUtilities';
import TemplateStyleColorField from './fields/TemplateStyleColorField';
import TemplateStyleTextField from './fields/TemplateStyleTextField';
import TemplateStylePaddingField from './fields/TemplateStylePaddingField';

class TemplateStyling extends Component {
  constructor(props) {
    super(props);
    this.state = { template: props.template };
  }

  // This function is used to escape control characters introduced by some customers in their branding styles.
  // We need to escape them to prevent JSON parsing errors.
  escapeControlCharacters(input) {
    // Mapping of specific ASCII control characters to JSON escape sequences
    const escapes = {
      '\b': '\\b', // Backspace
      '\f': '\\f', // Form feed
      '\n': '\\n', // New line
      '\r': '\\r', // Carriage return
      '\t': '\\t', // Tab
      '"': '\\"', // Double quote
      '\\': '\\\\', // Backslash
    };

    // Replace characters based on the escapes map, and use Unicode escapes for others
    return input.replace(/[\x00-\x1F\x7F]/g, (char) => {
      return (
        escapes[char] ||
        '\\u' + char.charCodeAt(0).toString(16).padStart(4, '0')
      );
    });
  }

  onStyleChange = (style, name, value) => {
    var safeValue = this.escapeControlCharacters(value);
    var templateChanges = this.props.templateChanges;
    templateChanges[style][name] = safeValue;
    var updatedTemplate = replaceStyle(this.state.template, templateChanges);
    this.props.onTemplateStyleChanged(updatedTemplate, templateChanges);
  };

  render() {
    const { templateChanges, configuration } = this.props;

    return (
      <div className="body padding">
        <TemplateStyleColorField
          colors={templateChanges.colors}
          configuration={configuration}
          onStyleChange={this.onStyleChange}
        />
        <TemplateStyleTextField
          textStyles={templateChanges.text}
          configuration={configuration}
          onStyleChange={this.onStyleChange}
        />
        <TemplateStylePaddingField
          padding={templateChanges.padding}
          configuration={configuration}
          onStyleChange={this.onStyleChange}
        />
      </div>
    );
  }
}

export default TemplateStyling;
