import React, { useCallback, useEffect, useState } from 'react';
import { Select } from '../components/Select';
import { SelectOption } from '../components/Select/types';

interface TextOverflowFieldProps {
  value: any;
  onChange: (value: any) => void;
}

export const TextOverflowField = ({
  value,
  onChange,
}: TextOverflowFieldProps) => {
  const [internalValue, setInternalValue] = useState<SelectOption | null>(null);

  const [options] = useState<SelectOption[]>([
    { value: 'ellipsis', label: 'Ellipsis' },
    { value: 'fade', label: 'Fade' },
    { value: 'clip', label: 'Clip' },
  ]);

  useEffect(() => {
    const option = options.find((option) => option.value === value);
    if (option) {
      setInternalValue(option);
      onChange({ target: { value: option ? option.value : '' } });
    }
  }, []);

  const onChangeWrapper = useCallback(
    (option: any) => {
      setInternalValue(option);
      onChange({ target: { value: option ? option.value : '' } });
    },
    [onChange],
  );

  return (
    <div className="select-container">
      <Select
        value={internalValue}
        isClearable={true}
        options={options}
        placeholder="Select overflow type..."
        onChange={onChangeWrapper}
      />
    </div>
  );
};
