import React, { useCallback, useState } from 'react';
import { Select } from '../components/Select';
import { SelectOption } from '../components/Select/types';

interface TextAlignFieldProps {
  value: any;
  onChange: (value: any) => void;
}

export const TextAlignField = ({ value, onChange }: TextAlignFieldProps) => {
  const [options] = useState<SelectOption[]>([
    { value: 'left', label: 'Left' },
    { value: 'center', label: 'Center' },
    { value: 'right', label: 'Right' },
    { value: 'start', label: 'Start' },
    { value: 'end', label: 'End' },
    { value: 'justify', label: 'Justify' },
  ]);

  const getDefaultValue = useCallback(
    (value: string) => {
      const option = options.find((option) => option.value === value);
      return option ? option : null;
    },
    [options],
  );

  const onChangeWrapper = useCallback(
    (option: any) => {
      onChange({ target: { value: option ? option.value : '' } });
    },
    [onChange],
  );

  return (
    <div className="select-container">
      <Select
        defaultValue={getDefaultValue(value)}
        isClearable={true}
        options={options}
        placeholder="Select text alignment..."
        onChange={onChangeWrapper}
      />
    </div>
  );
};
