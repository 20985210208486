import { useCallback, useEffect, useState } from 'react';
import ComponentTitle from './ComponentTitle';
import ComponentActionField from './ComponentActionField';
import { getDefinitionType } from '../definitions/definition-manager';
import { getMessages } from '../../../services/message-service';
import { ReactComponent as EditOutline } from '../../../assets/images/edit-outline.svg';
import { UpdateActions } from '../../../utilities/TemplateUtilities';
import { GRAY_500, PURPLE_700 } from '../../../utilities/colors';
import { ComponentLayout } from './ComponentLayout';
import arrowUpImage from '../../../assets/images/arrow-up.svg';
import arrowDownImage from '../../../assets/images/arrow-down.svg';
import { useEditorContextState } from '../../../context/useEditorContext';
import {
  highlightComponent,
  scrollToComponent,
  unhighlightComponent,
} from '../../../utilities/dom';

const componentFields = (definition: any) => {
  const fields: any[] = [];
  if (definition) {
    Object.keys(definition).forEach((key: string) => {
      if (key !== 'type') {
        fields.push(key);
      }
    });
  }
  return fields;
};

export const ComponentDetailsEditor = ({
  configuration,
  component,
  minimize,
  minimized,
  canOrder,
  canClone,
  canPerformActions,
  getComponentField,
  updateComponentField,
  updateComponent,
  deleteComponent,
}: any) => {
  const [type] = useState(component.type);
  const [definition] = useState(getDefinitionType(type));
  const [fields] = useState(componentFields(definition));
  const [messages, setMessages] = useState([]);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [editButtonFocused, setEditButtonFocused] = useState(false);
  const {
    selectedComponentId,
    previousSelectedComponentId,
    onHoverComponentId,
    previousOnHoverComponentId,
  } = useEditorContextState();

  useEffect(() => {
    // Avoid unnecesary calculations when the item is already selected
    if (selectedComponentId === previousSelectedComponentId) {
      return;
    }
    scrollToComponent(selectedComponentId);
    unhighlightComponent(previousSelectedComponentId);
    highlightComponent(selectedComponentId);
  }, [selectedComponentId]);

  useEffect(() => {
    // Avoid unnecesary hover when the item is already hovered
    if (onHoverComponentId === previousOnHoverComponentId) {
      return;
    }
    unhighlightComponent(previousOnHoverComponentId);
    highlightComponent(onHoverComponentId);
  }, [onHoverComponentId]);

  useEffect(() => {
    const loadMessages = async () => {
      const messages = await getMessages();
      if (messages && messages.status === 200) {
        setMessages(messages.data);
      }
    };
    loadMessages();
  }, []);

  const resetState = useCallback(() => {
    setIsEditingTitle(false);
    setShowEditButton(false);
    setEditButtonFocused(false);
  }, []);

  const onSaveTitle = useCallback(
    // @ts-ignore: ignore next line
    (event, value) => {
      const updatedComponent = {
        ...component,
        ...{ gist: { ...component.gist, description: value } },
      };
      updateComponent(
        updatedComponent,
        component.gist?.id,
        UpdateActions.update,
      );
      resetState();
      event.stopPropagation();
      event.preventDefault();
    },
    [component, updateComponent, resetState],
  );

  const onCancel = useCallback(
    // @ts-ignore: ignore next line
    (event) => {
      resetState();
      event.stopPropagation();
      event.preventDefault();
    },
    [resetState],
  );

  const startEditMode = useCallback(
    // @ts-ignore: ignore next line
    (event) => {
      setIsEditingTitle(true);
      event.stopPropagation();
      event.preventDefault();
    },
    [],
  );

  const onMouseEnterEditButton = useCallback(
    // @ts-ignore: ignore next line
    (event) => {
      setEditButtonFocused(true);
      event.preventDefault();
    },
    [],
  );

  const onMouseLeaveEditButton = useCallback(
    // @ts-ignore: ignore next line
    (event) => {
      setEditButtonFocused(false);
      event.preventDefault();
    },
    [],
  );

  if (definition) {
    return (
      <div id={component.gist.id} className="details">
        <div
          className={
            minimized ? 'minimizable minimized header' : 'minimizable header'
          }
        >
          <span className="container" onClick={minimize}>
            <img
              className="minimizable minimize-icon"
              src={minimized ? arrowDownImage : arrowUpImage}
              alt="Show / hide sub-components"
            />
            <ComponentTitle
              component={component}
              componentId={type}
              definition={definition}
              isEditing={isEditingTitle}
              showEditButton={showEditButton}
              showEditButtonHandler={setShowEditButton}
              onSave={onSaveTitle}
              onCancel={onCancel}
            />
            {(showEditButton || editButtonFocused) && !isEditingTitle && (
              <button
                className="edit-button"
                onMouseEnter={onMouseEnterEditButton}
                onMouseLeave={onMouseLeaveEditButton}
                onClick={startEditMode}
              >
                <EditOutline fill={editButtonFocused ? PURPLE_700 : GRAY_500} />
                <span className="edit-text">Edit</span>
              </button>
            )}
          </span>
          {canPerformActions && (
            <ComponentActionField
              updateComponent={updateComponent}
              deleteComponent={deleteComponent}
              component={component}
              canOrder={canOrder}
              canClone={canClone}
            />
          )}
        </div>
        <div className={minimized ? 'body minimized' : 'body'}>
          <ComponentLayout
            componentType={type}
            fields={fields}
            component={component}
            definition={definition}
            configuration={configuration}
            updateComponentField={updateComponentField}
            getComponentField={getComponentField}
            messages={messages}
            canPerformActions={canPerformActions}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="alert-details">
        <div className="header">
          <span>{`Unknown component/definition type ${
            type ? `:${type}` : ''
          }`}</span>
        </div>
      </div>
    );
  }
};
