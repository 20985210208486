import * as Sentry from '@sentry/react';
import { settings } from './settings-service';
import { extraErrorDataIntegration } from '@sentry/integrations';

// To work with sentry locally, override this method to return true
function sentryEnabled() {
  // Don't report local errors
  return settings.GIST_WEB_ENV !== 'local';
}

const getCurrentEnvironment = () => {
  switch (settings.GIST_WEB_ENV) {
    case 'dev':
      return 'development';
    case 'prod':
      return 'production';
    default:
      return 'local';
  }
};

export const initSentry = () => {
  if (!sentryEnabled()) {
    return;
  }
  const sentryConfig: Sentry.BrowserOptions = {
    dsn: settings.SENTRY_DSN,
    environment: `${getCurrentEnvironment()}`,
    integrations: [
      extraErrorDataIntegration(),
      Sentry.browserTracingIntegration(),
    ],
    // Performance Monitoring
    // Capture 50% of the transactions in dev and 25% in prod
    tracesSampleRate: getCurrentEnvironment() === 'production' ? 0.25 : 0.5,
    // Enable debug mode as needed
    debug: false,
  };
  Sentry.init(sentryConfig);
};

export const captureCustomException = (error: any) => {
  if (sentryEnabled()) {
    Sentry.captureException(error);
  }
};

export const setSentryUser = (flyUserId: string) => {
  if (sentryEnabled()) {
    Sentry.getCurrentScope().setUser({
      flyUserId,
    });
  }
};
