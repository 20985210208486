import React from 'react';
import { NodeModel } from '@minoru/react-dnd-treeview';
import { CIO_PURPLE } from '../../../utilities/colors';
import { INDENTATION_OFFSET } from './constants';

const styles = {
  container: {
    backgroundColor: CIO_PURPLE,
    height: '2px',
    position: 'absolute' as any,
    right: 0,
    top: 0,
    transform: 'translateY(-50%)',
  },
};

type Props = {
  node: NodeModel;
  depth: number;
};

export const Placeholder: React.FC<Props> = (props) => {
  const left = props.depth * INDENTATION_OFFSET;
  return <div style={{ ...styles.container, left: left }}></div>;
};
