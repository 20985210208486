// @ts-nocheck
import React, { Component } from 'react';
import EngineComponents from './EngineComponents';
import { ComponentDetailsEditor } from './ComponentDetailsEditor';
import {
  UpdateActions,
  SingleObjectContainerNames,
  MultipleObjectsContainerNames,
} from '../../../utilities/TemplateUtilities';

export default class ComponentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { component: {} };
  }

  updateComponentField = (field, fieldName) => {
    const { component } = this.state;
    if (fieldName && isNaN(fieldName)) {
      if (field === null) {
        delete component[fieldName];
      } else {
        component[fieldName] = field;
      }
    }
    this.props.updateComponent(
      component,
      component.gist.id,
      UpdateActions.update,
    );
  };

  collapseExpandComponent = () => {
    var showSettings = this.showSettings();
    this.state.component.gist.showSettings = !showSettings;
    this.props.updateComponent(
      this.state.component,
      this.state.component.gist.id,
      UpdateActions.update,
    );
  };

  showSettings = () => {
    var showSettings = true;
    if (this.state.component.gist.showSettings !== undefined) {
      showSettings = this.state.component.gist.showSettings;
    }
    return showSettings;
  };

  getComponentField = (fieldName) => {
    const { component } = this.state;
    return component[fieldName];
  };

  static getDerivedStateFromProps(props, state) {
    return { component: props.component };
  }

  render() {
    const {
      configuration,
      canOrder,
      canClone,
      canPerformActions,
      updateComponent,
      deleteComponent,
      isFirst,
    } = this.props;

    const { component } = this.state;

    var willShow = false;

    if (component.gist && component.gist.designer) {
      willShow = true;
    }

    const subComponents = [];
    MultipleObjectsContainerNames.forEach((key) => {
      if (component.hasOwnProperty(key)) {
        subComponents.push(
          <EngineComponents
            key={key}
            components={component[key]}
            configuration={configuration}
            updateComponent={updateComponent}
            deleteComponent={deleteComponent}
            fieldName={key}
            canPerformActions={canPerformActions}
            canOrder={component[key].length > 1}
            canClone={component[key].length > 0}
          />,
        );
      }
    });

    SingleObjectContainerNames.forEach((key) => {
      // Additioning component[key] != null to allow moving out components
      // held by actions or conditions
      if (component.hasOwnProperty(key) && component[key] != null) {
        const componentToShow = (
          <ComponentDetails
            key={key}
            component={component[key]}
            configuration={configuration}
            updateComponent={updateComponent}
            deleteComponent={deleteComponent}
            fieldName={key}
            canPerformActions={canPerformActions}
            canOrder={false}
            canClone={false}
          />
        );
        if (key === 'true' || key === 'false') {
          subComponents.push(
            <div key={`${component.gist.id}-${key}`} className="details">
              <div className="header">
                {key === 'true'
                  ? 'Condition evaluates to true'
                  : 'Condition evaluates to false'}
              </div>
              {componentToShow}
            </div>,
          );
        } else {
          subComponents.push(componentToShow);
        }
      }
    });

    let canOrderComponents = canOrder;
    if (canOrderComponents === undefined) {
      canOrderComponents = true;
    }

    let canCloneComponents = canClone;
    if (canCloneComponents === undefined) {
      canCloneComponents = true;
    }

    let componentShowClassName = '';
    if (willShow) {
      if (!isFirst) {
        componentShowClassName = 'show';
      }
    } else {
      componentShowClassName = 'hide';
    }

    return (
      <div key={component.gist.id} className={componentShowClassName}>
        <ComponentDetailsEditor
          component={component}
          configuration={configuration}
          updateComponent={this.props.updateComponent}
          deleteComponent={this.props.deleteComponent}
          updateComponentField={this.updateComponentField}
          getComponentField={this.getComponentField}
          minimize={this.collapseExpandComponent}
          minimized={!this.showSettings()}
          canOrder={canOrderComponents}
          canClone={canCloneComponents}
          canPerformActions={canPerformActions}
        />
        {subComponents.length > 0 && subComponents}
      </div>
    );
  }
}
