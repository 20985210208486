// @ts-nocheck
import React, { Component } from 'react';
import EngineComponents from './EngineComponents';
import AddSingleComponentField from '../fields/AddSingleComponentField';
import {
  updateComponentWithActionFromTemplate,
  deleteComponentFromTemplate,
  verifyTemplateIds,
} from '../../../utilities/TemplateUtilities';

export default class ComponentView extends Component {
  constructor(props) {
    super(props);
    if (props.components) {
      // Doing this prior to the verification, cause props.components is passed by reference
      const verifiedTemplate = verifyTemplateIds(props.components);
      this.props.updateTemplate(verifiedTemplate);
    } else {
      this.props.updateTemplate([]);
    }
  }

  updateComponent = (component, componentId, action) => {
    const updatedTemplate = updateComponentWithActionFromTemplate(
      this.props.components,
      component,
      componentId,
      action,
    );
    this.props.updateTemplate(updatedTemplate);
  };

  deleteComponent = (id) => {
    const updatedTemplate = deleteComponentFromTemplate(
      this.props.components,
      id,
    );
    this.props.updateTemplate(updatedTemplate);
  };

  addComponent = (component) => {
    const { components } = this.props;
    components.push(component);
    this.props.updateTemplate(components);
  };

  render() {
    const { configuration, components } = this.props;
    return components ? (
      <div className="component-builder">
        <EngineComponents
          configuration={configuration}
          components={components}
          updateComponent={this.updateComponent}
          deleteComponent={this.deleteComponent}
          isFirst={true}
          canPerformActions={true}
        />
        {
          <div className="component sub-component">
            <div className="details">
              <div className="header">Pick a component</div>
              <div className="body padding">
                <div className="field">
                  <span className="input-field-title">Component</span>
                  <span className="input-field">
                    <AddSingleComponentField
                      clearOnAdd={true}
                      configuration={configuration}
                      onChange={this.addComponent}
                      canPerformActions={true}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    ) : (
      <div />
    );
  }
}
