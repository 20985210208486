import React, { Component } from 'react';
import { ReactComponent as EditOutline } from '../../../assets/images/edit-outline.svg';
import { RenameEditor } from './RenameEditor';
import { truncateText } from '../../../utilities/TextUtilities';
import { notifyParentToShowDeleteMessageModal } from '../../../services/embed-service';
import Tooltip from './Tooltip';
import { trackEvent } from '../../../services/reporting-service';

type BuilderHeaderProps = {
  messageId: string;
  messageName: string;
  canSave: boolean;
  readOnly: boolean;
  hasUsage: boolean;
  onSaveMessageName: (newName: string) => Promise<void>;
  revertChanges: () => Promise<void>;
  saveMessage: () => Promise<void>;
};

type BuilderHeaderState = {
  editModeEnabled: boolean;
  showEditIcon: boolean;
};

const TITLE_MAX_LENGTH = 65;

export class BuilderHeader extends Component<
  BuilderHeaderProps,
  BuilderHeaderState
> {
  constructor(props: any) {
    super(props);
    this.state = { editModeEnabled: false, showEditIcon: false };
  }

  onEditClick = () => {
    if (this.props.readOnly) {
      return;
    }

    this.setState({ editModeEnabled: true });
  };

  onSaveMessageName = async (e: any, value: string) => {
    trackEvent('MESSAGE_RENAMED', { 'Message ID': this.props.messageId });
    e.preventDefault();

    if (value === this.props.messageName) {
      this.setState({ editModeEnabled: false, showEditIcon: false });
      return;
    }

    await this.props.onSaveMessageName(value);
    this.setState({ editModeEnabled: false, showEditIcon: false });
  };

  onMouseEnterForEditIcon = () => {
    if (this.props.readOnly) {
      return;
    }

    this.setState({ showEditIcon: true });
  };

  onMouseLeaveForEditIcon = () => {
    if (this.props.readOnly) {
      return;
    }

    this.setState({ showEditIcon: false });
  };

  onDeleteClick = () => {
    if (!this.props.hasUsage) {
      notifyParentToShowDeleteMessageModal(this.props.messageName);
    }
  };

  render() {
    const {
      messageName,
      revertChanges,
      saveMessage,
      canSave,
      hasUsage,
      readOnly,
    } = this.props;
    const { editModeEnabled, showEditIcon } = this.state;

    return (
      <div className="toolbar-sticky w-full">
        <div className="action-menu flex-1">
          <div className="page-nav">
            {editModeEnabled ? (
              <RenameEditor
                value={messageName}
                onSave={this.onSaveMessageName}
                onCancel={() =>
                  this.setState({
                    editModeEnabled: false,
                    showEditIcon: false,
                  })
                }
              />
            ) : (
              <span
                className="title top-title-text"
                onClick={() => this.onEditClick()}
                onMouseEnter={this.onMouseEnterForEditIcon}
                onMouseLeave={this.onMouseLeaveForEditIcon}
              >
                {truncateText(messageName, TITLE_MAX_LENGTH)}
              </span>
            )}
          </div>
          {!editModeEnabled && showEditIcon && (
            <button
              onMouseEnter={this.onMouseEnterForEditIcon}
              onMouseLeave={this.onMouseLeaveForEditIcon}
              className="edit-button"
              onClick={() => this.onEditClick()}
            >
              <EditOutline className="edit-icon" />
              <span className="edit-text">Edit</span>
            </button>
          )}
          <div className="action-buttons">
            <Tooltip
              data-testid="delete-button"
              isShown={hasUsage}
              content="You can't delete a message that's currently used in campaigns or broadcasts."
            >
              <button
                className={`button delete ${
                  readOnly || hasUsage ? 'disabled' : ''
                }`}
                disabled={readOnly}
                onClick={this.onDeleteClick}
              >
                Delete
              </button>
            </Tooltip>
            <button
              data-testid="revert-button"
              className={`button ${readOnly ? 'disabled' : ''}`}
              onClick={() => revertChanges()}
            >
              Revert
            </button>
            <Tooltip
              data-testid="save-button"
              isShown={!canSave}
              content="Required fields cannot be empty."
            >
              <button
                className={`button primary ${
                  !canSave || readOnly ? 'disabled' : ''
                }`}
                disabled={!canSave || readOnly}
                onClick={() => saveMessage()}
              >
                Save
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}
