import React, { Component } from 'react';

export default class TemplateStylePaddingField extends Component {
  onChange = (e) => {
    this.props.onStyleChange('padding', e.target.name, e.target.value);
  };

  getPaddingFromName = (name) => {
    const configuration = this.props.configuration;
    var paddingValue = '-';
    configuration.style.padding.forEach((padding) => {
      if (padding.name === name) {
        paddingValue = padding.value;
      }
    });
    return paddingValue;
  };

  render() {
    const { padding, configuration } = this.props;

    return (
      <div>
        {Object.keys(padding).map((paddingStyle) => {
          return (
            <div className="field" key={paddingStyle}>
              <div className="input-field-title">{paddingStyle} Padding</div>
              <div className="input-field">
                <div className="preview-field">
                  <div className="preview">
                    <div className="bubble">
                      {this.getPaddingFromName(
                        padding[paddingStyle] === ''
                          ? paddingStyle
                          : padding[paddingStyle],
                      )}
                    </div>
                  </div>
                  <select
                    name={paddingStyle}
                    defaultValue={paddingStyle}
                    onChange={this.onChange}
                    required
                  >
                    <option value=""></option>
                    {configuration.style.padding.map((configPaddingStyle) => {
                      return (
                        <option
                          key={configPaddingStyle.name}
                          value={configPaddingStyle.name}
                        >
                          {configPaddingStyle.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
