import React, { Component } from 'react';

export default class TemplateStyleColorField extends Component {
  onChange = (e) => {
    this.props.onStyleChange('colors', e.target.name, e.target.value);
  };

  getColorFromName = (name) => {
    const configuration = this.props.configuration;
    var hexColor = '';
    configuration.style.colors.forEach((color) => {
      if (color.name === name) {
        hexColor = color.value;
      }
    });
    return hexColor;
  };

  render() {
    const { colors, configuration } = this.props;

    return (
      <div>
        {Object.keys(colors).map((color) => {
          return (
            <div className="field" key={color}>
              <div className="input-field-title">{color} Color</div>
              <div className="input-field">
                <div className="preview-field">
                  <div className="preview">
                    <div
                      className="bubble"
                      style={{
                        backgroundColor: this.getColorFromName(
                          colors[color] === '' ? color : colors[color],
                        ),
                      }}
                    >
                      &nbsp;
                    </div>
                  </div>
                  <select
                    name={color}
                    defaultValue={color}
                    onChange={this.onChange}
                    required
                  >
                    <option value=""></option>
                    {configuration.style.colors.map((configColor) => {
                      return (
                        <option key={configColor.name} value={configColor.name}>
                          {configColor.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
