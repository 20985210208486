import React, { Component } from 'react';
import AddImage from '../../../assets/images/large-plus.svg';
import { trackEvent } from '../../../services/reporting-service';
import TextStyleEditor from './TextStyleEditor';

class TextStylesList extends Component {
  clearSelected = { id: -1, textStyle: null };

  constructor(props) {
    super(props);
    this.state = {
      selected: this.clearSelected,
      isNew: false,
      textStyles: [],
      fonts: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    var updatedState = state;
    updatedState.textStyles = props.textStyles;
    updatedState.fonts = props.fonts;
    return updatedState;
  }

  textStyleSelected = (id, textStyle) => {
    if (this.state.selected.id === id) {
      this.resetSelection();
    } else {
      this.setState({
        isNew: false,
        selected: { id: id, textStyle: textStyle },
      });
      trackEvent('BRANDING_EDIT_STARTED', {
        Name: textStyle.name ?? 'Unknown text style name',
        Font: textStyle.font,
        'Font Size': textStyle.size,
        'Line Height': textStyle.lineHeight,
        Type: 'Text Style',
      });
    }
  };

  toggleNewTextStyle = () => {
    this.resetSelection();
    this.setState({ isNew: !this.state.isNew });
    trackEvent('BRANDING_CREATION_STARTED', { Type: 'Text Style' });
  };

  resetSelection = () => {
    this.setState({ isNew: false, selected: this.clearSelected });
  };

  removeTextStyle = (index) => {
    var textStyles = this.state.textStyles;
    trackEvent('BRANDING_DELETED', {
      Name: textStyles[index].name ?? 'Unknown text style name',
      Type: 'Text Style',
    });
    textStyles.splice(index, 1);
    this.setState({ textStyles: textStyles });
    this.props.onTextStylesChange(textStyles);
    this.resetSelection();
  };

  saveTextStyle = (textStyle, index) => {
    var textStyles = this.state.textStyles;
    if (index != null) {
      textStyles[index] = textStyle;
      trackEvent('BRANDING_EDIT_COMPLETED', {
        Name: textStyle.name ?? 'Unknown text style name',
        Font: textStyle.font,
        'Font Size': textStyle.size,
        Type: 'Text Style',
        'Line Height': textStyle.lineHeight,
      });
    } else {
      textStyles.push(textStyle);
      trackEvent('BRANDING_CREATION_COMPLETED', {
        Name: textStyle.name ?? 'Unknown text style name',
        Font: textStyle.font,
        'Font Size': textStyle.size,
        Type: 'Text Style',
        'Line Height': textStyle.lineHeight,
      });
    }
    this.setState({ textStyles: textStyle });
    this.props.onTextStylesChange(textStyles);
    this.resetSelection();
  };

  render() {
    const { textStyles, fonts, selected, isNew } = this.state;

    return (
      <div className="editor-card-container spacer">
        <span className="card-text title">Text Styles</span>
        <div className="card shadow">
          <div className="body alt">
            <div className="grid">
              {textStyles.map((textStyle, id) => {
                return (
                  <div key={id}>
                    <div
                      className="field tappable"
                      onClick={() => this.textStyleSelected(id, textStyle)}
                    >
                      <div className="display">
                        <div
                          className="bubble"
                          style={{
                            fontSize:
                              textStyle.size <= 60 ? textStyle.size : 60,
                          }}
                        >
                          {textStyle.size}
                        </div>
                      </div>
                      <div className="title">{textStyle.name}</div>
                    </div>
                  </div>
                );
              })}
              <div
                className="field tappable"
                onClick={() => this.toggleNewTextStyle()}
              >
                <div className="display">
                  <div className="bubble add">
                    <img src={AddImage} alt="Add text style" />
                  </div>
                </div>
                <div className="title">&nbsp;</div>
              </div>
            </div>
            {isNew ? (
              <TextStyleEditor
                saveTextStyle={this.saveTextStyle}
                cancel={this.resetSelection}
                fonts={fonts}
                readOnly={this.props.readOnly}
              />
            ) : (
              selected.id !== -1 && (
                <TextStyleEditor
                  key={selected.id}
                  fonts={fonts}
                  textStyle={selected.textStyle}
                  id={selected.id}
                  removeTextStyle={this.removeTextStyle}
                  saveTextStyle={this.saveTextStyle}
                  cancel={this.resetSelection}
                  readOnly={this.props.readOnly}
                />
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default TextStylesList;
