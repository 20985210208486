import React, { useCallback, useState } from 'react';
import { Select } from '../components/Select';
import { SelectOption } from '../components/Select/types';

interface CrossAxisAlignmentFieldProps {
  value: any;
  onChange: (value: any) => void;
  selectPlaceholder?: string;
}

export const CrossAxisAlignmentField = ({
  value,
  onChange,
  selectPlaceholder,
}: CrossAxisAlignmentFieldProps) => {
  const [options] = useState<SelectOption[]>([
    { value: 'start', label: 'Start' },
    { value: 'end', label: 'End' },
    { value: 'center', label: 'Center' },
    { value: 'stretch', label: 'Stretch' },
    { value: 'baseline', label: 'Baseline' },
  ]);

  const getDefaultValue = useCallback(
    (value: string) => {
      const option = options.find((option) => option.value === value);
      return option ? option : null;
    },
    [options],
  );

  const onChangeWrapper = useCallback(
    (option: any) => {
      onChange({ target: { value: option ? option.value : '' } });
    },
    [onChange],
  );

  return (
    <div className="select-container">
      <Select
        value={getDefaultValue(value)}
        isClearable={true}
        options={options}
        placeholder={selectPlaceholder ?? 'Select cross axis alignment...'}
        onChange={onChangeWrapper}
      />
    </div>
  );
};
