// @ts-nocheck
import React, { Component } from 'react';
import ComponentDetails from './ComponentDetails';

export default class EngineComponents extends Component {
  render() {
    const {
      configuration,
      components,
      fieldName,
      canOrder,
      canClone,
      canPerformActions,
      isFirst,
    } = this.props;

    return (
      <div className={fieldName ? 'component sub-component' : 'component'}>
        {components.map((component) => (
          <ComponentDetails
            key={component.gist.id}
            component={component}
            configuration={configuration}
            updateComponent={this.props.updateComponent}
            deleteComponent={this.props.deleteComponent}
            canOrder={canOrder}
            canClone={canClone}
            canPerformActions={canPerformActions}
            isFirst={isFirst}
          />
        ))}
      </div>
    );
  }
}
