import React from 'react';
import { components } from 'react-select';
import { GRAY_200, GRAY_500, GRAY_700 } from '../../../../../utilities/colors';

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  colorLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  dot: {
    flexShrink: 0,
    width: '20px',
    height: '20px',
    border: `1px solid ${GRAY_200}`,
    borderRadius: '50%',
    marginRight: '8px',
  },
  // TODO: Define the following two styles in a typography component
  label: { color: GRAY_700, fontSize: '14px', fontWeight: 600 },
  description: {
    color: GRAY_500,
    fontSize: '12px',
    fontWeight: 400,
    marginLeft: '4px',
  },
};

export const ColorOption = (props: any) => {
  const { label, value, description } = props.data;

  return (
    <components.Option {...props}>
      <div style={styles.container}>
        <div style={styles.colorLabelContainer}>
          <div
            style={{
              ...styles.dot,
              backgroundColor: value,
            }}
          ></div>
          <span
            className="option-label"
            aria-label={label}
            title={`${label} - ${description}`}
            style={styles.label}
          >
            {label}
          </span>{' '}
        </div>
        <span aria-label={description} style={styles.description}>
          {description}
        </span>
      </div>
    </components.Option>
  );
};
