const userIdKey = 'fly.userId';
const workspaceIdKey = 'fly.workspaceId';

export function setFlyUserId(userId) {
  localStorage.setItem(userIdKey, userId);
}

export function getFlyUserId() {
  return localStorage.getItem(userIdKey);
}

export function setFlyWorkspaceId(workspaceId) {
  localStorage.setItem(workspaceIdKey, workspaceId);
}

export function getFlyWorkspaceId() {
  return localStorage.getItem(workspaceIdKey) ?? '';
}
