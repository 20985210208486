import '../assets/app.scss';
import '../assets/media.scss';

import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  useLocation,
} from 'react-router-dom';

import MessagesEmbed from '../views/messages/MessagesEmbed';
import BrandEmbed from '../views/brand/BrandEmbed';
import ColorsListEmbed from '../views/brand/ColorsListEmbed';
import BuilderEmbed from '../views/builder/BuilderEmbed';
import NewMessageSetup from '../views/messages/EmbedMessageSetup';
import {
  notififyParentOfNewSize,
  notifyParentFinishLoading,
  notifyParentOfError,
  notifyParentOfSuccess,
  notifyParentThatLocationChanged,
  notifyParentThatUserLoggedIn,
} from '../services/embed-service';
import { deleteMessage } from '../services/message-service';
import { setNativeValue } from '../utilities/TextUtilities';
import { setFlyUserId, setFlyWorkspaceId } from '../services/fly-user-service';
import { identifyUser, trackEvent } from '../services/reporting-service';
import { EditorContextProvider } from '../context/EditorContext';

const SUPPORTED_FLY_METHODS = [
  'saveFlyUserId',
  'saveFlyWorkspaceId',
  'inAppMessageUsage',
  'deleteMessageConfirmed',
  'deleteMessageCancelled',
  'onSelectAsset',
  'setReadOnlyMode',
];

const isSupportedFlyMethod = (method) => {
  return SUPPORTED_FLY_METHODS.includes(method);
};

class EmbedLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageUsage: null,
      readOnly: false,
    };
  }

  componentDidMount() {
    notifyParentThatUserLoggedIn();
    window.addEventListener('load', notififyParentOfNewSize);
    window.addEventListener('message', this.handleFlyMessages.bind(this));
    watchAppContainerSize(ON);
    notifyParentFinishLoading();
  }

  componentWillUnmount() {
    window.removeEventListener('load', notififyParentOfNewSize);
    window.removeEventListener('message', this.handleFlyMessages.bind(this));
    watchAppContainerSize(OFF);
  }

  handleFlyMessages = (e) => {
    if (!e.data.fly) {
      return;
    }

    const { method, payload } = e.data.fly;
    if (isSupportedFlyMethod(method)) {
      let handler = this[method];
      handler && handler(payload);
    }
  };

  saveFlyUserId = (payload) => {
    const { userId } = payload;
    setFlyUserId(userId);
    identifyUser();
  };

  saveFlyWorkspaceId = (payload) => {
    const { workspaceId } = payload;
    setFlyWorkspaceId(workspaceId);
  };

  inAppMessageUsage = (payload) => {
    this.setState({ messageUsage: payload.usage });
  };

  deleteMessageConfirmed = async (payload) => {
    let { messageId } = payload;
    if (!messageId) {
      return;
    }

    const response = await deleteMessage(messageId);
    trackEvent('MESSAGE_DELETED', { 'Message ID': messageId });
    if (response && response.status === 200) {
      window.location.replace('/embed/');
      notifyParentOfSuccess('Message deleted', response);
    } else {
      notifyParentOfError('There was a problem deleting the message', response);
    }
  };

  onSelectAsset = (payload) => {
    const { assetInputId, assetPath } = payload;

    if (!assetInputId || !assetPath) {
      return;
    }

    let input = document.getElementById(assetInputId);
    if (!input) {
      return;
    }

    try {
      setNativeValue(input, assetPath);
      input.dispatchEvent(new Event('input', { bubbles: true }));
    } catch (e) {
      notifyParentOfError('There was a problem selecting the asset', e);
    }
  };

  setReadOnlyMode = (payload) => {
    const { readOnly } = payload;
    this.setState({ readOnly });
  };

  render() {
    const { readOnly, messageUsage } = this.state;

    return (
      <div className="w-screen box-border columns-1">
        <Router>
          <div className="app-container p-4 pt-0">
            <ReactNotifications />
            <WatchLocationChanges />
            <Switch>
              <Route path="/">
                <Route exact path="/">
                  <MessagesEmbed readOnly={readOnly} />
                </Route>
                <Route exact path="/embed/">
                  <MessagesEmbed readOnly={readOnly} />
                </Route>
                <Route exact path="/embed/account/branding">
                  <BrandEmbed readOnly={readOnly} />
                </Route>
                <Route exact path="/embed/account/branding/colors">
                  <ColorsListEmbed readOnly={readOnly} />
                </Route>
                <Route path="/embed/new/message">
                  <Route exact path="/embed/new/message">
                    <NewMessageSetup readOnly={readOnly} />
                  </Route>
                  <Route path="/embed/new/message/:folderId">
                    <ShowMessageSetup />
                  </Route>
                </Route>
                <Route exact path="/embed/message/:messageId">
                  <ShowMessageBuilder
                    messageUsage={messageUsage}
                    readOnly={readOnly}
                  />
                </Route>
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

function WatchLocationChanges() {
  let location = useLocation();
  React.useEffect(() => {
    notifyParentThatLocationChanged({
      pathname: location.pathname,
      search: location.search,
      hash: location.hash,
    });
  }, [location]);
  return null;
}

function ShowMessageBuilder({ messageUsage, readOnly }) {
  let { messageId } = useParams();
  return (
    <EditorContextProvider>
      <BuilderEmbed
        messageId={messageId}
        messageUsage={messageUsage}
        readOnly={readOnly}
      />
    </EditorContextProvider>
  );
}

ShowMessageBuilder.propTypes = {
  messageUsage: PropTypes.array,
  readOnly: PropTypes.bool,
};

function ShowMessageSetup() {
  let { folderId } = useParams();
  return <NewMessageSetup folderId={folderId} readOnly={this.state.readOnly} />;
}

const ON = 'observe';
const OFF = 'unobserve';
const resizeObserver = new ResizeObserver(() => {
  notififyParentOfNewSize();
});

function watchAppContainerSize(mode) {
  let appContainer = getAppContainer();
  if (appContainer != null && appContainer instanceof Element) {
    resizeObserver[mode](appContainer);
  }
}

function getAppContainer() {
  return document.querySelector('.app-container');
}

export default EmbedLayout;
