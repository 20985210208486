import { Component } from 'react';
import ComponentView from './ComponentView';
import CodeView from './CodeView';
import '../../../assets/builder.scss';
import '../../../assets/editor.scss';
import { notifyParentOfError } from '../../../services/embed-service';
import { Template } from '../../../types/common';

interface BuilderViewProps {
  activeView: 'designer' | 'code';
  configuration: any;
  template: Template;
  updateTemplate: (template: Template) => unknown;
}

class BuilderView extends Component<BuilderViewProps, {}> {
  constructor(props: any) {
    super(props);
  }

  componentDidCatch(error: any) {
    this.setState({ activeView: 'code' });
    notifyParentOfError(
      'There was a problem opening message in designer view, switching to code view.',
      error,
    );
  }

  render() {
    const { configuration, template, activeView } = this.props;

    return (
      <div>
        <div className="editor-card-container">
          <div className="card builder">
            {activeView === 'code' ? (
              <CodeView
                components={template}
                updateTemplate={this.props.updateTemplate}
              />
            ) : (
              <ComponentView
                // @ts-ignore
                components={template}
                configuration={configuration}
                updateTemplate={this.props.updateTemplate}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default BuilderView;
