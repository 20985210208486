import React, { Component } from 'react';
import NewMessage from './components/NewMessage';
import { createMessage } from '../../services/message-service';
import { trackEvent } from '../../services/reporting-service';
import { Link } from 'react-router-dom';
import back from '../../assets/images/back.svg';
import { processCreateMessageResponse } from '../../utilities/messages';

class EmbedMessageSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSave: false,
      isSaving: false,
      message: {
        folderId: this.props.folderId,
        id: '',
        name: '',
        position: 'center',
        template: '[]',
        data: [],
      },
    };
  }

  onMessageChange = (message) => {
    this.setState({ message: message });
  };

  canSave = (canSave) => {
    this.setState({ canSave: canSave });
  };

  saveMessage = async (e) => {
    e.preventDefault();
    if (!this.state.isSaving) {
      const { message } = this.state;
      this.setState({ isSaving: true });
      var createResponse = await createMessage(message);
      trackEvent('BRANDING_CREATION_COMPLETED', { Name: message.name });
      processCreateMessageResponse(
        createResponse,
        message,
        `embed/message/${message.id}`,
      );
      this.setState({ isSaving: false });
    }
  };

  render() {
    return (
      <form onSubmit={this.saveMessage}>
        <div className="row">
          <div className="col-xs-12">
            <div className="action-menu">
              <Link to={'/'}>
                <div className="page-nav">
                  <span className="back">
                    <img src={back} alt="back" />
                  </span>
                  <span className="title top-title-text">New Message</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">&nbsp;</div>
        </div>
        {
          <NewMessage
            message={this.state.message}
            onMessageChange={this.onMessageChange}
            isNew={true}
            canSave={this.canSave}
            isSaving={this.state.isSaving}
            isEmbedded={true}
            readOnly={this.props.readOnly}
          />
        }
      </form>
    );
  }
}

export default EmbedMessageSetup;
