import React, { Component } from 'react';
import AddImage from '../../../assets/images/large-plus.svg';
import { trackEvent } from '../../../services/reporting-service';
import PaddingEditor from './PaddingEditor';

class PaddingList extends Component {
  clearSelected = { id: -1, padding: null };

  constructor(props) {
    super(props);
    this.state = { selected: this.clearSelected, isNew: false, padding: [] };
  }

  static getDerivedStateFromProps(props, state) {
    var updatedState = state;
    updatedState.padding = props.padding;
    return updatedState;
  }

  paddingSelected = (id, padding) => {
    if (this.state.selected.id === id) {
      this.resetSelection();
    } else {
      this.setState({ isNew: false, selected: { id: id, padding: padding } });

      trackEvent('BRANDING_EDIT_STARTED', {
        Name: padding.name,
        Padding: padding.value,
        Type: 'Padding',
      });
    }
  };

  toggleNewPadding = () => {
    this.resetSelection();
    this.setState({ isNew: !this.state.isNew });
    trackEvent('BRANDING_CREATION_STARTED', { Type: 'Padding' });
  };

  resetSelection = () => {
    this.setState({ isNew: false, selected: this.clearSelected });
  };

  removePadding = (index) => {
    var padding = this.state.padding;
    trackEvent('BRANDING_DELETED', {
      Name: padding[index]?.name ?? 'Unknown color name',
      Padding: padding[index]?.value ?? 'Unknown padding value',
      Type: 'Padding',
    });
    padding.splice(index, 1);
    this.setState({ padding: padding });
    this.props.onPaddingChange(padding);
    this.resetSelection();
  };

  savePadding = (updatedPadding, index) => {
    var padding = this.state.padding;
    if (index != null) {
      padding[index] = updatedPadding;
      trackEvent('BRANDING_EDIT_COMPLETED', {
        Name: updatedPadding.name ?? 'Unknown padding name',
        Padding: updatedPadding.value,
        Type: 'Padding',
      });
    } else {
      padding.push(updatedPadding);
      trackEvent('BRANDING_CREATION_COMPLETED', {
        Name: updatedPadding.name ?? 'Unknown padding name',
        Padding: updatedPadding.value,
        Type: 'Padding',
      });
    }
    this.setState({ padding: padding });
    this.props.onPaddingChange(padding);
    this.resetSelection();
  };

  render() {
    const { padding, selected, isNew } = this.state;

    return (
      <div className="editor-card-container spacer">
        <span className="card-text title">Padding</span>
        <div className="card shadow">
          <div className="alt body">
            <div className="grid">
              {padding.map((paddingItem, id) => {
                return (
                  <div key={id}>
                    <div
                      className="field tappable"
                      onClick={() => this.paddingSelected(id, paddingItem)}
                    >
                      <div className="display">
                        <div className="bubble">{paddingItem.value}</div>
                      </div>
                      <div className="title">{paddingItem.name}</div>
                    </div>
                  </div>
                );
              })}
              <div
                className="field tappable"
                onClick={() => this.toggleNewPadding()}
              >
                <div className="display">
                  <div className="bubble add">
                    <img src={AddImage} alt="Add padding" />
                  </div>
                </div>
                <div className="title">&nbsp;</div>
              </div>
            </div>
            {isNew ? (
              <PaddingEditor
                savePadding={this.savePadding}
                cancel={this.resetSelection}
                readOnly={this.props.readOnly}
              />
            ) : (
              selected.id !== -1 && (
                <PaddingEditor
                  key={selected.id}
                  padding={selected.padding}
                  id={selected.id}
                  removePadding={this.removePadding}
                  savePadding={this.savePadding}
                  cancel={this.resetSelection}
                  readOnly={this.props.readOnly}
                />
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PaddingList;
