import { DropOptions, NodeModel } from '@minoru/react-dnd-treeview';
import { CustomData } from '../views/builder/Layers/types';
import { getExtraMetadataForDefinitionType } from '../views/builder/definitions/definition-manager';
import { refreshComponentsIds } from './TemplateUtilities';
import { cloneDeep } from 'lodash';
import { findAndRemoveComponentById, insertNodeAtTarget } from './template';
import { isComponentDroppable } from './dnd';
import { Template } from '../types/common';

/* Util from the @minoru/react-dnd-treeview moved into our codebase instead of importing, because that broke the tests */
const getDescendants = <T = unknown>(
  treeData: NodeModel<T>[],
  id: NodeModel['id'],
): NodeModel<T>[] => {
  let descendants: NodeModel<T>[] = [];

  const search = (tree: NodeModel<T>[], ids: NodeModel['id'][]) => {
    const children = tree.filter((node) => ids.includes(node.parent));

    if (children.length > 0) {
      descendants = [...descendants, ...children];

      search(
        tree,
        children.map((node) => node.id),
      );
    }
  };

  search(treeData, [id]);

  return descendants;
};

interface Node {
  id: string | number;
  parent: string | number;
  droppable: boolean;
  text: string;
  data: CustomData;
}

const createNode = (
  gistId: string | number,
  parentId: string | number,
  droppable: boolean,
  name: string,
  customData: CustomData,
): Node => ({
  id: gistId,
  parent: parentId,
  droppable,
  text: name,
  data: customData,
});

/* 
    Data structure of a template:
    A template is an array of components.
    Components can be nested within each-other.
    Each object in the components array also has a property called components (unless it is an actionWidget or a conditionalWidget)
    which holds is an array of objects.
    This pattern repeats itself until there are no more components. 

    Each component in the template has the following notable properties:
    - type: The type of the component. This is used to determine the icon and the name of the component.
    - gist: Metadata of the component. gist.id is the unique identifier of the component.
    - components: An array of components. This is used to model the children of the component.
    - component (actionWidget unary reference): An object containing a child component. This is used to model the children of the component.
    - true and false (two unary references conditionalWidget): keys to refer to child components of a parent conditional component 

    Example: 
    [
        {
            type: 'blockWidget',
            gist: {
            id: '76fc631e-ceae-47ba-a42a-da1d2ce2dd08',
            designer: true,
            },
            components: [
                {
                            "type": "fixedHorizontalListWidget",
                            "gist": {
                                "designer": true,
                                "showSettings": false,
                                "description": "Image spacing",
                                "id": "0336e595-c16d-42ad-b104-033297164c53"
                            },
                            "components": [...]
                }
        },
        {
            type: 'actionWidget',
            gist: {
            id: '76fc631e-ceae-47ba-a42a-da1d2ce2dd08',
            designer: true,
            },
            component: { ... }
        },
        {
            type: 'conditionalWidget',
            gist: {
            id: '76fc631e-ceae-47ba-a42a-da1d2ce2dd08',
            designer: true,
            },
            condition: '...',
            true: { ... },
            false: { ... }
        },
    ];
*/

const isNodeHidden = (designer: boolean | undefined): boolean => {
  if (designer == null) {
    return false;
  }
  return Boolean(!designer);
};

export const templateToNodesArray = (
  template: Template,
): NodeModel<CustomData>[] => {
  return template && template.length > 0 && template[0]?.gist?.id == null
    ? generateNodesArray(refreshComponentsIds(template))
    : generateNodesArray(template);
};

export const generateNodesArray = (components: any, parent = null): Node[] => {
  const nodesArray = [];

  if (!components || components.length === 0) {
    return [];
  }

  for (const component of components) {
    const { id, description, designer } = component.gist;
    const metadata = getExtraMetadataForDefinitionType(component.type);
    const droppable = isComponentDroppable(component);
    const node = createNode(
      id,
      parent ?? 0,
      droppable,
      description ?? metadata?.name ?? 'Unknown name',
      {
        // Possible performance improvement for the future
        // Downside: The array needs to be recalculated each time the template is updated
        // otherwise this metadata might be outdated
        // ...(component.type === 'conditionalWidget' && {
        //   condition: component.condition,
        //   true: component.true,
        //   false: component.false,
        // }),
        // ...(component.type === 'actionWidget' && {
        //   component: component.component,
        // }),
        componentType: component.type,
        description: metadata?.description ?? 'Unknown description',
        iconName: metadata?.iconName ?? '',
        isHidden: isNodeHidden(designer),
      },
    );
    nodesArray.push(node);

    // Handle nested components using "components" property
    if (component.components && component.components.length > 0) {
      nodesArray.push(
        ...generateNodesArray(component.components, component.gist.id),
      );
    }

    // Handle nested components using "component" property
    if (component.component) {
      nodesArray.push(
        ...generateNodesArray([component.component], component.gist.id),
      );
    }

    // Handle conditional components
    if (component.type === 'conditionalWidget') {
      if (component.true) {
        nodesArray.push(
          ...generateNodesArray([component.true], component.gist.id),
        );
      }
      if (component.false) {
        nodesArray.push(
          ...generateNodesArray([component.false], component.gist.id),
        );
      }
    }
  }
  return nodesArray;
};

export const updateTemplateFromLayersArray = (
  template: Template,
  actualTree: NodeModel<CustomData>[],
  options: DropOptions<CustomData>,
) => {
  const { dragSourceId, dropTargetId, relativeIndex, dragSource, dropTarget } =
    options;

  if (relativeIndex == null) {
    return template;
  }

  // Find and remove the source node
  // Freeze the template and then clone it to prevent mutation
  Object.freeze(template);
  const clonedTemplate = cloneDeep(template);
  const [updatedTemplate, removedNode] = findAndRemoveComponentById(
    clonedTemplate,
    dragSourceId as string,
  );

  // Node not found
  if (removedNode == null) {
    console.error(`Drag source component with id ${dragSourceId} not found.`);
    return template;
  }

  // If moving downwards within the same parent, modify the relativeIndex, since the removal already happened
  let insertionIndex = relativeIndex;
  // Movement is within the root level or nodes have same parent
  if (
    (dragSource?.parent == 0 && !dropTarget) ||
    dragSource?.parent === dropTarget?.id
  ) {
    const descendants = getDescendants(actualTree, dragSource!.parent);
    const dragSourceIndex = descendants.findIndex(
      (node) => node.id === dragSourceId,
    );
    if (dragSourceIndex < relativeIndex) {
      insertionIndex--;
    }
  }

  // Insert the source node at the target location
  const finalTemplate = insertNodeAtTarget(
    removedNode,
    dropTargetId,
    updatedTemplate,
    insertionIndex,
  );

  return finalTemplate;
};
