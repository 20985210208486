import React, { useState } from 'react';
import { NodeModel, useDragOver } from '@minoru/react-dnd-treeview';
import { CustomData } from './types';
import { useEditorContextUpdater } from '../../../context/useEditorContext';
import { ItemRow } from './ItemRow';
import { INDENTATION_OFFSET } from './constants';
import { trackEvent } from '../../../services/reporting-service';
import { getExtraMetadataForDefinitionType } from '../definitions/definition-manager';

type Props = {
  node: NodeModel<CustomData>;
  depth: number;
  isOpen: boolean;
  parentHovered: boolean;
  onToggle: (id: NodeModel['id']) => void;
};

export const Node: React.FC<Props> = (props) => {
  const { iconName, isHidden } = props.node.data || {
    iconName: '',
    isHidden: false,
  };
  // const { id } = props.node;
  const parentHovered = props.parentHovered;
  const [nodeIsOnHover, setNodeIsOnHover] = useState(false);
  const { setSelectedComponentId, setOnHoverComponentId } =
    useEditorContextUpdater();

  const indent = props.depth * INDENTATION_OFFSET;
  //const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    trackTreeExpansion();
    props.onToggle(props.node.id);
  };

  const trackTreeExpansion = () => {
    const componentMeta = getExtraMetadataForDefinitionType(
      props.node.data?.componentType ?? '',
    );
    const name = componentMeta?.name ?? 'Unknown Component Type';

    if (props.isOpen) {
      trackEvent('COMPONENT_TREE_COLLAPSED', {
        'Component Type': name,
      });
    } else {
      trackEvent('COMPONENT_TREE_EXPANDED', {
        'Component Type': name,
      });
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (setSelectedComponentId) {
      setSelectedComponentId(props.node.id as string);
    }
  };

  const handleOnMouseEnterNode = (e: React.MouseEvent) => {
    setNodeIsOnHover(true);
    if (setOnHoverComponentId) {
      setOnHoverComponentId(props.node.id as string);
    }
  };

  const handleOnMouseLeaveNode = (e: React.MouseEvent) => {
    setNodeIsOnHover(false);
    if (setOnHoverComponentId) {
      setOnHoverComponentId(null);
    }
  };

  return (
    <div
      className={`tree-node ${nodeIsOnHover ? 'hover' : ''}`}
      style={{ paddingInlineStart: indent }}
      onMouseEnter={handleOnMouseEnterNode}
      onMouseLeave={handleOnMouseLeaveNode}
      onClick={handleClick}
    >
      <ItemRow
        node={props.node}
        isOpen={props.isOpen}
        onToggle={handleToggle}
        parentHovered={parentHovered}
        isHidden={isHidden}
        iconName={iconName}
      />
    </div>
  );
};

export const renderNodes =
  (hover: boolean) =>
  (node: any, { depth, isOpen, onToggle }: any) =>
    (
      <Node
        node={node}
        depth={depth}
        isOpen={isOpen}
        parentHovered={hover}
        onToggle={onToggle}
      />
    );
