import { useContext } from 'react';
import { EditorContextState, EditorContextUpdater } from './EditorContext';

// context consumer hook
export const useEditorContextState = () => {
  // get the context
  const context = useContext(EditorContextState);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useEditorContextState was used outside of its Provider');
  }

  return context;
};

// context consumer hook
export const useEditorContextUpdater = () => {
  // get the context
  const context = useContext(EditorContextUpdater);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useEditorContextUpdater was used outside of its Provider');
  }

  return context;
};
