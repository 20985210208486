import React, { Component } from 'react';

export default class TemplateSelectorField extends Component {
  constructor(props) {
    super(props);
    this.state = { showTemplates: false };
  }

  onChange = async (id, defaultPosition, templateName) => {
    if (id === '') {
      this.setState({ showTemplates: true });
    } else {
      const template = await import(`../../../../assets/templates/${id}.json`);
      this.props.onValueChange(
        id,
        template.default,
        defaultPosition,
        templateName,
      );
    }
  };

  render() {
    const { value, templates } = this.props;
    return (
      <div className="template-selector">
        <span className="card-text title">
          How do you want to create your message?
        </span>
        <div className="top-padded cards">
          {templates.map((template) => {
            if (template.type === undefined) {
              return this.templateCard(template);
            }
          })}
        </div>
        <div
          className={
            this.state.showTemplates
              ? 'template-sections visible'
              : 'template-sections'
          }
        >
          <hr />
          <div className="card-text title">
            Choose from one of the templates below.
          </div>
          <div className="templates-section">
            <div className="template-section">
              <span className="card-text section-title">
                Welcome, Announcements, and Promotions
              </span>
              <div className="top-padded cards">
                {templates.map((template) => {
                  if (template.type === 'message') {
                    return this.templateCard(template);
                  }
                })}
              </div>
            </div>
            <div className="template-section">
              <span className="card-text section-title">Banners</span>
              <div className="top-padded cards">
                {templates.map((template) => {
                  if (template.type === 'banner') {
                    return this.templateCard(template);
                  }
                })}
              </div>
            </div>
            <div className="template-section">
              <span className="card-text section-title">Surveys</span>
              <div className="top-padded cards">
                {templates.map((template) => {
                  if (template.type === 'survey') {
                    return this.templateCard(template);
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  templateCard = (template) => {
    return (
      <div
        data-testid={`${template.id}`}
        className={
          template.id === '' && this.state.showTemplates
            ? 'item-card-container selected'
            : 'item-card-container'
        }
        key={template.id}
        onClick={() =>
          this.onChange(template.id, template.position, template.name)
        }
      >
        <div className="preview">
          <div className="container">
            <object
              className="illustration"
              data={`data:image/svg+xml;base64,${template.image}`}
            >
              Template
            </object>
          </div>
        </div>
        <div className="overlay"></div>
        <div className="body padding">
          <div className="underlay">
            <div className="details">
              <p className="title">{template.name}</p>
              <p className="subtitle">{template.description}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
