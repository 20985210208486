import React, { Component } from 'react';

export default class TemplateStyleTextField extends Component {
  onChange = (e) => {
    this.props.onStyleChange('text', e.target.name, e.target.value);
  };

  getTextStyleFromName = (name) => {
    const configuration = this.props.configuration;
    var textStyleValue = '-';
    configuration.style.fonts.forEach((textStyle) => {
      if (textStyle.name === name) {
        textStyleValue = textStyle.size;
      }
    });
    return textStyleValue;
  };

  render() {
    const { textStyles, configuration } = this.props;

    return (
      <div>
        {Object.keys(textStyles).map((textStyle) => {
          return (
            <div className="field" key={textStyle}>
              <div className="input-field-title">{textStyle} Text</div>
              <div className="input-field">
                <div className="preview-field">
                  <div className="preview">
                    <div className="bubble">
                      {this.getTextStyleFromName(
                        textStyles[textStyle] === ''
                          ? textStyle
                          : textStyles[textStyle],
                      )}
                    </div>
                  </div>
                  <select
                    name={textStyle}
                    defaultValue={textStyle}
                    onChange={this.onChange}
                    required
                  >
                    <option value=""></option>
                    {configuration.style.fonts.map((configTextStyle) => {
                      return (
                        <option
                          key={configTextStyle.name}
                          value={configTextStyle.name}
                        >
                          {configTextStyle.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
