import React, { Component, FormEvent } from 'react';
import { GenericKeyValue } from '../../../types/common';

const showError = () => {
  return (
    <span className="error">
      {' '}
      The color name already exists. Please try again{' '}
    </span>
  );
};

interface ColorEditorProps {
  saveColor: (color: GenericKeyValue, index?: number) => void;
  cancel: () => void;
  removeColor?: (index: number) => void;
  color?: GenericKeyValue;
  id?: number;
  error?: boolean;
  readOnly?: boolean;
}

interface ColorEditorState {
  name: string;
  value: string;
}

class ColorEditor extends Component<ColorEditorProps, ColorEditorState> {
  constructor(props: ColorEditorProps) {
    super(props);
    const { color } = this.props;
    if (color) {
      this.state = { name: color.name, value: color.value };
    } else {
      this.state = { name: '', value: '' };
    }
  }

  removeColor = () => {
    const { id, removeColor } = this.props;
    if (removeColor && id) {
      removeColor(id);
    }
  };

  saveColor = (e: FormEvent) => {
    const { name, value } = this.state;
    const { id, saveColor } = this.props;
    saveColor({ name: name, value: value.trim() }, id);
    e.preventDefault();
  };

  render() {
    const { id, error } = this.props;
    const { name, value } = this.state;

    return (
      <form className="inline-form padded mt-0" onSubmit={this.saveColor}>
        <div className="field">
          <div className="input-field-title">Name</div>
          <div className="input-field">
            <input
              type="text"
              value={name}
              onChange={(e) => this.setState({ name: e.target.value })}
              required
            />
          </div>
        </div>
        <div className="field">
          <div className="input-field-title">Color</div>
          <div className="input-field">
            <input
              type="text"
              value={value}
              placeholder="Enter a hexadecimal color code (e.g. #FF0000)"
              pattern="^#[0-9a-fA-F]{8}$|#[0-9a-fA-F]{6}$"
              onChange={(e) => this.setState({ value: e.target.value })}
              title="Only hex colors with the following patterns are supported #000000 and #1A000000"
              required
            />
          </div>
        </div>
        <div className="field last">
          {error && showError()}
          <div className="input-field-title">&nbsp;</div>
          <div className="input-field button-field">
            <button
              className={`button primary ${
                this.props.readOnly ? 'disabled' : ''
              }`}
              type="submit"
              disabled={this.props.readOnly}
            >
              Save
            </button>
            <button className="button" onClick={this.props.cancel}>
              Cancel
            </button>
            {id != null && (
              <button
                className={`button delete ${
                  this.props.readOnly ? 'disabled' : ''
                }`}
                onClick={this.removeColor}
                disabled={this.props.readOnly}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default ColorEditor;
