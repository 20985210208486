import { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Select } from '../components/Select';
import {
  getDefinitionType,
  getExtraMetadataForDefinitionType,
  buildGroupedOptions,
} from '../definitions/definition-manager';
import { ComponentOption as Option } from '../components/Select/ComponentOption';
import { ActionMeta, SingleValue } from 'react-select';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus2.svg';
import { refreshComponentIds } from '../../../utilities/TemplateUtilities';

import CloseButton from '../definitions/blocks/close-button.json';
import SingleButton from '../definitions/blocks/single-button.json';
import TwoButtons from '../definitions/blocks/two-buttons.json';
import RoundedImage from '../definitions/blocks/rounded-image.json';
import MessageFrame from '../definitions/blocks/message-frame.json';
import CSATNumbers from '../definitions/blocks/csat-numbers.json';
import CSATEmojis from '../definitions/blocks/csat-emojis.json';
import FiveOptionSurvey from '../definitions/blocks/five-option-survey.json';
import TwoOptionSurvey from '../definitions/blocks/two-option-survey.json';
import { trackEvent } from '../../../services/reporting-service';

type AddComponentFieldProps = {
  isDisabled: boolean;
  canPerformAction: boolean;
  onChange: (newComponent: any) => void;
  value?: any;
  clearOnAdd?: boolean;
};

type AddComponentFieldState = {
  selectedComponent: string;
};

export default class AddComponentField extends Component<
  AddComponentFieldProps,
  AddComponentFieldState
> {
  constructor(props: any) {
    super(props);
    const { value } = props;
    this.state = { selectedComponent: value ? value : '' };
  }

  onComponentChange = (event: any) => {
    this.setState({ selectedComponent: event.target.value });
  };

  // TODO: Improve SelectOption typing addition here
  onChangeWrapper = (option: SingleValue<any>, _: ActionMeta<any>) => {
    this.onComponentChange({ target: { value: option ? option.value : '' } });
  };

  onAddComponent = () => {
    const { onChange, clearOnAdd } = this.props;
    if (this.state.selectedComponent !== '') {
      var extraDefinition = getExtraMetadataForDefinitionType(
        this.state.selectedComponent,
      );
      if (extraDefinition?.type !== 'block') {
        var componentType = getDefinitionType(this.state.selectedComponent);
        var newComponent: any = {
          type: this.state.selectedComponent,
          gist: {
            id: uuidv4(),
            designer: true,
          },
        };
        if (componentType != null && componentType.components) {
          newComponent.components = [];
        }
        trackEvent('MESSAGE_COMPONENT_ADDED', {
          'Component Type': this.state.selectedComponent
            ? getExtraMetadataForDefinitionType(this.state.selectedComponent)
                ?.name
            : 'Unknown Component Type',
        });
      } else {
        var newComponent: any;
        switch (extraDefinition?.id) {
          case 'messageFrame':
            newComponent = this.fetchComponentCopyOf(MessageFrame);
            break;
          case 'closeButton':
            newComponent = this.fetchComponentCopyOf(CloseButton);
            break;
          case 'singleButton':
            newComponent = this.fetchComponentCopyOf(SingleButton);
            break;
          case 'twoButtonRow':
            newComponent = this.fetchComponentCopyOf(TwoButtons);
            break;
          case 'roundedImage':
            newComponent = this.fetchComponentCopyOf(RoundedImage);
            break;
          case 'csatSurveyNumbers':
            newComponent = this.fetchComponentCopyOf(CSATNumbers);
            break;
          case 'csatSurveyEmojis':
            newComponent = this.fetchComponentCopyOf(CSATEmojis);
            break;
          case 'fiveOptionSurvey':
            newComponent = this.fetchComponentCopyOf(FiveOptionSurvey);
            break;
          case 'twoOptionSurvey':
            newComponent = this.fetchComponentCopyOf(TwoOptionSurvey);
            break;
        }
        trackEvent('MESSAGE_COMPONENT_ADDED', {
          'Component Type': extraDefinition?.id ?? 'Unknown Component Type',
        });
      }
      onChange(refreshComponentIds(newComponent));
      if (clearOnAdd) {
        this.setState({ selectedComponent: '' });
      }
    }
  };

  fetchComponentCopyOf = (section: any) => {
    return JSON.parse(JSON.stringify(section));
  };

  render() {
    const { canPerformAction, isDisabled } = this.props;
    const { selectedComponent } = this.state;

    return (
      <div className="new-selector">
        <div className="select-container">
          <Select
            data-testid="components-list"
            value={
              selectedComponent !== ''
                ? {
                    label:
                      getExtraMetadataForDefinitionType(selectedComponent)
                        ?.name,
                  }
                : null
            }
            isDisabled={!canPerformAction || isDisabled}
            isClearable={true}
            options={buildGroupedOptions()}
            placeholder="Search components..."
            components={{ Option }}
            onChange={this.onChangeWrapper}
          />
        </div>
        <button
          data-testid="add-component-button"
          className="button"
          onClick={this.onAddComponent}
          disabled={isDisabled || selectedComponent === ''}
        >
          <PlusIcon />
          Add component
        </button>
      </div>
    );
  }
}
