import { AuthenticatedNetworkInstance } from './base-network';

export async function fileUpload(fileName, file) {
  try {
    var response = await AuthenticatedNetworkInstance().post(`/api/v1/upload`, {
      fileName: fileName,
      file: file,
    });
    return response;
  } catch (error) {
    return error.response;
  }
}
