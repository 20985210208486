import React, { Component } from 'react';
import { Select } from '../components/Select';
import { ColorOption as Option } from '../components/Select/ColorOption';
import { ColorSingleValue as SingleValue } from '../components/Select/ColorSingleValue';

export default class ColorField extends Component {
  constructor(props) {
    super(props);
    const { value } = this.props;
    this.state = { internalValue: this.getDefaultValue(value) };
  }

  // Returns the first color or null
  findFirstColorValue = () => {
    const configuration = this.props.configuration;
    const textColor = configuration?.style?.colors.filter(
      (value) => value.name === 'Text',
    );
    const firstColor = textColor[0]
      ? textColor[0]
      : configuration?.style?.colors[0]
      ? configuration?.style?.colors[0]
      : null;
    return firstColor
      ? {
          value: firstColor.value,
          label: firstColor.name,
          description: firstColor.value,
        }
      : null;
  };

  getColorFromName = (name) => {
    const configuration = this.props.configuration;
    var hexColor = '';
    configuration.style.colors.forEach((color) => {
      if (color.name === name) {
        hexColor = color.value;
      }
    });
    return hexColor;
  };

  getDefaultValue = (value) => {
    const { defaultsToFirstOption, onChange } = this.props;
    const colorValueFromConfig = this.getColorFromName(value);
    // Color already selected
    if (colorValueFromConfig) {
      return {
        value: colorValueFromConfig,
        label: value,
        description: colorValueFromConfig,
      };
    }
    // Definition points to default to first option
    if (defaultsToFirstOption) {
      const firstValue = this.findFirstColorValue();
      if (firstValue) {
        onChange({ target: { value: firstValue ? firstValue.label : '' } });
        return firstValue;
      }
    }
    return null;
  };

  onChangeWrapper = (option) => {
    const { onChange } = this.props;
    this.setState({ internalValue: option });
    onChange({ target: { value: option ? option.label : '' } });
  };

  render() {
    const { configuration } = this.props;
    const { internalValue } = this.state;

    return (
      <Select
        value={internalValue}
        isClearable={true}
        options={configuration.style.colors.map((configColor) => ({
          value: configColor.value,
          label: configColor.name,
          description: configColor.value,
        }))}
        components={{ SingleValue, Option }}
        placeholder="Select a color..."
        onChange={this.onChangeWrapper}
      />
    );
  }
}
