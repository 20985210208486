import React, { Component } from 'react';
import { Select } from '../components/Select';

const ActionBehaviours = {
  SYSTEM: 'system',
  NATIVE: 'native',
  CHANGE_MESSAGE: 'retain',
  GIST_CLOSE: 'gist://close',
  GIST_LOAD_PAGE: 'gist://loadPage?url=',
  EDITOR_CHANGE_MESSAGE: 'changeMessage',
  EDITOR_WEBSITE_SAME_TAB: 'websiteSameTab',
  EDITOR_SYSTEM: 'system',
  EDITOR_CLOSE_MESSAGE: 'close',
  EDITOR_APPLICATION: 'application',
};

const GistActionProperties = {
  TRACKABLE: 'trackable=true',
};

export default class ActionBehaviourField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorBehaviour: this.findActionType(props.action),
      trackable: false,
      shouldTrack: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    var messageList = [];
    if (props.messages) {
      messageList = props.messages.map((message) => ({
        value: message.id,
        label: message.name,
      }));
    }
    return { messageList: messageList };
  }

  getDefaultValue = (value) => {
    if (value) {
      return this.state.messageList.find((message) => message.value === value);
    } else if (this.state.messageList.length > 0) {
      this.actionChange(this.state.messageList[0].value);
      return this.state.messageList[0];
    }
  };

  findActionType = (action) => {
    const { behaviour } = this.props;
    if (behaviour === ActionBehaviours.CHANGE_MESSAGE) {
      return ActionBehaviours.EDITOR_CHANGE_MESSAGE;
    }

    if (action) {
      try {
        var url = new URL(action);
        if (url) {
          if (url.protocol === 'http:' || url.protocol === 'https:') {
            if (behaviour === ActionBehaviours.NATIVE) {
              return ActionBehaviours.EDITOR_APPLICATION;
            } else {
              return ActionBehaviours.EDITOR_SYSTEM;
            }
          } else if (url.protocol === 'gist:') {
            var gistAction = url.href.replace('gist://', '').split('?')[0];
            switch (gistAction) {
              case 'close':
                var shouldTrack = false;
                if (url.href.includes(GistActionProperties.TRACKABLE)) {
                  shouldTrack = true;
                }
                this.setState({ trackable: true, shouldTrack: shouldTrack });
                return ActionBehaviours.EDITOR_CLOSE_MESSAGE;
              case 'showMessage':
                return ActionBehaviours.EDITOR_APPLICATION;
              case 'loadPage':
                return ActionBehaviours.EDITOR_WEBSITE_SAME_TAB;
            }
          } else {
            if (behaviour === ActionBehaviours.NATIVE) {
              return ActionBehaviours.EDITOR_APPLICATION;
            } else {
              return ActionBehaviours.EDITOR_SYSTEM;
            }
          }
        }
      } catch {
        if (behaviour === ActionBehaviours.SYSTEM) {
          return ActionBehaviours.EDITOR_SYSTEM;
        }
      }
      return ActionBehaviours.EDITOR_APPLICATION;
    }
    return '';
  };

  changeBehaviour = (behaviour) => {
    var trackable = false;
    switch (behaviour) {
      case ActionBehaviours.EDITOR_CLOSE_MESSAGE:
        trackable = true;
        this.props.onChange(
          ActionBehaviours.GIST_CLOSE,
          ActionBehaviours.NATIVE,
        );
        break;
      case ActionBehaviours.EDITOR_WEBSITE_SAME_TAB:
        this.setState({ helpText: 'https://google.com' });
        this.props.onChange('', ActionBehaviours.NATIVE);
        break;
      case ActionBehaviours.EDITOR_SYSTEM:
        this.setState({ helpText: 'https://google.com or app://link' });
        this.props.onChange('', ActionBehaviours.SYSTEM);
        break;
      case ActionBehaviours.EDITOR_CHANGE_MESSAGE:
        this.props.onChange('', ActionBehaviours.CHANGE_MESSAGE);
        break;
      case ActionBehaviours.EDITOR_APPLICATION:
        this.setState({
          helpText: 'An action handled inside your application.',
        });
        this.props.onChange('', ActionBehaviours.NATIVE);
        break;
      case '':
        this.props.onChange('', '');
        break;
    }
    this.setState({ editorBehaviour: behaviour, trackable: trackable });
  };

  actionChange = (action) => {
    const { behaviour } = this.props;
    if (
      this.state.editorBehaviour === ActionBehaviours.EDITOR_WEBSITE_SAME_TAB
    ) {
      action = ActionBehaviours.GIST_LOAD_PAGE + action;
    }
    this.props.onChange(action, behaviour);
  };

  displayValue = () => {
    if (this.props.action) {
      return this.props.action.replace(ActionBehaviours.GIST_LOAD_PAGE, '');
    } else {
      return '';
    }
  };

  render() {
    const { helpText, messageList, trackable, shouldTrack } = this.state;
    const { action, behaviour, required } = this.props;
    return (
      <div className="behaviour-field">
        <select
          className="behavior"
          defaultValue={this.findActionType(action)}
          onChange={(e) => this.changeBehaviour(e.target.value)}
          required={required}
        >
          <option value="">Select behaviour</option>
          <option value={ActionBehaviours.EDITOR_CLOSE_MESSAGE}>
            Close message
          </option>
          <option value={ActionBehaviours.EDITOR_WEBSITE_SAME_TAB}>
            Link to web page
          </option>
          <option value={ActionBehaviours.EDITOR_SYSTEM}>
            Link to web page (new tab) or mobile app (deep link)
          </option>
          <option value={ActionBehaviours.EDITOR_CHANGE_MESSAGE}>
            Show another message
          </option>
          <option value={ActionBehaviours.EDITOR_APPLICATION}>
            Custom action
          </option>
        </select>
        {!action?.includes(ActionBehaviours.GIST_CLOSE) &&
        behaviour !== ActionBehaviours.CHANGE_MESSAGE &&
        behaviour !== '' &&
        behaviour ? (
          <input
            className="action"
            type="text"
            placeholder={helpText}
            value={this.displayValue()}
            onChange={(e) => this.actionChange(e.target.value)}
            required={required}
          />
        ) : (
          <span />
        )}
        {trackable && (
          <div className="checkbox">
            <input
              type="checkbox"
              checked={shouldTrack}
              onChange={(e) => {
                if (e.target.checked) {
                  this.props.onChange(
                    `${ActionBehaviours.GIST_CLOSE}?${GistActionProperties.TRACKABLE}`,
                    ActionBehaviours.NATIVE,
                  );
                } else {
                  this.props.onChange(
                    ActionBehaviours.GIST_CLOSE,
                    ActionBehaviours.NATIVE,
                  );
                }
              }}
            />
            <label>Track clicks</label>
          </div>
        )}
        {
          // Add message dropdown
          behaviour === ActionBehaviours.CHANGE_MESSAGE ? (
            <Select
              isClearable={false}
              options={messageList}
              value={this.getDefaultValue(this.displayValue())}
              placeholder="Select a message"
              onChange={(message) => {
                if (message) {
                  this.actionChange(message.value);
                }
              }}
            />
          ) : (
            <span />
          )
        }
      </div>
    );
  }
}
