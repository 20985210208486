import { NodeModel } from '@minoru/react-dnd-treeview';
import { CustomData } from '../views/builder/Layers/types';
import { findComponentById } from './template';
import { isEmpty } from 'lodash';

export interface DragObjectType {
  dragSource?: NodeModel<CustomData>;
  dropTargetId: number | string;
  dropTarget?: NodeModel<CustomData>;
}

/* 
  A component is droppable if it has children via component, components or binary condition 
(true or false nodes containing the children, or if it has a specific type
*/

interface DroppableNode {
  component?: any;
  components?: any[];
  type: string;
}

/* Util from the @minoru/react-dnd-treeview moved into out codebase instead of importing, because that broke the tests */
const isAncestor = (
  tree: NodeModel[],
  sourceId: NodeModel['id'],
  targetId: NodeModel['id'],
): boolean => {
  if (targetId === 0) {
    return false;
  }

  const targetNode = tree.find((node) => node.id === targetId);

  if (targetNode === undefined) {
    return false;
  }

  if (targetNode.parent === sourceId) {
    return true;
  }

  return isAncestor(tree, sourceId, targetNode.parent);
};

export const isComponentDroppable = (component: DroppableNode): boolean => {
  // Check for components array
  if ('components' in component) {
    return true;
  }

  // Check for specific component types
  return !!(
    component.type === 'blockWidget' ||
    component.type === 'actionWidget' ||
    component.type === 'fixedListWidget' ||
    component.type === 'fixedHorizontalListWidget' ||
    component.type === 'fixedHorizontalListScrollWidget' ||
    component.type === 'listWidget' ||
    component.type === 'fixedGridWidget' ||
    component.type === 'gridWidget' ||
    // This last piece is added to have an expand button on the conditional widget
    // the canDrop function will prevent dropping in this case
    component.type === 'conditionalWidget'
  );
};

// Keeping this method to help debugging possible future issues
export const canDropDebug = (
  tree: NodeModel<CustomData>[],
  { dragSource, dropTargetId, dropTarget }: DragObjectType,
  template?: any,
) => {
  const can = canDrop(
    tree,
    {
      dragSource,
      dropTargetId,
      dropTarget,
    },
    template,
  );
  // TODO: Commented is a possible approach to change the cursor when dragging over a forbidden area
  // console.log(
  //   `canDrop(${dragSource?.text} - ${dragSource?.id})(${dragSource?.data?.componentType}) -> into (${dropTarget?.text} - ${dropTarget?.id})(${dropTarget?.data?.componentType}) = ${can}}`,
  // );
  // const treeNode = document.getElementsByClassName('dragging-source');
  // console.log(treeNode, can);
  // if (treeNode && treeNode[0] && !can) {
  //   treeNode[0].classList.add('forbidden');
  // } else {
  //   treeNode[0].classList.remove('forbidden');
  // }
  return can;
};

/* Utilities for the Drag and Drop functionality within the Layers Panel */
export const canDrop = (
  currentTree: NodeModel<CustomData>[],
  { dragSource, dropTargetId, dropTarget }: DragObjectType,
  template?: any,
) => {
  // If dropTarget is undefined, then it's a root drop, which is always allowed
  if (!dropTarget) {
    return true;
  }

  if (!dragSource) {
    return false;
  }

  if (dragSource?.id === dropTargetId) {
    return false;
  }

  // Prevent dropping on a conditionalWidget always
  if (dropTarget.data?.componentType === 'conditionalWidget') {
    return false;
  }

  // Dropping a parent node into their own children shold not be allowed
  if (dragSource && isAncestor(currentTree, dragSource?.id, dropTargetId)) {
    return false;
  }

  if (template) {
    const dropTargetComponent = findComponentById(
      template,
      dropTargetId as string,
    );

    if (dropTarget.data?.componentType === 'actionWidget') {
      // Check for component property
      return isEmpty(dropTargetComponent.component);
    }

    return isComponentDroppable({
      ...dropTargetComponent,
      type: dropTarget.data?.componentType,
    });
  }
  // Apply default rules if there was no match with the previous controls
  return undefined;
};

// Keeping this method to help debugging possible future issues
export const canDragDebug = (
  node: NodeModel<CustomData> | undefined,
  template?: any,
): boolean => {
  const canDragValue = canDrag(node, template);
  // console.log(
  //   `canDrag(${node?.text} - ${node?.id})(${node?.data?.componentType}) = ${canDragValue}`,
  // );
  return canDragValue;
};

/* Utilities for the Drag and drag functionality within the Layers Panel */
export const canDrag = (
  node: NodeModel<CustomData> | undefined,
  template?: any,
): boolean => {
  // If drag node is not for some reason then return false
  if (!node) {
    return false;
  }

  // There's a template loaded and is not a root node
  if (template && node.parent !== 0) {
    const parent = findComponentById(template, node.parent as string);
    // Prevent drag on a conditionalWidget always
    if (parent?.type === 'conditionalWidget') {
      return false;
    }
  }
  // Dragging all other elements is allowed
  return true;
};
