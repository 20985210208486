export const linkPointsToImage = async (url: string) => {
  if (!url) return false;

  try {
    const res = await fetch(url);
    const buff = await res.blob();
    return buff.type.startsWith('image/');
  } catch (e) {
    return false;
  }
};
