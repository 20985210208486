import '../assets/onboarding.scss';
import '../assets/gate.scss';

import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import { BrowserRouter as Router } from 'react-router-dom';

export default function LoggedOutLayout() {
  return (
    <Router>
      <p>Looks like you've been logged out, refresh the page to login again.</p>
      <ReactNotifications />
    </Router>
  );
}
