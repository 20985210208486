import React from 'react';
import { BaseLayoutProps } from '../../../../../types/common';
import { FieldsBuilder } from '../FieldsBuilder';

export const TextLayout = ({
  component,
  definition,
  configuration,
  fields,
  updateComponentField,
  getComponentField,
  messages,
  canPerformActions,
}: BaseLayoutProps) => (
  <div className="text-fields fields">
    <FieldsBuilder
      component={component}
      definition={definition}
      configuration={configuration}
      fields={fields}
      updateComponentField={updateComponentField}
      getComponentField={getComponentField}
      messages={messages}
      canPerformActions={canPerformActions}
    />
  </div>
);
