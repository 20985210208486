import React, { useState } from 'react';
import { usePopper } from 'react-popper';

type TooltipProps = {
  content: string | JSX.Element;
  children: React.ReactNode;
  isShown?: boolean;
};

const Tooltip = (props: TooltipProps) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  );
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [
        { name: 'arrow', options: { element: arrowElement, padding: 10 } },
        { name: 'offset', options: { offset: [0, 8] } },
      ],
    },
  );

  function show() {
    if (props.isShown === false) return;

    popperElement?.setAttribute('data-show', '');

    update?.();
  }

  function hide() {
    popperElement?.removeAttribute('data-show');
  }

  return (
    <span onMouseEnter={show} onMouseLeave={hide}>
      {React.cloneElement(props.children as React.ReactElement, {
        ref: setReferenceElement,
      })}
      <div
        className="tooltip"
        role="tooltip"
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {props.content}
        <div className="arrow" ref={setArrowElement} style={styles.arrow} />
      </div>
    </span>
  );
};

export default Tooltip;
