import React, { ReactElement } from 'react';
import ComponentDetailsField from './ComponentDetailsField';
import { BlockLayout } from './LayoutDefinitions/BlockLayout/BlockLayout';
import { BaseLayoutProps } from '../../../types/common';
import { TextLayout } from './LayoutDefinitions/TextLayout/TextLayout';
import { ActionLayout } from './LayoutDefinitions/ActionLayout/ActionLayout';
import { ImageLayout } from './LayoutDefinitions/ImageLayout/ImageLayout';
import { VerticalListLayout } from './LayoutDefinitions/VerticalListLayout/VerticalListLayout';
import { HorizontalListLayout } from './LayoutDefinitions/HorizontalListLayout/HorizontalListLayout';
import { GridLayout } from './LayoutDefinitions/GridLayout/GridLayout';
import { CarouselLayout } from './LayoutDefinitions/CarouselLayout/CarouselLayout';
import { IconLayout } from './LayoutDefinitions/IconLayout/IconLayout';
import { ConditionalLayout } from './LayoutDefinitions/ConditionalLayout/ConditionalLayout';
import { MarkdownLayout } from './LayoutDefinitions/MarkdownLayout/MarkdownLayout';

/* 
  This is a map with the Layout definition for each component type
  The Components in the map are the ready ones so far 
*/
const LayoutComponentsMap: any = {
  blockWidget: BlockLayout,
  textWidget: TextLayout,
  actionWidget: ActionLayout,
  imageWidget: ImageLayout,
  fixedListWidget: VerticalListLayout,
  fixedHorizontalListWidget: HorizontalListLayout,
  fixedGridWidget: GridLayout,
  fixedHorizontalListScrollWidget: CarouselLayout,
  iconWidget: IconLayout,
  conditionalWidget: ConditionalLayout,
  markdownWidget: MarkdownLayout,
};

const legacyLayout = (
  fields: any[],
  component: any,
  definition: any,
  configuration: any,
  updateComponentField: (paddingField: any[], fieldKey: string) => void,
  getComponentField: (fieldName: string) => string,
  messages: any[],
  canPerformActions: boolean,
): ReactElement => (
  <>
    <div className="fields">
      {fields.map((key) => {
        if (key === 'components' || key === 'component') {
          return null;
        }
        return (
          <ComponentDetailsField
            key={`${key}-cdf`}
            componentType={definition.type}
            fieldKey={key}
            value={component[key]}
            definition={definition[key]}
            configuration={configuration}
            updateValue={updateComponentField}
            getValue={getComponentField}
            messages={messages}
            canPerformActions={canPerformActions}
          />
        );
      })}
    </div>
    {/* The footer needs to render the add component or components footer */}
    {component['components'] && definition['components'] ? (
      <div className="footer">
        <ComponentDetailsField
          key={`${'components'}-cdf`}
          componentType={definition.type}
          fieldKey={'components'}
          value={component['components']}
          definition={definition['components']}
          configuration={configuration}
          updateValue={updateComponentField}
          getValue={getComponentField}
          messages={messages}
          canPerformActions={canPerformActions}
        />
      </div>
    ) : component['component'] && definition['component'] ? (
      <div className="footer">
        <ComponentDetailsField
          key={`${'component'}-cdf`}
          componentType={definition.type}
          fieldKey={'component'}
          value={component['component']}
          definition={definition['component']}
          configuration={configuration}
          updateValue={updateComponentField}
          getValue={getComponentField}
          messages={messages}
          canPerformActions={canPerformActions}
        />
      </div>
    ) : null}
  </>
);

type ComponentLayoutProps = {
  componentType: string;
} & BaseLayoutProps;

export const ComponentLayout = ({
  componentType,
  fields,
  definition,
  component,
  configuration,
  updateComponentField,
  getComponentField,
  messages,
  canPerformActions,
}: ComponentLayoutProps) => {
  if (LayoutComponentsMap[componentType]) {
    const Layout = LayoutComponentsMap[componentType];
    return (
      <Layout
        component={component}
        definition={definition}
        configuration={configuration}
        updateComponentField={updateComponentField}
        getComponentField={getComponentField}
        messages={messages}
        canPerformActions={canPerformActions}
        fields={fields}
      />
    );
  }
  return legacyLayout(
    fields,
    component,
    definition,
    configuration,
    updateComponentField,
    getComponentField,
    messages,
    canPerformActions,
  );
};
