export const settings = {
  GIST_RENDERER_ENDPOINT: 'https://renderer.gist.build/2.0',
  GIST_API_ENDPOINT: 'https://api.dev.gist.build',
  ENGINE_API_ENDPOINT: 'https://engine.api.dev.gist.build',
  CAPTCHA_CLIENT_KEY: '6Le7K9gUAAAAAM9vhYwM8YCUHx2wtg32j_TPyUZE',
  // To avoid reporting issues in when running the project locally, change the value of the GIST_WEB_ENV variable to 'local'
  GIST_WEB_ENV: 'dev',
  GIST_WEB_ORGANIZATION_ID: '5a6f01a7-6847-4554-8e71-3610a27ed201',
  CUSTOMER_IO_SITE_ID: 'a5ec106751ef4b34a0b9',
  MIXPANEL_TOKEN: '8aaeab92cef9b792a5304f160bc47690',
  SENTRY_DSN:
    'https://53049f126a35c78b6695a0edbef9f73d@o38230.ingest.sentry.io/4506747219148800',
};
