import { Store } from 'react-notifications-component';

export function showSuccessNotification(
  title,
  message,
  position = 'bottom-right',
) {
  Store.addNotification({
    title: title,
    message: message === undefined || message === '' ? ' ' : message,
    type: 'success',
    container: position,
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
}

export function showErrorResponseNotification(
  title,
  message,
  response,
  position = 'bottom-right',
) {
  var detailedMessages = createDetailedErrorMessage(message, response);
  showErrorNotification(title, detailedMessages, position);
}

export function createDetailedErrorMessage(message, response) {
  var detailedMessages = message;
  if (response && response.status) {
    var responseData = response.data && `: ${JSON.stringify(response.data)}`;
    switch (response.status) {
      case 500:
        detailedMessages += `, please contact customer care and quote the following code: ${response.data.traceId}`;
        break;
      case 409:
        detailedMessages += `, duplicate id / name.`;
        break;
      case 400:
        var validationErrors = '';
        var objectKeys = Object.keys(response.data);
        if (response.data && objectKeys) {
          objectKeys.forEach((key) => {
            validationErrors += `${response.data[key]} `;
          });
        } else {
          validationErrors = responseData;
        }
        detailedMessages += `, validation error: ${validationErrors}`;
        break;
      default:
        detailedMessages += `, error: ${response.status} from server ${responseData}`;
        break;
    }
  }
  return detailedMessages;
}

export function showErrorNotification(
  title,
  message,
  position = 'bottom-right',
) {
  Store.addNotification({
    title: title,
    message: message === undefined || message === '' ? ' ' : message,
    type: 'danger',
    container: position,
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 10000,
      onScreen: true,
    },
  });
}
