import React, { Component } from 'react';
import { getMessages } from '../../../services/message-service';
import {
  getFolders,
  linkMessageToFolder,
  linkFolderToParentFolder,
  deleteFolder,
} from '../../../services/folders-service';
import { showErrorResponseNotification } from '../../common/notifications';
import LivePreview from '../../livePreview/LivePreview';
import loading from '../../../assets/images/live-preview-loader.svg';
import { Context } from './dnd/Context';
import { Droppable } from './dnd/Droppable';
import { Draggable } from './dnd/Draggable';
import FolderDetails from './FolderDetails';

class MessageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folders: [],
      messages: [],
      messagesLoaded: [],
      combinedMessageIds: [],
      currentFolder: null,
      loaded: false,
    };
  }

  componentDidMount() {
    this.loadScreenData();
  }

  loadMessages = async () => {
    var messages = await getMessages();
    if (messages && messages.status === 200) {
      this.setState({ messages: messages.data });
    } else {
      showErrorResponseNotification(
        'Error loading messages',
        'There was a problem loading the messages',
        messages,
      );
    }
  };

  loadFolders = async () => {
    var folders = await getFolders();
    if (folders && folders.status === 200) {
      this.setState({ folders: folders.data });
    } else {
      showErrorResponseNotification(
        'Error loading folders',
        'There was a problem loading the folders',
        folders,
      );
    }
  };

  loadScreenData = async () => {
    await this.loadFolders();
    await this.loadMessages();
    await this.checkFoldersAndDeleteIfEmpty();
    this.setState({ loaded: true });
  };

  messageSelected = (messageId) => {
    this.props.selectAction(messageId);
  };

  folderSelected = (folder) => {
    this.setState({ currentFolder: folder }, () => {
      if (this.props.updateCurrentFolderId != null) {
        if (this.state.currentFolder == null) {
          this.props.updateCurrentFolderId(null);
        } else {
          this.props.updateCurrentFolderId(this.state.currentFolder.id);
        }
      }
    });
  };

  onMessageLoaded = (messageId) => {
    var messagesLoaded = this.state.messagesLoaded;
    messagesLoaded.push(messageId);
    this.setState({ messagesLoaded: messagesLoaded });
  };

  toggleFolderDetails = async () => {
    this.setState(
      { showFolderDetails: !this.state.showFolderDetails },
      async () => {
        if (!this.state.showFolderDetails) {
          await this.loadScreenData();
          this.setState({ combinedMessageIds: [] });
        }
      },
    );
  };

  handleFolderClick = (folderId) => {
    this.folderSelected(this.findFolderById(folderId));
  };

  handleMessageClick = (messageId) => {
    this.messageSelected(messageId);
  };

  handleDragEnd = async (event) => {
    if (event.over.id !== event.active.id) {
      if (event.over.id.startsWith('folder')) {
        var activeId = event.active.id.split('_').pop();
        var overFolderId = event.over.id.split('_').pop();

        if (event.over.id === 'folder_back') {
          if (event.active.id.startsWith('folder')) {
            await linkFolderToParentFolder(
              activeId,
              this.state.currentFolder.parentId,
            );
          } else {
            await linkMessageToFolder(
              activeId,
              this.state.currentFolder.parentId,
            );
          }
        } else {
          if (event.active.id.startsWith('folder')) {
            await linkFolderToParentFolder(activeId, overFolderId);
          } else {
            await linkMessageToFolder(activeId, overFolderId);
          }
        }
        await this.loadScreenData();
      }

      if (
        event.over.id.startsWith('message') &&
        event.active.id.startsWith('message')
      ) {
        var activeId = event.active.id.split('_').pop();
        var overId = event.over.id.split('_').pop();
        this.setState({
          combinedMessageIds: [activeId, overId],
          showFolderDetails: true,
        });
      }
    }
  };

  checkFoldersAndDeleteIfEmpty = async () => {
    await this.state.folders.forEach(async (folder) => {
      var messagesFolder = this.state.messages.filter(
        (message) => message.folderId === folder.id,
      );
      var foldersParent = this.state.folders.filter(
        (fp) => fp.parentId === folder.id,
      );
      if (messagesFolder.length == 0 && foldersParent.length == 0) {
        await deleteFolder(folder.id);
        await this.loadScreenData();
      }
    });
  };

  findFolderById = (folderId) => {
    var foundFolders = this.state.folders.filter(
      (folder) => folder.id === folderId,
    );
    if (foundFolders.length > 0) {
      return foundFolders[0];
    } else {
      return null;
    }
  };

  render() {
    const {
      messages,
      folders,
      messagesLoaded,
      currentFolder,
      combinedMessageIds,
      showFolderDetails,
      loaded,
    } = this.state;

    return (
      <div className="message-list">
        <Context onDragEnd={this.handleDragEnd}>
          <div className="cards">
            {currentFolder != null && (
              <Droppable id="folder_back">
                <div
                  className="item-card-container"
                  onClick={() => this.handleFolderClick(currentFolder.parentId)}
                >
                  <div className="preview">
                    <div className="container">
                      <p className="icon">...</p>
                    </div>
                  </div>
                  <div className="overlay"></div>
                  <div className="body padding">
                    <div className="underlay">
                      <div className="details">
                        <p className="title">Go Back</p>
                        <p className="subtitle">Folder</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Droppable>
            )}
            {folders
              .filter(
                (folder) =>
                  (currentFolder != null &&
                    folder.parentId === currentFolder.id) ||
                  (currentFolder == null && folder.parentId === null),
              )
              .map((folder, index) => (
                <Draggable id={`folder_${folder.id}`} key={index}>
                  <Droppable id={`folder_${folder.id}`}>
                    <div
                      className="item-card-container"
                      onClick={() => this.handleFolderClick(folder.id)}
                    >
                      <div className="preview">
                        <div className="container">
                          <p className="icon">{folder.icon}</p>
                        </div>
                      </div>
                      <div className="overlay"></div>
                      <div className="body padding">
                        <div className="underlay">
                          <div className="details">
                            <p className="title">{folder.name}</p>
                            <p className="subtitle">Folder</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Droppable>
                </Draggable>
              ))}
            {messages
              .filter(
                (message) =>
                  (currentFolder != null &&
                    message.folderId === currentFolder.id) ||
                  (currentFolder == null && message.folderId === null),
              )
              .map((message, index) => (
                <Draggable id={`message_${message.id}`} key={index}>
                  <Droppable id={`message_${message.id}`}>
                    <div
                      className="item-card-container"
                      onClick={() => this.handleMessageClick(message.id)}
                    >
                      <div className="preview">
                        <div className="container">
                          {!messagesLoaded.includes(message.id) && (
                            <img
                              src={loading}
                              alt={`Message preview ${message.id} loading`}
                              className="loading"
                            />
                          )}
                          <LivePreview
                            messageId={message.id}
                            draft={false}
                            livePreview={false}
                            loaded={() => {
                              this.onMessageLoaded(message.id);
                            }}
                          />
                        </div>
                      </div>
                      <div className="overlay"></div>
                      <div className="body padding">
                        <div className="underlay">
                          <div className="details">
                            <p className="title">{message.name}</p>
                            <p className="subtitle">{message.id}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Droppable>
                </Draggable>
              ))}
          </div>
        </Context>
        <div>
          {messages.length === 0 && folders.length === 0 && loaded && (
            <div className="list">
              <div className="item-list-container notice">
                <p className="message">{this.props.emptyListMessage}</p>
              </div>
            </div>
          )}
        </div>
        <div className={showFolderDetails ? 'modal visible' : 'modal'}>
          <FolderDetails
            isNew={true}
            messageIds={combinedMessageIds}
            currentFolder={currentFolder}
            onDismissOverlay={() => this.toggleFolderDetails()}
          />
        </div>
      </div>
    );
  }
}

export default MessageList;
