import React from 'react';
import ComponentDetailsField from '../views/builder/components/ComponentDetailsField';

export const GetComponentDetailsForKey = (
  fieldKey: string,
  component: any,
  definition: any,
  configuration: any,
  updateComponentField: any,
  getComponentField: any,
  messages: any,
  canPerformActions: any,
  containerClassName?: string,
  inputClassName?: string,
  index?: number,
) => (
  <ComponentDetailsField
    key={`${fieldKey}-${index}-cdf`}
    componentType={definition.type}
    containerClassName={containerClassName}
    inputClassName={inputClassName}
    fieldKey={fieldKey}
    value={component[fieldKey]}
    definition={definition[fieldKey]}
    configuration={configuration}
    updateValue={updateComponentField}
    getValue={getComponentField}
    messages={messages}
    canPerformActions={canPerformActions}
    style={{ display: 'flex', flex: `${definition[fieldKey]['flex']}` }}
  />
);

export const getGroupEndIndex = (
  definition: any,
  fields: any[],
  groupStartIndex: number,
): number => {
  let indexToReturn = 0;
  /* 
    Using some to be able to break the loop, which would be beneficial 
    for definitions with a large number of fields
  */
  fields
    .slice(groupStartIndex, fields.length)
    .some((key: string, index: number) => {
      if (definition[key]['group'] === 'end') {
        indexToReturn = index + groupStartIndex;
        return true;
      }
      return false;
    });
  return indexToReturn;
};

export const GetComponentGroupForIndexes = (
  groupStartIndex: number,
  groupEndIndex: number,
  fields: any[],
  component: any,
  definition: any,
  configuration: any,
  updateComponentField: (paddingField: any[], fieldKey: string) => void,
  getComponentField: (fieldName: string) => string,
  messages: any,
  canPerformActions: any,
  containerClassName?: string,
  inputClassName?: string,
) => (
  <>
    {definition[fields[groupStartIndex]]['groupName'] && (
      <div className="fields-header">
        <label> {definition[fields[groupStartIndex]]['groupName']}</label>
      </div>
    )}
    <div data-testid="fields-container" className="fields-row">
      {fields
        .slice(groupStartIndex, groupEndIndex + 1)
        .map((key: string, index: number) => {
          return GetComponentDetailsForKey(
            key,
            component,
            definition,
            configuration,
            updateComponentField,
            getComponentField,
            messages,
            canPerformActions,
            containerClassName,
            inputClassName,
            index,
          );
        })}
    </div>
  </>
);
