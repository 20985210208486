// Definitions
import Block from './block.json';
import Text from './text.json';
import Image from './image.json';
import Action from './action.json';
import Icon from './icon.json';
import FixedList from './fixed-list.json';
import FixedHorizontalList from './fixed-horizontal-list.json';
import FixedHorizontalListScrollable from './fixed-horizontal-list-scrollable.json';
import HorizontalList from './horizontal-list.json';
import HorizontalScrollList from './horizontal-list-scrollable.json';
import List from './list.json';
import Conditional from './conditional.json';
import FixedGrid from './fixed-grid.json';
import Grid from './grid.json';
import Markdown from './markdown.json';
import { GroupOption, SelectOption } from '../components/Select/types';

//TODO: We could later introduce a specific type to fully declare the widget definitions
type WidgetDefinition = {
  type: string;
  [name: string]: object | string;
};

// The method returns a valid widget definition or null if the widget type is invalid
export const getDefinitionType = (type: string): WidgetDefinition | null => {
  switch (type) {
    case 'blockWidget':
      return Block;
    case 'textWidget':
      return Text;
    case 'imageWidget':
      return Image;
    case 'actionWidget':
      return Action;
    case 'iconWidget':
      return Icon;
    case 'fixedListWidget':
      return FixedList;
    case 'fixedHorizontalListWidget':
      return FixedHorizontalList;
    case 'fixedHorizontalListScrollWidget':
      return FixedHorizontalListScrollable;
    case 'listWidget':
      return List;
    case 'horizontalListWidget':
      return HorizontalList;
    case 'horizontalListScrollWidget':
      return HorizontalScrollList;
    case 'conditionalWidget':
      return Conditional;
    case 'fixedGridWidget':
      return FixedGrid;
    case 'gridWidget':
      return Grid;
    case 'markdownWidget':
      return Markdown;
    default:
      return null;
  }
};

export type WidgetOption = {
  id: string;
  name: string;
  description: string;
  iconName: string;
  type: 'block' | 'content' | 'layout';
};

export const getExtraMetadataForDefinitionType = (
  definition: string,
): WidgetOption | null => {
  const found = getDefinitionTypes().find(
    (defOption) => defOption.id === definition,
  );
  return found ? found : null;
};

export const getDefinitionTypes = (): WidgetOption[] => {
  return [
    {
      id: 'blockWidget',
      name: 'Block',
      description: 'Group items for spacing, background color, etc.',
      iconName: 'block',
      type: 'layout',
    },
    {
      id: 'actionWidget',
      name: 'Action',
      description: 'Take an action when an element is clicked.',
      iconName: 'action',
      type: 'content',
    },
    {
      id: 'textWidget',
      name: 'Text',
      description: 'Add headings and plain text.',
      iconName: 'text',
      type: 'content',
    },
    {
      id: 'imageWidget',
      name: 'Image',
      description: 'Upload an image.',
      iconName: 'image',
      type: 'content',
    },
    {
      id: 'markdownWidget',
      name: 'Markdown',
      description: 'Add text using the markdown syntax.',
      iconName: 'markdown',
      type: 'content',
    },
    {
      id: 'fixedListWidget',
      name: 'Vertical List',
      description: 'Align a group of items in a vertical list.',
      iconName: 'vertical-list',
      type: 'layout',
    },
    {
      id: 'fixedHorizontalListWidget',
      name: 'Horizontal List',
      description: 'Align a group of items in a horizontal list.',
      iconName: 'horizontal-list',
      type: 'layout',
    },
    {
      id: 'fixedGridWidget',
      name: 'Grid',
      description: 'Align a group of items in a grid.',
      iconName: 'grid',
      type: 'layout',
    },
    {
      id: 'fixedHorizontalListScrollWidget',
      name: 'Carousel',
      description: 'Horizontally scroll a list of items.',
      iconName: 'carousel',
      type: 'layout',
    },
    {
      id: 'iconWidget',
      name: 'Icon',
      description: 'Add an icon from an icon font.',
      iconName: 'icon',
      type: 'content',
    },
    {
      id: 'conditionalWidget',
      name: 'Conditional',
      description: 'Dynamically display items based on a condition.',
      iconName: 'conditional',
      type: 'layout',
    },
    {
      id: 'messageFrame',
      name: 'Message Frame',
      description: 'Creates a message frame with rounded borders.',
      iconName: 'messageFrame',
      type: 'block',
    },
    {
      id: 'closeButton',
      name: 'Close Button',
      description: 'Creates a close button with padding around.',
      iconName: 'closeButton',
      type: 'block',
    },
    {
      id: 'singleButton',
      name: 'Button',
      description: 'Creates a rounded button with a default action.',
      iconName: 'button',
      type: 'block',
    },
    {
      id: 'twoButtonRow',
      name: 'Two Button Row',
      description: 'Creates two rounded buttons side by side.',
      iconName: 'twoButtonRow',
      type: 'block',
    },
    {
      id: 'roundedImage',
      name: 'Rounded Image',
      description: 'Creates a centered rounded image.',
      iconName: 'roundedImage',
      type: 'block',
    },
    {
      id: 'twoOptionSurvey',
      name: 'Survey with 2 options',
      description: 'Creates a survey with 2 options.',
      iconName: 'twoOptionSurvey',
      type: 'block',
    },
    {
      id: 'fiveOptionSurvey',
      name: 'Survey with 5 options',
      description: 'Creates a survey with 5 options.',
      iconName: 'fiveOptionSurvey',
      type: 'block',
    },
    {
      id: 'csatSurveyNumbers',
      name: 'CSAT Survey with Numbers',
      description: 'Creates a CSAT survey with 1 - 5 options.',
      iconName: 'csatSurveyNumbers',
      type: 'block',
    },
    {
      id: 'csatSurveyEmojis',
      name: 'CSAT Survey with Emojis',
      description: 'Creates a CSAT survey with emoji options.',
      iconName: 'csatSurveyEmojis',
      type: 'block',
    },
  ];
};

const optionToReactSelectOption = (option: WidgetOption): SelectOption => {
  return {
    value: option.id,
    label: option.name,
    description: option.description,
    iconName: option.iconName,
    type: option.type,
  };
};

// Returns a React Select compatible option, filtered by the group name
const groupDefinitionTypes = (type: 'block' | 'content' | 'layout') => {
  return getDefinitionTypes()
    .filter((def) => def.type === type)
    .map((defType) => optionToReactSelectOption(defType));
};

export const buildGroupedOptions = (): GroupOption[] => {
  return [
    // Pre-built components
    {
      label: 'Blocks',
      options: groupDefinitionTypes('block'),
    },
    {
      label: 'Content',
      options: groupDefinitionTypes('content'),
    },
    {
      label: 'Layout',
      options: groupDefinitionTypes('layout'),
    },
  ];
};
