import React, { Component } from 'react';
import {
  getConfiguration,
  updateColors,
} from '../../services/configuration-service';
import ColorsList from './components/ColorsList';
import '../../assets/editor.scss';
import { trackEvent } from '../../services/reporting-service';
import { showError, showSuccess } from '../../services/embed-service';

// TODO: Add more specific types
class ColorsListEmbed extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { configuration: { style: { colors: [] } }, fontList: [] };
  }

  componentDidMount() {
    this.getBrandConfiguration();
  }

  getBrandConfiguration = async () => {
    const response = await getConfiguration();
    if (response.status === 200) {
      this.setState({ configuration: response.data });
    } else {
      showError(
        'Error fetching brand configuration',
        'There was a problem fetching the brand configuration',
        response,
      );
    }
  };

  onColorsChange = async (colors: any) => {
    const response = await updateColors(colors);
    if (response.status === 200) {
      showSuccess('Colour configuration saved');
    } else {
      showError(
        'Error updating colors:',
        'There was a problem updating the colour configuration',
        response,
      );
    }
  };

  render() {
    const { style } = this.state.configuration;

    return (
      <ColorsList
        showHeader={false}
        colors={style.colors}
        onColorsChange={this.onColorsChange}
        readOnly={this.props.readOnly}
      />
    );
  }
}

export default ColorsListEmbed;
