import React, { Component } from 'react';

export default class TextField extends Component {
  onChange = (e) => {
    this.props.onValueChange(this.props.name, e.target.value);
  };

  render() {
    const { value, required, placeholder, disabled } = this.props;

    return (
      <input
        type="text"
        defaultValue={value}
        onChange={this.onChange}
        required={required}
        disabled={disabled}
        placeholder={placeholder ? placeholder : ''}
      />
    );
  }
}
