import React, { Component, Fragment } from 'react';
import { debounce } from 'throttle-debounce';

import { refreshComponentsIds } from '../../utilities/TemplateUtilities';
import {
  updateDraftMessageTemplate,
  updateDraftMessage,
  updateMessage,
  getMessageDetails,
} from '../../services/message-service';
import {
  notifyParentFinishLoading,
  notifyParentOfError,
  notifyParentOfSuccess,
  showError,
} from '../../services/embed-service';
import { loadMessageDetails } from './BuilderSharedUtils';
import { BuilderHeader } from './components/BuilderHeader';
import { trackEvent } from '../../services/reporting-service';
import { Toggle } from '../common/toggle';

import { ToggleOption } from '../common/toggle/types';

import { ReactComponent as CodeIcon } from '../../assets/images/code-icon.svg';
import { ReactComponent as DesignerIcon } from '../../assets/images/designer-icon.svg';
import { ReactComponent as LayersIcon } from '../../assets/images/layers.svg';
import { ReactComponent as PreviewIcon } from '../../assets/images/preview.svg';
import { EditorContent } from './EditorContent';
import {
  templateToNodesArray,
  updateTemplateFromLayersArray,
} from '../../utilities/layers';
import { DropOptions, NodeModel } from '@minoru/react-dnd-treeview';
import { CustomData } from './Layers/types';
import { EditorContextUpdater } from '../../context/EditorContext';
import { Template } from '../../types/common';
interface BuilderEmbedProps {
  messageId: string;
  messageUsage: any;
  readOnly: boolean;
}

interface BuilderEmbedState {
  canSave: boolean;
  showPreview: boolean;
  messageId: string;
  message: any;
  template: Template;
  layersArray: any;
  configuration: any;
  activeView: 'designer' | 'code';
  layersVisible: boolean;
  previewVisible: boolean;
  openNodes: (string | number)[];
}

class BuilderEmbed extends Component<BuilderEmbedProps, BuilderEmbedState> {
  static contextType = EditorContextUpdater;

  public updateTemplateDebounced: debounce<() => void>;

  // public headerRef: React.RefObject<HTMLDivElement>;
  // public gridRef: React.RefObject<HTMLDivElement>;
  // public spacerRef: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);
    this.state = {
      canSave: false,
      showPreview: false,
      messageId: this.props.messageId,
      message: null,
      template: null,
      layersArray: [],
      configuration: null,
      activeView: 'designer',
      layersVisible: true,
      previewVisible: true,
      openNodes: [],
    };
    this.updateTemplateDebounced = debounce(2000, () =>
      this.saveDraftTemplate(this.state.template),
    );

    // Create refs
    // this.headerRef = React.createRef();
    // this.gridRef = React.createRef();
    // this.spacerRef = React.createRef();
  }

  // TODO: Review as a possible way forward to adjust the header width
  // adjustHeaderWidth = () => {
  //   let totalWidth = 0;
  //   if (this.headerRef.current) {
  //     if (this.gridRef.current) {
  //       Array.from(this.gridRef.current.children).forEach((child) => {
  //         const style = window.getComputedStyle(child);
  //         const marginLeft = parseFloat(style.marginLeft);
  //         const marginRight = parseFloat(style.marginRight);
  //         // @ts-ignore
  //         totalWidth += child.offsetWidth + marginLeft + marginRight;
  //       });
  //     }
  //     this.headerRef.current.style.width =
  //       totalWidth == 0 ? '100vw' : `${totalWidth - 32}px`;
  //   }
  // };

  componentDidMount() {
    loadMessageDetails(
      this.state.messageId,
      showError,
      (message, configuration, template) => {
        const layersArray = templateToNodesArray(template);
        this.setState({
          message: message.data,
          configuration: configuration.data,
          template: template,
          layersArray,
          openNodes: layersArray.map((node) => node.id),
        });
        //@ts-ignore
        // Set the template to the context to be used by the layers and the calculations (canDrag, canDrop, etc)
        if (this.context && this.context.setTemplate) {
          //@ts-ignore
          this.context.setTemplate(template);
        }
        this.checkValidity();
        notifyParentFinishLoading();
      },
    );

    // Listen to window resize events to adjust width dynamically
    // window.addEventListener('resize', this.adjustHeaderWidth);
  }

  componentWillUnmount() {
    // Cleanup the event listener when the component is unmounted
    // window.removeEventListener('resize', this.adjustHeaderWidth);
  }

  get hasUsage() {
    if (!this.props.messageUsage) {
      // If messageUsage is undefined / null, it means that the usage details is not yet loaded
      // so, we can't say if it has usage or not.
      return true;
    }

    return this.props.messageUsage?.length > 0;
  }

  revertChanges = async () => {
    if (this.props.readOnly) {
      return;
    }
    trackEvent('MESSAGE_REVERTED', { 'Message ID': this.state.messageId });
    const message = await getMessageDetails(this.state.messageId);
    if (message && message.status === 200) {
      await this.saveDraft(message.data);
      // Code editor is an uncontrolled component, using reload to update.
      window.location.reload();
    } else {
      showError(
        'Error reverting message',
        'There was a problem reverting the message',
        message,
      );
    }
  };

  updateTemplate = async (template: Template) => {
    this.setState(
      { template: template, layersArray: templateToNodesArray(template) },
      () => {
        this.checkValidity();
        if (!this.props.readOnly) {
          this.updateTemplateDebounced();
        }
        // Update the shared context when the template is updated
        //@ts-ignore
        if (this.context && this.context.setTemplate) {
          //@ts-ignore
          this.context.setTemplate(template);
        }
      },
    );
  };

  saveDraft = async (message: any) => {
    const { messageId } = this.state;
    const response = await updateDraftMessage(messageId, message);
    if (response && response.status === 200) {
      // TODO: Log, Draft Message Updated
    } else {
      showError(
        'Error updating draft message',
        'There was a problem updating the draft message',
        response,
      );
    }
  };

  saveDraftTemplate = async (template: Template | null) => {
    const { message, messageId } = this.state;

    // TODO: Find a better type
    // @ts-ignore
    if (template && template !== {}) {
      message.template = JSON.stringify(template);
      const response = await updateDraftMessageTemplate(messageId, message);
      if (response && response.status === 200) {
        // TODO: Log, Draft Template Updated
      } else {
        // Since while building the template a lot of error 400 can crop up, decided to comment this line for now.
        // showError("Error updating draft template", "There was a problem updating the draft template", response);
      }
    }
  };

  saveMessage = async (successMessage = 'Message saved successfully') => {
    if (this.props.readOnly) {
      return;
    }
    const { message, template, messageId, canSave } = this.state;
    trackEvent('MESSAGE_SAVED', { 'Message ID': messageId });

    // TODO: Find a better type
    // @ts-ignore
    if (canSave && template && template !== {}) {
      message.template = JSON.stringify(template);
      const response = await updateMessage(messageId, message);
      if (response && response.status === 200) {
        notifyParentOfSuccess(successMessage);
      } else {
        notifyParentOfError('There was a problem saving the message', response);
      }
    }
  };

  onSaveMessageName = async (messageName: string) => {
    if (this.props.readOnly) {
      return;
    }
    return new Promise((resolve) => {
      this.setState(
        (prevState) => ({
          message: {
            ...prevState.message,
            name: messageName,
          },
        }),
        async () => {
          await this.saveMessage('Message name saved successfully');
          // TODO: Improve typing
          // @ts-ignore
          resolve();
        },
      );
    });
  };

  viewTypeSwitch = () => {
    var template = refreshComponentsIds(this.state.template);
    this.setState({
      template: template,
      layersArray: templateToNodesArray(template),
    });
  };

  checkValidity = () => {
    let isFormValid = false;
    // @ts-ignore
    if (document.forms['builder-view']) {
      // @ts-ignore
      isFormValid = document.forms['builder-view'].checkValidity();
    }
    this.setState({ canSave: isFormValid });
  };

  handleToggleCodeClick = (selectedOption: ToggleOption) => {
    const { activeView } = this.state;
    trackEvent('MESSAGE_TOGGLED_VIEW', {
      'View Type': activeView === 'designer' ? 'Designer' : 'Code',
    });
    this.setState({
      activeView: selectedOption.value === 'designer' ? 'designer' : 'code',
    });
    this.viewTypeSwitch();
  };

  handleTargetDeviceChange = (selectedOption: ToggleOption) => {
    // TODO: See what to do here
  };

  toggleLayers = () => {
    const { layersVisible } = this.state;
    trackEvent('LAYERS_PANEL_TOGGLED', {
      Visible: !layersVisible,
    });
    this.setState({ layersVisible: !layersVisible });
    //@ts-ignore
    if (this.context && this.context.resetPreviousSelectedComponentId) {
      //@ts-ignore
      this.context.resetPreviousSelectedComponentId();
    }
  };

  togglePreview = () => {
    const { previewVisible } = this.state;
    trackEvent('PREVIEW_PANEL_TOGGLED', {
      Visible: !previewVisible,
    });
    this.setState({ previewVisible: !previewVisible });
  };

  onLayersDropHandler = (
    modifiedLayers: NodeModel<CustomData>[],
    options: DropOptions<CustomData>,
  ) => {
    const { template, layersArray } = this.state;
    const updatedTemplate = updateTemplateFromLayersArray(
      template,
      layersArray,
      options,
    );
    this.setState({
      template: updatedTemplate,
      layersArray: modifiedLayers,
    });
    // Updates the context with the updated template
    //@ts-ignore
    if (this.context && this.context.setTemplate) {
      //@ts-ignore
      this.context.setTemplate(updatedTemplate);
      if (!this.props.readOnly) {
        this.saveDraftTemplate(updatedTemplate);
      }
    }
  };

  render() {
    const {
      message,
      template,
      configuration,
      canSave,
      activeView,
      layersVisible,
      previewVisible,
      layersArray,
      openNodes,
    } = this.state;

    const Editor = (
      <EditorContent
        template={template}
        layersArray={layersArray}
        openNodes={openNodes}
        setOpenNodes={(openNodes: (string | number)[]) => {
          this.setState({ openNodes: openNodes });
        }}
        onLayersDropHandler={this.onLayersDropHandler}
        configuration={configuration}
        updateTemplate={this.updateTemplate}
        activeView={activeView}
        layersVisible={layersVisible}
        previewVisible={previewVisible}
        message={message}
        onTargetDeviceChange={this.handleTargetDeviceChange}
      />
    );

    return message === null && template === null ? (
      <div />
    ) : (
      <Fragment>
        <BuilderHeader
          canSave={canSave}
          readOnly={this.props.readOnly}
          hasUsage={this.hasUsage}
          messageName={message.name}
          messageId={message.id}
          // @ts-ignore
          onSaveMessageName={this.onSaveMessageName}
          revertChanges={this.revertChanges}
          saveMessage={this.saveMessage}
        />
        <div className="pt-0">
          <div className="main-layout-container">
            <div className="w-full mx-auto">
              <div className="header top-header">
                {/* TODO: Wrap the header in a component */}
                <Toggle
                  options={[
                    {
                      value: 'designer',
                      label: 'Design',
                      icon: <DesignerIcon />,
                    },
                    { value: 'code', label: 'Code', icon: <CodeIcon /> },
                  ]}
                  onToggleChange={(selectedOption: ToggleOption) =>
                    this.handleToggleCodeClick(selectedOption)
                  }
                />
                <div className="button-container">
                  <button
                    className={`action-button ${
                      this.state.layersVisible ? 'active' : ''
                    }`}
                    onClick={() => this.toggleLayers()}
                  >
                    <span className="icon no-fill">
                      <LayersIcon />
                    </span>
                    <label>Layers</label>
                  </button>
                </div>
                <div className="button-container">
                  <button
                    className={`action-button ${
                      this.state.previewVisible ? 'active' : ''
                    }`}
                    onClick={() => this.togglePreview()}
                  >
                    <span className="icon">
                      <PreviewIcon />
                    </span>
                    <label>Preview</label>
                  </button>
                </div>
              </div>
            </div>
            {/*
          All the complementary conditions cannot be created dynamically, this is why all the variants are placed direclty in the code
          It is necesary for Tailwind to have prior knowledge of all the required dynamic grid column classes in order to work properly
        */}
            {layersVisible === true && previewVisible === true && (
              <div
                className={`grid gap-2 grid-cols-[200px_1fr_auto] xl:grid-cols-[260px_1fr_auto]`}
              >
                {Editor}
              </div>
            )}
            {layersVisible === true && previewVisible === false && (
              <div className={`grid gap-2 grid-cols-[260px_1fr]`}>{Editor}</div>
            )}
            {layersVisible === false && previewVisible === true && (
              <div className={`grid gap-2 grid-cols-[1fr_auto]`}>{Editor}</div>
            )}
            {layersVisible === false && previewVisible === false && (
              <div className={`grid gap-2 grid-cols-1`}>{Editor}</div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default BuilderEmbed;
