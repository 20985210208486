import React, { Component } from 'react';
import TextField from '../../common/fields/TextField';
import { cleanName } from '../../common/naming';

export default class MessageDetails extends Component {
  onValueChange = (name, value) => {
    if (name === 'name' && this.props.isNew === true) {
      var cleanId = cleanName(value).toLowerCase();
      this.props.onValueChange('id', cleanId);
    }
    this.props.onValueChange(name, value);
  };

  render() {
    const { message } = this.props;
    return (
      <div className="body padding">
        <div className="field">
          <div className="input-field-title">Name</div>
          <div className="input-field">
            <TextField
              data-testid="message-name-input"
              name="name"
              value={message.name}
              onValueChange={this.onValueChange}
              required
            />
          </div>
        </div>
      </div>
    );
  }
}
