import React, { Component } from 'react';
import AddImage from '../../../assets/images/large-plus.svg';
import { trackEvent } from '../../../services/reporting-service';
import { GenericKeyValue } from '../../../types/common';
import { isColorNameDuplicated } from '../../../utilities/colors';
import ColorEditor from './ColorEditor';

interface ColorsListProps {
  colors: GenericKeyValue[];
  showHeader: boolean;
  onColorsChange: (colors: GenericKeyValue[]) => void;
  readOnly?: boolean;
}

interface ColorsListState {
  selected: any;
  isNew: boolean;
  colors: GenericKeyValue[];
  error: boolean;
}
class ColorsList extends Component<ColorsListProps, ColorsListState> {
  clearSelected = { id: -1, color: null };

  constructor(props: ColorsListProps) {
    super(props);
    this.state = {
      selected: this.clearSelected,
      isNew: false,
      colors: [],
      error: false,
    };
  }

  static getDerivedStateFromProps(
    props: ColorsListProps,
    state: ColorsListState,
  ) {
    const updatedState = state;
    updatedState.colors = props.colors;
    return updatedState;
  }

  colorSelected = (id: number, color: any) => {
    if (this.state.selected.id === id) {
      this.resetSelection();
    } else {
      this.setState({ isNew: false, selected: { id: id, color: color } });
      trackEvent('BRANDING_EDIT_STARTED', {
        Name: color.name,
        Color: color.value,
        Type: 'Color',
      });
    }
  };

  toggleNewColor = () => {
    this.resetSelection();
    this.setState({ isNew: !this.state.isNew });
    trackEvent('BRANDING_CREATION_STARTED', { Type: 'Color' });
  };

  resetSelection = () => {
    this.setState({ isNew: false, selected: this.clearSelected, error: false });
  };

  removeColor = (index: number) => {
    const colors = this.state.colors;
    trackEvent('BRANDING_DELETED', {
      Name: colors[index].name ?? 'Unknown color name',
      Type: 'Color',
    });
    colors.splice(index, 1);
    this.setState({ colors: colors });
    this.props.onColorsChange(colors);
    this.resetSelection();
  };

  saveColor = (color: GenericKeyValue, index?: number) => {
    const { colors, selected, isNew } = this.state;
    // Edition without changing color name, skips duplicate check
    // because the code will exist in the list of colors
    if (isNew || (selected?.color && color.name !== selected.color.name)) {
      if (isColorNameDuplicated(color.name, colors)) {
        this.setState({ error: true });
        return;
      }
    }

    if (index != null) {
      colors[index] = color;
    } else {
      colors.push(color);
    }
    this.setState({ colors: colors, error: false });
    if (isNew) {
      trackEvent('BRANDING_CREATION_COMPLETED', {
        Name: color.name ?? 'Unknown color name',
        Color: color.value,
        Type: 'Color',
      });
    } else {
      trackEvent('BRANDING_EDIT_COMPLETED', {
        Name: color.name ?? 'Unknown color name',
        Color: color.value,
        Type: 'Color',
      });
    }
    this.props.onColorsChange(colors);
    this.resetSelection();
  };

  cleanColor = (hex: string) => {
    if (hex.length === 9) {
      return '#' + hex.slice(3, 9) + hex[1] + hex[2];
    }
    return hex;
  };

  render() {
    const { colors, selected, isNew, error } = this.state;

    return (
      <div
        className={`editor-card-container ${
          this.props.showHeader ? '' : 'stripped-embed-container'
        }`}
      >
        {this.props.showHeader && (
          <span className="card-text title">Colors</span>
        )}
        <div className={`card ${this.props.showHeader ? 'shadow' : ''}`}>
          <div className="body alt">
            <div className="grid">
              {colors.map((color, id) => {
                return (
                  <div key={id}>
                    <div
                      className="field tappable"
                      onClick={() => this.colorSelected(id, color)}
                    >
                      <div className="display">
                        <div
                          className="bubble"
                          style={{
                            backgroundColor: this.cleanColor(color.value),
                          }}
                        >
                          &nbsp;
                        </div>
                      </div>
                      <div className="title">{color.name}</div>
                    </div>
                  </div>
                );
              })}
              <div
                className="field tappable"
                onClick={() => this.toggleNewColor()}
              >
                <div className="display">
                  <div className="bubble add">
                    <img src={AddImage} alt="Add color" />
                  </div>
                </div>
                <div className="title">&nbsp;</div>
              </div>
            </div>
            {isNew ? (
              <div>
                <ColorEditor
                  saveColor={this.saveColor}
                  cancel={this.resetSelection}
                  error={error}
                  readOnly={this.props.readOnly}
                />
              </div>
            ) : (
              selected.id !== -1 && (
                <div>
                  <ColorEditor
                    key={selected.id}
                    color={selected.color}
                    id={selected.id}
                    removeColor={this.removeColor}
                    saveColor={this.saveColor}
                    cancel={this.resetSelection}
                    error={error}
                    readOnly={this.props.readOnly}
                  />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ColorsList;
