import React, { useState } from 'react';
import { ErrorTooltip } from './ErrorTooltip';

interface NumericInputFieldProps {
  value: any;
  onChange: (value: any) => void;
  placeholder?: string;
  step?: string;
  min?: string;
  required?: boolean;
}

export const NumericInputField = ({
  value,
  onChange,
  placeholder,
  step = '1',
  min = '0',
  required = false,
}: NumericInputFieldProps) => {
  const [validationError, setValidationError] = useState('');

  const handleNumberChange = (event: any) => {
    const input = event.target;
    const withValue = input.value;
    // Clear custom validity to ensure the native message updates dynamically
    input.setCustomValidity('');

    if (input.validity.valid) {
      setValidationError('');
      onChange(event);
    } else {
      // Display the browser's native error message
      setValidationError(input.validationMessage);
    }
  };

  const handleNumberInvalid = (event: any) => {
    event.preventDefault();
    handleNumberChange(event);
  };

  return (
    <div className="input-container">
      <input
        type="number"
        className="numeric-input"
        placeholder={placeholder}
        defaultValue={value}
        onChange={handleNumberChange}
        onInvalid={handleNumberInvalid}
        min={min}
        step={step}
        required={required}
      />
      {validationError && <ErrorTooltip errorMessage={validationError} />}
    </div>
  );
};
