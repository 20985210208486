import React, { ChangeEvent, Component } from 'react';
import { ReactComponent as ImagePreviewIcon } from '../../../assets/images/image.svg';
import { linkPointsToImage } from '../../../utilities/images';
import { v4 as uuid } from 'uuid';
import { notifyParentToShowAssetLibraryModal } from '../../../services/embed-service';

type FilePickerFieldEmbedProps = {
  preview: boolean;
  value: string;
  onChange: (e: any) => void;
  required: boolean;
};

type FilePickerFieldEmbedState = {
  id: string;
  value: string;
  isDynamicField: boolean;
  isValidImage: boolean;
  loadingPreview: boolean;
  showErrorMessage: boolean;
};

export default class FilePickerFieldEmbed extends Component<
  FilePickerFieldEmbedProps,
  FilePickerFieldEmbedState
> {
  constructor(props: FilePickerFieldEmbedProps) {
    super(props);
    this.state = {
      id: `file-picker-input-${uuid()}`,
      value: this.props.value ?? '',
      isValidImage: false,
      showErrorMessage: false,
      isDynamicField: this.isDynamicFieldValue(this.props.value),
      loadingPreview: false,
    };

    linkPointsToImage(this.props.value).then((isValidImage) => {
      this.setState({ isValidImage });
    });
  }

  get previewPlaceholder() {
    if (this.state.loadingPreview) {
      return 'Loading preview...';
    }

    return 'Preview not available';
  }

  get isShowingImagePreview() {
    return this.props.preview && this.state.value && this.state.isValidImage;
  }

  get isShowingActionButtons() {
    return !!this.state.value || !this.props.preview;
  }

  get isShowingRemoveButton() {
    return this.props.preview || (!this.props.preview && this.state.value);
  }

  get placeholder() {
    if (this.props.preview) {
      return 'or type image URL';
    }
    return 'Choose image or type image URL';
  }

  isDynamicFieldValue = (value: string) => {
    return !!value && value.startsWith('$properties.');
  };

  onChange = async (e: ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value;
    this.setState({ value, showErrorMessage: false });

    // Empty input scenario handled differentially
    if (value === '') {
      this.setState({
        isValidImage: false,
        isDynamicField: false,
        showErrorMessage: this.props.required,
      });
      this.props.onChange(e);
    } else if (this.isDynamicFieldValue(value)) {
      this.setState({
        isValidImage: false,
        isDynamicField: true,
        showErrorMessage: false,
      });
      this.props.onChange(e);
    } else {
      this.setState({ loadingPreview: true });
      const isImage = await linkPointsToImage(value);
      this.setState({
        isValidImage: isImage,
        loadingPreview: false,
        isDynamicField: false,
        showErrorMessage: !isImage,
      });
      if (isImage) {
        this.props.onChange(e);
      }
    }
  };

  removeImage = async () => {
    this.setState({ value: '', isValidImage: false, isDynamicField: false });
    await this.onChange({ target: { value: '' } } as unknown as ChangeEvent);
  };

  onAddImageButtonClick = () => {
    notifyParentToShowAssetLibraryModal(this.state.id);
  };

  render() {
    const { preview, required } = this.props;
    const { value, isValidImage, showErrorMessage } = this.state;

    return (
      <>
        {this.isShowingImagePreview ? (
          <div className="file-picker-img-container">
            <a target="blank" href={value}>
              <img
                className="file-picker-img-preview"
                src={value}
                alt="preview"
              />
            </a>
          </div>
        ) : preview ? (
          <div className="file-picker-img-container">
            <div className="img-preview-container">
              <ImagePreviewIcon />
              {!isValidImage && value ? (
                this.previewPlaceholder
              ) : (
                <button
                  type="button"
                  className="button primary"
                  onClick={this.onAddImageButtonClick}
                >
                  Add image from library
                </button>
              )}
            </div>
          </div>
        ) : null}
        <div className="file-picker-field-embed">
          <div className="floating-error-container">
            <input
              data-testid="file-picker-input"
              id={this.state.id}
              placeholder={this.placeholder}
              type="text"
              value={value}
              onChange={(e) => this.onChange(e)}
              className={showErrorMessage ? 'error' : ''}
              required={required}
            />
            {showErrorMessage && (
              <div className="floating-error">File must be an image</div>
            )}
          </div>
          {this.isShowingActionButtons && (
            <div className="action-buttons">
              {this.isShowingRemoveButton && (
                <button
                  type="button"
                  className="button subtle danger"
                  onClick={this.removeImage}
                >
                  Remove
                </button>
              )}
              <button
                type="button"
                className="button subtle"
                onClick={this.onAddImageButtonClick}
              >
                Choose image
              </button>
            </div>
          )}
        </div>
      </>
    );
  }
}
