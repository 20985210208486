import React, { Component } from 'react';
import { trackEvent } from '../../services/reporting-service';
import MessageList from './components/MessageList';

interface MessagesEmbedProps {
  readOnly?: boolean;
}

interface MessagesEmbedState {
  currentFolderId?: string;
}

class MessagesEmbed extends Component<MessagesEmbedProps, MessagesEmbedState> {
  constructor(props: MessagesEmbedProps) {
    super(props);
    this.state = {
      currentFolderId: undefined,
    };
  }

  showMessageDetails = (messageId: string) => {
    trackEvent('MESSAGE_VIEWED', {
      'Message ID': messageId ?? 'Unknown message ID',
    });
    window.location.pathname = `/embed/message/${messageId}`;
  };

  createNewMessage = () => {
    if (this.state != null && this.state.currentFolderId != undefined) {
      window.location.pathname = `/embed/new/message/${this.state.currentFolderId}`;
    } else {
      window.location.pathname = `/embed/new/message`;
    }
    trackEvent('MESSAGE_CREATION_STARTED');
  };

  setCurrentFolderId = (folderId: string) => {
    this.setState({ currentFolderId: folderId });
  };

  render() {
    return (
      <div>
        <div className="w-full padding-t">
          <div className="w-full mx-auto">
            <div className="action-menu">
              <div className="action-buttons">
                <button
                  data-testid="new-message-button"
                  className={`button primary ${
                    this.props.readOnly ? 'disabled' : ''
                  }`}
                  disabled={this.props.readOnly}
                  onClick={() => this.createNewMessage()}
                >
                  New Message
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="main-body">
          <MessageList
            selectAction={this.showMessageDetails}
            emptyListMessage="Select New Message to start building great in-app experiences"
            updateCurrentFolderId={this.setCurrentFolderId}
          />
        </div>
      </div>
    );
  }
}

export default MessagesEmbed;
