import { AuthenticatedNetworkInstance } from './base-network';

export async function getMessages() {
  try {
    var response = await AuthenticatedNetworkInstance().get(`/api/v1/messages`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getMessageDetails(id) {
  try {
    var response = await AuthenticatedNetworkInstance().get(
      `/api/v1/messages/${id}`,
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getDraftMessageDetails(id) {
  try {
    var response = await AuthenticatedNetworkInstance().get(
      `/api/v1/messages/draft/${id}`,
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function updateDraftMessage(id, message) {
  try {
    var response = await AuthenticatedNetworkInstance().put(
      `/api/v1/messages/draft/${id}`,
      message,
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function updateDraftMessageTemplate(id, message) {
  try {
    var response = await AuthenticatedNetworkInstance().put(
      `/api/v1/messages/draft/${id}/template`,
      message,
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function updateMessage(id, message) {
  try {
    var response = await AuthenticatedNetworkInstance().put(
      `/api/v1/messages/${id}`,
      message,
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function createMessage(message) {
  try {
    var response = await AuthenticatedNetworkInstance().post(
      `/api/v1/messages`,
      message,
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function deleteMessage(id) {
  try {
    var response = await AuthenticatedNetworkInstance().delete(
      `/api/v1/messages/${id}`,
    );
    return response;
  } catch (error) {
    return error.response;
  }
}
