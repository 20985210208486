import React, { Component } from 'react';
import { showErrorNotification } from '../../common/notifications';

class PaddingEditor extends Component {
  constructor(props) {
    super(props);
    const { padding } = this.props;
    if (padding) {
      this.state = { name: padding.name, value: padding.value };
    } else {
      this.state = { name: '', value: '' };
    }
  }

  removePadding = () => {
    var shouldDelete = window.confirm(
      'Are you certain you want to delete this padding? Deleting it will reset any messages that rely on it and result in the loss of their associated value.',
    );
    if (shouldDelete) {
      this.props.removePadding(this.props.id);
    }
  };

  processPaddingValue = (value) => {
    try {
      const integer = parseInt(value);
      this.setState({ value: integer });
    } catch (e) {
      showErrorNotification(
        'Invalid padding value',
        'Padding values must be integers.',
      );
    }
  };

  savePadding = (e) => {
    const { name, value } = this.state;
    this.props.savePadding({ name: name, value: value }, this.props.id);
    e.preventDefault();
  };

  render() {
    const { id } = this.props;
    const { name, value } = this.state;

    return (
      <form className="inline-form padded" onSubmit={this.savePadding}>
        <div className="field">
          <div className="input-field-title">Name</div>
          <div className="input-field">
            <input
              type="text"
              value={name}
              onChange={(e) => this.setState({ name: e.target.value })}
              required
            />
          </div>
        </div>
        <div className="field">
          <div className="input-field-title">Value</div>
          <div className="input-field">
            <input
              type="number"
              value={value}
              onChange={(e) => this.processPaddingValue(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="field last">
          <div className="input-field-title">&nbsp;</div>
          <div className="input-field button-field">
            <button
              className={`button primary ${
                this.props.readOnly ? 'disabled' : ''
              }`}
              disabled={this.props.readOnly}
              type="submit"
            >
              Save
            </button>
            <button className="button" onClick={this.props.cancel}>
              Cancel
            </button>
            {id != null && (
              <button
                className={`button delete ${
                  this.props.readOnly ? 'disabled' : ''
                }`}
                onClick={this.removePadding}
                type="button"
                disabled={this.props.readOnly}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default PaddingEditor;
