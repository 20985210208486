export const getPlaceholderInputForFieldName = (name: string) => {
  switch (name) {
    case 'Border Radius':
      return '';
    case 'Height':
      return '';
    case 'Flex':
      return '';
    case 'Text':
      return 'Your text here';
    default:
      return '';
  }
};
