import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light.css';

import { GRAY_500, PURPLE_700 } from '../../../../../utilities/colors';
import { HoveredBlockProps } from './types';
import { useComponentSVGIcon } from '../../../../../hooks/useComponentSVGIcon';
import { Row } from '../ComponentOption';
import { TooltipPreview } from '../TooltipPreview';

export const HoveredBlock = ({
  label,
  description,
  iconName,
  isSelected,
}: HoveredBlockProps) => {
  const Icon = useComponentSVGIcon(iconName, {
    fill: isSelected ? PURPLE_700 : GRAY_500,
  });
  if (!Icon)
    return (
      <Row
        iconName={iconName}
        label={label}
        description={description}
        isSelected={isSelected}
      />
    );
  return (
    <Tippy
      content={
        <TooltipPreview Icon={Icon} label={label} description={description} />
      }
      placement="right"
      animation="scale-subtle"
      offset={[0, 20]}
      theme="light"
      className="tippy-preview"
    >
      {/* parent div needed to make Tippy work */}
      <div>
        <Row
          iconName={iconName}
          label={label}
          description={description}
          isSelected={isSelected}
        />
      </div>
    </Tippy>
  );
};
