import { Fragment } from 'react';

import { ReactComponent as ChevronDown } from './../../../assets/images/down-chevron.svg';
import { useComponentSVGIcon } from '../../../hooks/useComponentSVGIcon';
import { truncateText } from '../../../utilities/TextUtilities';
import { NodeModel } from '@minoru/react-dnd-treeview';
import { CustomData } from './types';

interface ItemRowProps {
  isOpen: boolean;
  node: NodeModel<CustomData>;
  parentHovered?: boolean;
  onToggle: any;
  isHidden: boolean | undefined;
  iconName: string | undefined;
}

export const ItemRow = ({
  isOpen,
  node,
  parentHovered,
  onToggle,
  isHidden,
  iconName,
}: ItemRowProps) => {
  const clickHandler: any = onToggle ? (e: any) => onToggle(e) : null;
  return (
    <Fragment>
      <div className={`expand-icon-wrapper ${!isOpen ? 'rotate' : ''} `}>
        <div className="chevron-container" onClick={clickHandler}>
          {node.droppable && parentHovered && <ChevronDown />}
        </div>
      </div>
      <div className="label-grid-item">
        {iconName != '' && (
          <div className="icon">
            {useComponentSVGIcon(iconName, {
              width: 16,
              height: 16,
            })}
          </div>
        )}
        <p>{`${truncateText(node.text, 25)} ${isHidden ? '(hidden)' : ''}`}</p>
      </div>
    </Fragment>
  );
};
