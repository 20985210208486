import { GistStyles } from '../types/common';

const checkPaddingsArray = (style: GistStyles): void => {
  if (!style || style.padding.length === 0) {
    throw new Error('Invalid style padding array');
  }
};

export const getPaddingValueFromStyles = (
  keyName: string,
  style: GistStyles,
): number => {
  checkPaddingsArray(style);
  if (keyName === '') {
    return 0;
  }
  const valueFound = style.padding.find(
    (element) => element.name === keyName,
  )?.value;
  return valueFound ?? 0;
};

// It's important to keep the indexes as Top = 1, Right = 2, Bottom = 3, Left = 0
export const shiftPaddingValuesToCSS = (array: number[]): any => {
  return {
    paddingTop: `${array[1]}px`,
    paddingRight: `${array[2]}px`,
    paddingBottom: `${array[3]}px`,
    paddingLeft: `${array[0]}px`,
  };
};

export const toValues = (
  arrayOfKeys: string[],
  style: GistStyles,
): number[] => {
  checkPaddingsArray(style);
  const result: number[] = [];
  arrayOfKeys.forEach((keyName: string) => {
    const value = getPaddingValueFromStyles(keyName, style);
    result.push(value);
  });
  return result;
};
