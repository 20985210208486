import React from 'react';
import {
  DndContext,
  useSensor,
  PointerSensor,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  useSensors,
} from '@dnd-kit/core';

export function Context(props) {
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 15,
    },
  });
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 15,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      distance: 15,
    },
  });
  const keyboardSensor = useSensor(KeyboardSensor, {});
  const sensors = useSensors(
    mouseSensor,
    touchSensor,
    pointerSensor,
    keyboardSensor,
  );

  return (
    <DndContext id={props.id} sensors={sensors} onDragEnd={props.onDragEnd}>
      {props.children}
    </DndContext>
  );
}
