import * as Sentry from '@sentry/react';

import LoggedOut from './LoggedOut';
import LoggedIn from './LoggedIn';
import { getToken } from './services/token-service';
import { setupReporting } from './services/reporting-service';

const App = () => {
  setupReporting();
  return getToken() ? <LoggedIn /> : <LoggedOut />;
};

export default Sentry.withProfiler(App);
