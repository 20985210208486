import React, { Component } from 'react';
import { JsonEditor as Editor } from 'jsoneditor-react';
import ace from 'brace';
// Styles
import 'jsoneditor-react/es/editor.min.css';
import 'brace/mode/json';
import 'brace/theme/github';
import '../../../assets/code-editor.scss';

export default class CodeView extends Component {
  render() {
    const { components, updateTemplate } = this.props;
    return (
      <div className="code-builder">
        <Editor
          ref={this.setEditorRef}
          htmlElementProps={{ className: 'code-view' }}
          history={true}
          navigationBar={true}
          search={true}
          mode="code"
          allowedModes={['code', 'tree']}
          value={components}
          ace={ace}
          theme="ace/theme/github"
          onChange={updateTemplate}
        />
      </div>
    );
  }
}
