import React from 'react';
import { BaseLayoutProps } from '../../../../types/common';
import {
  GetComponentGroupForIndexes,
  getGroupEndIndex,
} from '../../../../utilities/layout';
import ComponentDetailsField from '../ComponentDetailsField';

export const FieldsBuilder = ({
  component,
  definition,
  configuration,
  fields,
  updateComponentField,
  getComponentField,
  messages,
  canPerformActions,
}: BaseLayoutProps) => {
  // This algorithm creates generates the fields and the group of fields
  // as it's defined in the component definition
  const getChildFieldsComponent = (): JSX.Element => {
    const components: JSX.Element[] = [];
    let groupStarted = false;
    const addedKeys: { [key: string]: string } = {};
    fields.forEach((key: string, index: number) => {
      if (key === 'components' || key === 'component') {
        addedKeys[key] = 'added';
        components.push(<></>);
      }

      if (definition[key]['group'] === 'start') {
        groupStarted = true;
        const endIndex = getGroupEndIndex(definition, fields, index);
        for (let iterator = index; iterator <= endIndex; iterator++) {
          const addedKey = fields[iterator];
          addedKeys[addedKey] = 'added';
        }
        components.push(
          GetComponentGroupForIndexes(
            index,
            endIndex,
            fields,
            component,
            definition,
            configuration,
            updateComponentField,
            getComponentField,
            messages,
            canPerformActions,
            '',
            '',
          ),
        );
        groupStarted = false;
      }
      // If grup has started or key was already added, return null
      if (groupStarted || addedKeys.hasOwnProperty(key)) {
        components.push(<></>);
      } else {
        components.push(
          <ComponentDetailsField
            key={`${key}-${index}-cdf`}
            componentType={definition.type}
            containerClassName=""
            inputClassName=""
            fieldKey={key}
            value={component[key]}
            definition={definition[key]}
            configuration={configuration}
            updateValue={updateComponentField}
            getValue={getComponentField}
            messages={messages}
            canPerformActions={canPerformActions}
          />,
        );
      }
    });
    return <>{components}</>;
  };
  return getChildFieldsComponent();
};
