import React, { useMemo } from 'react';
import {
  shiftPaddingValuesToCSS,
  toValues,
} from '../../../../utilities/padding';
import PaddingField from '../../fields/PaddingField';
import { MultiPaddingPickerProps } from './types';

export const MultiPaddingPicker = ({
  value,
  configuration,
  updatePropertyPaddingHandler,
}: MultiPaddingPickerProps) => {
  // It's important to keep the indexes as Top = 1, Right = 2, Bottom = 3, Left = 0
  const paddingValues = useMemo(() => {
    if (!value || !configuration) return [0, 0, 0, 0];
    return toValues(value, configuration?.style);
  }, [value, configuration]);

  return (
    <div className="padding-field">
      <div
        className="sample-container"
        style={shiftPaddingValuesToCSS(paddingValues)}
      >
        <div className="box-w-padding"></div>
      </div>
      <div className="padding-field-container">
        <div className="padding-field-cell">
          <span className="padding-field-title">Top</span>
          <PaddingField
            value={value}
            index={1}
            configuration={configuration}
            onPaddingChange={updatePropertyPaddingHandler}
          />
        </div>
      </div>
      <div className="padding-field-container">
        <div className="padding-field-cell">
          <span className="padding-field-title">Right</span>
          <PaddingField
            value={value}
            index={2}
            configuration={configuration}
            onPaddingChange={updatePropertyPaddingHandler}
          />
        </div>
      </div>
      <div className="padding-field-container">
        <div className="padding-field-cell">
          <span className="padding-field-title">Bottom</span>
          <PaddingField
            value={value}
            index={3}
            configuration={configuration}
            onPaddingChange={updatePropertyPaddingHandler}
          />
        </div>
      </div>
      <div className="padding-field-container">
        <div className="padding-field-cell">
          <span className="padding-field-title">Left</span>
          <PaddingField
            value={value}
            index={0}
            configuration={configuration}
            onPaddingChange={updatePropertyPaddingHandler}
          />
        </div>
      </div>
    </div>
  );
};
