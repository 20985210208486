import React from 'react';
import { DragLayerMonitorProps } from '@minoru/react-dnd-treeview';
import { CustomData } from './types';
import { ItemRow } from './ItemRow';
import { GRAY_200 } from '../../../utilities/colors';

const styles = {
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto auto 1fr auto',
    height: '32px',
    borderBottom: `solid 1px ${GRAY_200}`,
    width: '200px',
    opacity: 0.75,
    position: 'relative' as any,
    transform: 'translateX(-50%)',
    zIndex: 99999,
  },
};

type Props = {
  monitorProps: DragLayerMonitorProps<CustomData>;
};

export const DragPreview = ({ monitorProps }: Props) => {
  const item = monitorProps.item;
  const isHidden = item.data?.isHidden ?? false;
  const iconName = item.data?.iconName ?? '';
  // TODO: Improve this calculation
  const leftOffset = item.ref.current?.getBoundingClientRect().left;
  const clientOffset = monitorProps.clientOffset;

  return (
    <div
      className="tree-node hover"
      style={{
        ...styles.root,
        transform: `translateX(calc(-50% + ${
          leftOffset ?? 0 + (clientOffset ? clientOffset.x : 0)
        }px))`,
      }}
    >
      <ItemRow
        isHidden={isHidden}
        iconName={iconName}
        node={item}
        isOpen={false}
        parentHovered={true}
        onToggle={null}
      />
    </div>
  );
};
