import React, { Component } from 'react';
import FileUploadField from '../../common/fields/FileUploadField';

class FontEditor extends Component {
  constructor(props) {
    super(props);
    const { font } = this.props;
    if (font) {
      this.state = { font: font, error: false };
    } else {
      this.state = { font: '', error: false };
    }
  }

  removeFont = () => {
    this.props.removeFont(this.props.id);
  };

  saveFont = (e) => {
    const { font } = this.state;
    if (!font) {
      this.setState({ error: true });
      e.preventDefault();
      e.stopPropagation();
      return;
    } else {
      this.props.saveFont(font, this.props.id);
      e.preventDefault();
    }
  };

  onFileUploaded = (url) => {
    this.setState({ font: url, error: false });
  };

  render() {
    const { id } = this.props;
    const { font, error } = this.state;

    return (
      <form className="inline-form padded" onSubmit={this.saveFont}>
        <div className="field">
          <div className="input-field-title">FONT FILE (.TTF)</div>
          <div className="input-field">
            <div className="file-picker-field">
              <input
                className={error ? 'highlight' : ''}
                type="url"
                value={font}
                onChange={(e) => this.setState({ font: e.target.value })}
                required
                readOnly
              />
              <FileUploadField
                onFileUploaded={this.onFileUploaded}
                supportedTypes={'.ttf'}
              />
            </div>
            {error && (
              <span className="error"> Please select a font file first </span>
            )}
          </div>
        </div>
        <div className="field last">
          <div className="input-field-title">&nbsp;</div>
          <div className="input-field button-field">
            <button
              className={`button primary ${
                this.props.readOnly ? 'disabled' : ''
              }`}
              type="submit"
              disabled={this.props.readOnly}
            >
              Save
            </button>
            <button className="button" onClick={this.props.cancel}>
              Cancel
            </button>
            {id != null && (
              <button
                className={`button delete ${
                  this.props.readOnly ? 'disabled' : ''
                }`}
                onClick={this.removeFont}
                type="button"
                disabled={this.props.readOnly}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

export default FontEditor;
